import { useState } from 'react';

import { CreateProjectDialog } from '@/pages/project/components/CreateProject/CreateProjectDialog';

import ProjectServicesContext from './ProjectServicesContext';
import useProvider from './useProvider';

const ProjectServicesWrapper = ({
  children,
}: {
  children?: React.ReactNode;
}) => {
  const { isShowCreateProject, event } = useProvider();
  const [initCreate, setInitCreate] = useState<{
    initTeamId?: string;
  }>();
  return (
    <ProjectServicesContext.Provider
      value={{
        isShowCreateProject,
        showCreateProjectPopup: (value) => {
          event.showCreateProjectPopup(value.isOpen);
          setInitCreate({ ...value });
        },
      }}
    >
      {children}

      <CreateProjectDialog
        {...initCreate}
        onSuccess={() => {}}
        onCancel={() => {}}
      />
    </ProjectServicesContext.Provider>
  );
};

export default ProjectServicesWrapper;
