import { useContext } from 'react';

import {
  Accordion,
  AccordionContent,
  AccordionItem,
  AccordionTrigger,
} from '@/components/Accordion';
import { Button } from '@/components/Button';
import { Label } from '@/components/Label';
import EmojiIcon, { IEmojiIcon } from '@/icons/emoji';
import { WorkspaceFragment, TeamFragment } from '@/types/gql/generated';

import WorkspaceContext from '../../workspace/WorkspaceContext';

const Item = (props: {
  title: string;
  path: string;
  onNavigation: (path: string) => void;
}) => {
  return (
    <Button
      type="button"
      className="h-[30px] pl-[38px]"
      variant="ghost"
      onClick={() => props.onNavigation(props.path)}
    >
      <div className="flex w-full items-center gap-4">
        <Label>{props.title}</Label>
      </div>
    </Button>
  );
};

const Team = (props: {
  workspace: WorkspaceFragment;
  team: TeamFragment;
  onNavigation: (path: string) => void;
}) => {
  const MenuTeams = [
    { title: 'Issues', path: 'issues' },
    { title: 'Active', path: 'active' },
    { title: 'Backlog', path: 'backlog' },
    { title: 'Documents', path: 'documents' },
    { title: 'Projects', path: 'projects' },
  ];

  return (
    <Accordion type="single" collapsible>
      <AccordionItem value={props.team.id}>
        <Button
          size={'sm'}
          type="button"
          variant={'ghost'}
          className="flex h-[30px] w-full flex-row justify-start px-[10px] text-left"
        >
          <AccordionTrigger className="flex-none">
            <div className="flex flex-row items-center gap-2">
              <EmojiIcon
                tag={props.team.icon as IEmojiIcon}
                attrs={{
                  width: 18,
                  height: 18,
                  color: props.team.color,
                }}
              />
              <Label className="max-w-[115px] flex-none truncate pr-[5px]">
                {props.team.name}
              </Label>
            </div>
          </AccordionTrigger>
        </Button>
        <AccordionContent>
          <div className="flex w-full flex-col">
            {MenuTeams.map((item, index) => {
              return (
                <Item
                  key={index}
                  title={item.title}
                  path={`${props.team.identifier}/${item.path}`}
                  onNavigation={props.onNavigation}
                />
              );
            })}
          </div>
        </AccordionContent>
      </AccordionItem>
    </Accordion>
  );
};

const TeamList = (props: { onNavigate: (path: string) => void }) => {
  const { selected: selectedWorkspace, teams } = useContext(WorkspaceContext);

  const onNavigation = (path: string) => {
    props.onNavigate(`/${selectedWorkspace?.url}/team/${path}`);
  };

  return (
    <div className="flex w-full flex-col">
      {selectedWorkspace &&
        teams.map((team) => (
          <Team
            key={team.id}
            team={team}
            workspace={selectedWorkspace}
            onNavigation={onNavigation}
          />
        ))}
    </div>
  );
};

const TeamSection = (props: {
  onNavigate: (path: string, reload?: boolean) => void;
}) => {
  return (
    <Accordion type="single" collapsible={true} defaultValue={'team'}>
      <AccordionItem value={'team'}>
        <Button
          size={'sm'}
          type="button"
          variant={'ghost'}
          className="flex  h-[30px] w-full flex-row justify-start px-[10px] text-left"
        >
          <AccordionTrigger className="flex-none">
            <Label className="text-xs opacity-50">Your teams</Label>
          </AccordionTrigger>
        </Button>
        <AccordionContent>
          <TeamList onNavigate={props.onNavigate} />
        </AccordionContent>
      </AccordionItem>
    </Accordion>
  );
};

export default TeamSection;
