import { useSortable } from '@dnd-kit/sortable';
// eslint-disable-next-line import/no-extraneous-dependencies
import { CSS } from '@dnd-kit/utilities';
import { cva } from 'class-variance-authority';
import { useNavigate } from 'react-router-dom';

import { CardContent, Card } from '@/components/Card';
import { Label } from '@/components/Label';
import { UserAvatar } from '@/components/UserAvatar';
import { UserAssignIcon } from '@/icons/core/issue';
import { MemoizedRenderMember } from '@/renders/RenderMember';

import { DragStatus, Task, TaskDragData } from './kanban.interface';

const TaskCard = ({ task, isOverlay }: { task: Task; isOverlay?: boolean }) => {
  const navigate = useNavigate();
  const {
    setNodeRef,
    attributes,
    listeners,
    transform,
    transition,
    isDragging,
  } = useSortable({
    id: task.id,
    data: {
      type: 'Task',
      task,
    } satisfies TaskDragData,
    attributes: {
      roleDescription: 'Task',
    },
  });

  const style = {
    transition,
    transform: CSS.Translate.toString(transform),
  };

  let draggingClass: DragStatus;
  if (isOverlay) {
    draggingClass = 'overlay';
  } else if (isDragging) {
    draggingClass = 'over';
  }

  const variants = cva('', {
    variants: {
      dragging: {
        over: 'ring-2 opacity-30',
        overlay: 'ring-2 ring-primary',
      },
    },
  });

  return (
    <Card
      ref={setNodeRef}
      style={style}
      className={variants({
        dragging: draggingClass,
      })}
      {...attributes}
      {...listeners}
    >
      <CardContent
        className="whitespace-pre-wrap px-1 pb-6 pt-1 text-left"
        onClick={() => navigate(task.path)}
      >
        <div className="flex w-full">
          <Label className="grow opacity-50">{task.code}</Label>
          <MemoizedRenderMember
            id={task.assignee_id}
            renderItem={(member) =>
              member ? (
                <UserAvatar size={'sm'} fallback={member?.username} />
              ) : (
                <UserAssignIcon
                  width={22}
                  height={22}
                  style={{ color: '#9e9fa8' }}
                />
              )
            }
          />
        </div>
        <Label>{task.content}</Label>
      </CardContent>
    </Card>
  );
};
export default TaskCard;
