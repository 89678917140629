import {
  AlertDialog,
  AlertDialogAction,
  AlertDialogCancel,
  AlertDialogContent,
  AlertDialogDescription,
  AlertDialogTitle,
} from '@/components/AlertDialog';
import { useErrorHandler } from '@/hooks/useToasterHandler';
import { LabelFragment, useRemoveLabelMutation } from '@/types/gql/generated';

const DeleteLabelDialog = (props: {
  label: LabelFragment | undefined;
  onCancel: () => void;
  onDeleteSuccess: (label: LabelFragment) => void;
}) => {
  const { showErrorToast } = useErrorHandler();
  const removeLabel = useRemoveLabelMutation({
    onMutate: () => {},
    onError: (res) => {
      showErrorToast({ error: res });
    },
    onSuccess: (res) => {
      if (res.removeLabel) {
        props.onDeleteSuccess(res.removeLabel);
      }
    },
    onSettled: () => {},
  });

  return (
    <AlertDialog open={!!props.label}>
      <AlertDialogContent className="max-w[450px]">
        <AlertDialogTitle className="text-xl">
          Are you sure you want to delete the "{props.label?.name}" label?
        </AlertDialogTitle>
        <AlertDialogDescription>
          The label will be removed. This might also affect issues and drafts
          you do not have access to. This action cannot be undone.
        </AlertDialogDescription>

        <div className="flex flex-row justify-end gap-4">
          <AlertDialogCancel onClick={() => props.onCancel()}>
            Cancel
          </AlertDialogCancel>
          <AlertDialogAction
            disabled={removeLabel.isPending}
            onClick={() =>
              props.label && removeLabel.mutate({ id: props.label.id })
            }
          >
            Delete
          </AlertDialogAction>
        </div>
      </AlertDialogContent>
    </AlertDialog>
  );
};

export default DeleteLabelDialog;
