import { createContext } from 'react';

import { CommentFragment } from '@/types/gql/generated';

type IIssueDetailContext = {
  comments: CommentFragment[];
};

const IssueDetailContext = createContext<IIssueDetailContext>({
  comments: [],
});

export default IssueDetailContext;
