import { createContext } from 'react';

type DocumentServicesContext = {
  isShowCreateDocument: boolean;
  showCreateDocumentPopup: (props: {
    isOpen: boolean;
    initTeamId?: string;
  }) => void;
};

const DocumentServicesContext = createContext<DocumentServicesContext>({
  isShowCreateDocument: false,
  showCreateDocumentPopup: () => undefined,
});

export default DocumentServicesContext;
