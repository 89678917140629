import { Button } from '@/components/Button';
import { Label } from '@/components/Label';
import { UserAvatar } from '@/components/UserAvatar';
import { LabelIcon, UserAssignIcon } from '@/icons/core/issue';
import { getNameByPriority } from '@/services/issue/issue-utils';
import { PriorityType } from '@/types/gql/generated';

import MemoizedAssignToButton from './Properties/AssignToButton';
import {
  MemoizedLabelButton,
  MemoizedRenderLabelsColor,
} from './Properties/LabelButton';
import {
  MemoizedPriorityButton,
  MemoizedRenderPriorityIcon,
} from './Properties/PriorityButton';
import {
  MemoizedRenderStatusIcon,
  MemoizedStatusButton,
} from './Properties/StatusButton';

export const IssuePropertiesComponent = (props: {
  teamId: string;
  selectedWorkflowId: string | undefined;
  onSelectedWorkflowId: (value: string | undefined) => void;
  selectedPriority: PriorityType | undefined;
  onSelectedPriority: (value: PriorityType | undefined) => void;
  selectedLabelIds: string[];
  onSelectedLabelIds: (value: string[]) => void;
  selectedMemberId: string | undefined;
  onSelectedMemberId: (value: string | undefined) => void;
}) => {
  return (
    <div className="flex w-full flex-wrap items-center gap-2">
      <div className="flex-none">
        <MemoizedStatusButton
          teamId={props.teamId}
          selected={props.selectedWorkflowId}
          onSelected={props.onSelectedWorkflowId}
          renderItem={(value) => (
            <Button
              size={'sm'}
              className="flex flex-row items-center justify-start gap-2 text-left"
              variant="outline"
              type="button"
            >
              <span className="flex w-[20px] flex-none">
                <MemoizedRenderStatusIcon
                  type={value?.type}
                  color={value?.color}
                  processing={value?.processing}
                />
              </span>
              <Label className="text-xs">{value?.name}</Label>
            </Button>
          )}
        />
      </div>
      <div className="flex-none">
        <MemoizedPriorityButton
          selected={props.selectedPriority}
          onSelected={props.onSelectedPriority}
          renderItem={(value) => (
            <Button
              size={'sm'}
              className="flex flex-row items-center justify-start gap-2 text-left"
              variant="outline"
              type="button"
            >
              <span className="flex">
                <MemoizedRenderPriorityIcon type={value?.type} />
              </span>
              <Label className="text-xs">
                {getNameByPriority(value?.type)}
              </Label>
            </Button>
          )}
        />
      </div>
      <div className="flex-none">
        <MemoizedAssignToButton
          teamIds={props.teamId ? [props.teamId] : []}
          selected={props.selectedMemberId}
          onSelected={props.onSelectedMemberId}
          renderItem={(value) => (
            <Button
              size={'sm'}
              className="flex flex-row items-center justify-start gap-2 text-left"
              variant="outline"
              type="button"
            >
              {!props.selectedMemberId && (
                <div className="flex flex-row items-center gap-2">
                  <span className="flex">
                    <UserAssignIcon
                      width={18}
                      height={18}
                      style={{ color: '#9e9fa8' }}
                    />
                  </span>
                  <Label className="text-xs">Assign</Label>
                </div>
              )}
              {props.selectedMemberId && (
                <div className="flex flex-row items-center gap-2">
                  <span className="flex items-center">
                    <UserAvatar
                      size="sm"
                      url={value?.image}
                      fallback={value?.username}
                    />
                  </span>
                  <Label className="text-xs">{value?.username}</Label>
                </div>
              )}
            </Button>
          )}
        />
      </div>
      <div className="flex-none">
        <MemoizedLabelButton
          teamId={props.teamId}
          selected={props.selectedLabelIds}
          onSelected={props.onSelectedLabelIds}
          placeholder="Search label..."
          renderItem={(value) => (
            <Button
              size={'sm'}
              className="flex flex-row items-center justify-start gap-2 text-left"
              variant="outline"
              type="button"
            >
              {value.length > 0 && (
                <div className="flex flex-row items-center gap-1">
                  <MemoizedRenderLabelsColor labels={value} />
                  <Label className="text-xs">{value.length} labels</Label>
                </div>
              )}
              {value.length === 0 && <LabelIcon width={16} height={16} />}
            </Button>
          )}
        />
      </div>
    </div>
  );
};
