type UnionFromProperty<T, P> = P extends keyof T
  ? T[P] extends string | number | symbol
    ? T[P]
    : never
  : never;

type PickFromUnion<T, V> = Extract<T, { [k in keyof T]: V }>;

type ReturnTypeOf<T> = T extends (...arg: any[]) => infer R ? R : never;

type ReturnOfObj<O, T extends O[keyof O] = O[keyof O]> = T extends O[keyof O]
  ? ReturnTypeOf<T>
  : never;

type MatchesFromObject<Item, P, E> = {
  [K in UnionFromProperty<Item, P>]: (
    data: PickFromUnion<Item, K>,
  ) => E extends never ? any : E;
};

type MatchesFromString<Item extends string, E> = {
  [K in Item]: (data: K) => E extends never ? any : E;
};

type MayBeMatchesFromObject<Item, P, E> = {
  [K in UnionFromProperty<Item, P>]?: (
    data: PickFromUnion<Item, K>,
  ) => E extends never ? any : E;
} & { orElse: () => E extends never ? any : E };

type MayBeMatchesFromString<Item extends string, E> = {
  [K in Item]?: (data: K) => E extends never ? any : E;
} & { orElse: () => E extends never ? any : E };

export function match<Item, P extends keyof Item>(
  input: [Item, P],
): <E>(
  matchCases: MatchesFromObject<Item, P, E>,
) => ReturnOfObj<typeof matchCases>;

export function match<Item extends string>(
  input: [Item],
): <E>(
  matchCases: MatchesFromString<Item, E>,
) => ReturnOfObj<typeof matchCases>;

export function match(input: any) {
  return (matchCases: any) => {
    const item = input[0];
    const property = input[1];
    if (typeof item === 'string') {
      return matchCases[item](item);
    }

    return matchCases[item[property]](item);
  };
}

export function mayBeMatch<Item, P extends keyof Item>(
  input: [Item, P],
): <E>(
  matchCases: MayBeMatchesFromObject<Item, P, E>,
) => ReturnOfObj<typeof matchCases>;

export function mayBeMatch<Item extends string>(
  input: [Item],
): <E>(
  matchCases: MayBeMatchesFromString<Item, E>,
) => ReturnOfObj<typeof matchCases>;

export function mayBeMatch(input: any) {
  return (matchCases: any) => {
    const item = input[0];
    const property = input[1];
    try {
      if (typeof item === 'string') {
        return matchCases[item](item);
      }

      if (typeof item === 'object' && property in item) {
        return matchCases[item[property]](item);
      }

      return matchCases.orElse();
    } catch (_) {
      return matchCases.orElse();
    }
  };
}
