import { createContext } from 'react';

type IProjectDeleteContext = {
  isOpen: boolean;
  projectId: string | undefined;
  showModal: (props: {
    isOpen: boolean;
    projectId?: string | undefined;
  }) => void;
};

const ProjectDeleteContext = createContext<IProjectDeleteContext>({
  isOpen: false,
  projectId: undefined,
  showModal: () => undefined,
});

export default ProjectDeleteContext;
