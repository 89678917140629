import { create } from 'zustand';

type IPageState = {
  isOpen: boolean;
  documentId: string | undefined;
  setDataHistory: (props: { isOpen: boolean; documentId?: string }) => void;
};

const createStore = create<IPageState>((set) => ({
  isOpen: false,
  documentId: undefined,
  setDataHistory: (props: { isOpen: boolean; documentId?: string }) =>
    set({ isOpen: props.isOpen, documentId: props.documentId }),
}));

const useDocumentContentHistoryProvider = () => {
  const { isOpen, documentId, setDataHistory } = createStore();
  return {
    isOpen,
    documentId,
    event: {
      setDataHistory,
    },
  };
};

export default useDocumentContentHistoryProvider;
