import orderBy from 'lodash/orderBy';

import { WorkflowType } from '@/types/gql/generated';
import { WorkflowFragment } from '@/types/gql/generated';

class CustomWorkflows {
  static sortWorkflows(workflows: WorkflowFragment[]) {
    const backlogs = workflows.filter(
      (item) => item.type === WorkflowType.Backlog,
    );
    const planned = workflows.filter(
      (item) => item.type === WorkflowType.Planned,
    );
    const inprogress = workflows.filter(
      (item) => item.type === WorkflowType.InProgress,
    );
    const completed = workflows.filter(
      (item) => item.type === WorkflowType.Completed,
    );
    const canceled = workflows.filter(
      (item) => item.type === WorkflowType.Canceled,
    );

    return [
      ...backlogs,
      ...planned,
      ...orderBy(inprogress, ['order'], 'asc'),
      ...completed,
      ...canceled,
    ];
  }
}

export default CustomWorkflows;
