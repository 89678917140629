import { useContext, useState } from 'react';

import { yupResolver } from '@hookform/resolvers/yup';
import { useForm } from 'react-hook-form';
import * as yup from 'yup';

import { Button } from '@/components/Button';
import {
  Dialog,
  DialogContent,
  DialogTitle,
  DialogTrigger,
} from '@/components/Dialog';
import { Form, FormField } from '@/components/Form';
import { Input } from '@/components/Input';
import { Label } from '@/components/Label';
import { LabelError } from '@/components/LabelError';
import { Separator } from '@/components/Separator';
import { useErrorHandler, useSuccessHandler } from '@/hooks/useToasterHandler';
import { CloseFillIcon, Plus } from '@/icons/core';
import EmojiIcon, { IEmojiIcon } from '@/icons/emoji';
import WorkspaceContext from '@/providers/workspace/WorkspaceContext';
import {
  TeamFragment,
  useCreateMemberWorkspaceMutation,
  WorkspaceFragment,
} from '@/types/gql/generated';

import TeamSelection from '../../TeamSelection';

export type ICreateMemberWorkspace = {
  email: string;
};

export const schemaCreateMemberWorkspace = yup.object().shape({
  email: yup.string().required(),
});
const CreateInviteMember = (props: {
  initSelectedTeams: TeamFragment[];
  workspace: WorkspaceFragment;
  children: React.ReactNode;
}) => {
  const { showSuccessToast } = useSuccessHandler();
  const { showErrorToast } = useErrorHandler();

  const { teams } = useContext(WorkspaceContext);
  const [selectedTeams, setSelectedTeams] = useState<TeamFragment[]>(
    props.initSelectedTeams,
  );
  const [open, setOpen] = useState<boolean>(false);
  const form = useForm<ICreateMemberWorkspace>({
    resolver: yupResolver<ICreateMemberWorkspace>(schemaCreateMemberWorkspace),
    defaultValues: {
      email: '',
    },
  });
  const createMemberWorkspace = useCreateMemberWorkspaceMutation({
    onMutate: () => {},
    onError: (res) => {
      showErrorToast({ error: res });
    },
    onSuccess: async () => {
      showSuccessToast({
        title: 'Member has been invited',
        description: 'Invite has been sent to member.',
      });
      form.reset();
      setSelectedTeams(
        props.initSelectedTeams.length > 0 ? props.initSelectedTeams : [],
      );
      setOpen(false);
    },
    onSettled: () => {},
  });
  const onSubmitCreate = (data: ICreateMemberWorkspace) => {
    createMemberWorkspace.mutate({
      createInput: {
        email: data.email ?? '',
        team_id: selectedTeams.map((item) => item.id),
        workspace_id: props.workspace.id,
      },
    });
  };

  const onSelected = (data: TeamFragment) => {
    const clone = [...selectedTeams];
    clone.push(data);
    setSelectedTeams([...clone]);
  };

  const onUnselected = (data: TeamFragment) => {
    setSelectedTeams([...selectedTeams.filter((item) => item.id !== data.id)]);
  };

  return (
    <Dialog
      open={open}
      onOpenChange={(data) => {
        if (!data) {
          setSelectedTeams(
            props.initSelectedTeams.length > 0 ? props.initSelectedTeams : [],
          );
        }
        setOpen(data);
      }}
    >
      <DialogTrigger>{props.children}</DialogTrigger>
      <DialogContent className="max-w-[450px]">
        <Form {...form}>
          <form onSubmit={form.handleSubmit(onSubmitCreate)}>
            <DialogTitle className="py-4">Invite to your workspace</DialogTitle>
            <div className="flex flex-col gap-4">
              <div className="flex flex-col gap-2">
                <Label>Email</Label>
                <FormField
                  control={form.control}
                  name="email"
                  render={({ field }) => (
                    <>
                      <Input {...field} placeholder="Email"></Input>

                      <LabelError>
                        {form.formState.errors.email?.message}
                      </LabelError>
                    </>
                  )}
                />
              </div>
              <div className="flex flex-col gap-2">
                <Label>Add to team (optional)</Label>
                <div className="flex min-h-[40px] w-full flex-wrap rounded-lg border p-2">
                  {selectedTeams.map((item) => (
                    <div className="flex flex-row gap-1 p-1">
                      <Button
                        size={'sm'}
                        type="button"
                        className="p-1 px-2"
                        onClick={() => onUnselected(item)}
                      >
                        <div className="flex flex-row items-center gap-2">
                          <div>
                            {item.icon && (
                              <EmojiIcon
                                tag={item.icon as IEmojiIcon}
                                attrs={{
                                  width: 20,
                                  height: 20,
                                  color: item.color,
                                }}
                              />
                            )}
                          </div>
                          <div>{item.name}</div>
                          <div>
                            <CloseFillIcon width={15} height={15} />
                          </div>
                        </div>
                      </Button>
                    </div>
                  ))}
                  <TeamSelection
                    teams={teams}
                    selected={selectedTeams}
                    onSelected={onSelected}
                  >
                    <Button size={'sm'} type="button">
                      <Plus />
                    </Button>
                  </TeamSelection>
                </div>
              </div>
              <div className="py-[20px]">
                <Separator className="w-full opacity-50" />
              </div>
            </div>
            <div className="flex flex-row items-center justify-end gap-4">
              <Button
                size={'sm'}
                type="button"
                variant={'secondary'}
                onClick={() => setOpen(false)}
              >
                Cancel
              </Button>
              <Button size={'sm'} type="submit">
                Send invite
              </Button>
            </div>
          </form>
        </Form>
      </DialogContent>
    </Dialog>
  );
};

export default CreateInviteMember;
