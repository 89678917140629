import { useContext, useState } from 'react';

import {
  CircleUserRoundIcon,
  HistoryIcon,
  KeyRoundIcon,
  LockIcon,
  CopyIcon,
  MessageCircleMoreIcon,
  SettingsIcon,
  PaperclipIcon,
} from 'lucide-react';

import { Button } from '@/components/Button';
import { Dialog, DialogContent, DialogTrigger } from '@/components/Dialog';
import { Progress } from '@/components/Progress';
import { Separator } from '@/components/Separator';
import WorkspaceContext from '@/providers/workspace/WorkspaceContext';

const ItemFeature = (props: { icon: React.ReactNode; label: string }) => {
  return (
    <p className="flex flex-row gap-1">
      <span className="items-centers flex w-[30px] justify-start text-blue-500">
        {props.icon}
      </span>
      {props.label}
    </p>
  );
};

const FreePlanModal = (props: { children: React.ReactNode }) => {
  const { issues } = useContext(WorkspaceContext);
  const [isOpen, setIsOpen] = useState<boolean>(false);
  const totalIssues = issues.length;
  return (
    <Dialog open={isOpen} onOpenChange={setIsOpen}>
      <DialogTrigger asChild>{props.children}</DialogTrigger>
      <DialogContent className="max-w-[1000px] p-0">
        <div>
          <div className="grid grid-cols-1 gap-2 lg:grid-cols-2">
            <div className="flex flex-col gap-8 p-4">
              <div className="pb-[10px] md:pb-[20px]">
                <h4 className="inline-block border bg-secondary p-2 text-2xl font-semibold">
                  W-SPACES
                </h4>
              </div>
              <div className="flex flex-col gap-2">
                <p>Your workspace is on the free plan</p>
                <p className="opacity-50">
                  Upgrade to remove limits and access additional features
                </p>
              </div>
              <div>
                <Progress
                  color="bg-green-500"
                  value={(totalIssues / 250) * 100}
                  className="h-[5px] "
                />
                <div className="mt-[10px] flex flex-row gap-1 text-sm">
                  <span className="font-semibold text-green-500">
                    {totalIssues}
                  </span>
                  <span className="opacity-50">/ 250 issues</span>
                </div>
              </div>
            </div>
            <div className="bg-secondary p-4  md:pb-14">
              <div>
                <h5 className="pb-[20px] text-2xl md:pb-[50px]">
                  Paid features
                </h5>
                <div className="grid grid-cols-2 gap-4 text-sm">
                  <ItemFeature
                    icon={<MessageCircleMoreIcon width={18} />}
                    label={'Priority support'}
                  />
                  <ItemFeature
                    icon={<LockIcon width={18} />}
                    label={'Private teams'}
                  />
                  <ItemFeature
                    icon={<HistoryIcon width={18} />}
                    label={'Advanced history'}
                  />
                  <ItemFeature
                    icon={<SettingsIcon width={18} />}
                    label={'Admin tools'}
                  />
                  <ItemFeature
                    icon={<KeyRoundIcon width={18} />}
                    label={'Authentication controls'}
                  />
                  <ItemFeature
                    icon={<CircleUserRoundIcon width={18} />}
                    label={'Unlimited members'}
                  />
                  <ItemFeature
                    icon={<CopyIcon width={18} />}
                    label={'Unlimited issues'}
                  />
                  <ItemFeature
                    icon={<PaperclipIcon width={18} />}
                    label={'Unlimited file uploads'}
                  />
                </div>
              </div>
            </div>
          </div>
          <Separator className="m-0 p-0" />
          <div className="flex w-full flex-row items-center justify-end gap-4 p-4  ">
            <Button onClick={() => setIsOpen(false)} variant={'ghost'}>
              Dismiss
            </Button>
            <Button disabled>Choose plan</Button>
          </div>
        </div>
      </DialogContent>
    </Dialog>
  );
};

export default FreePlanModal;
