import React from 'react';

import { RouteObject } from 'react-router-dom';

import SettingTeamGeneralPage from '../../pages/settings/team/general';
import SettingTeamLabelsPage from '../../pages/settings/team/labels';
import SettingTeamMembersPage from '../../pages/settings/team/members';
import SettingTeamWorkflowPage from '../../pages/settings/team/workflow';

const AuthSettingsTeamRoute: RouteObject[] = [
  {
    path: ':workspace/settings/:team/general',
    element: <SettingTeamGeneralPage />,
  },
  {
    path: ':workspace/settings/:team/labels',
    element: <SettingTeamLabelsPage />,
  },
  {
    path: ':workspace/settings/:team/workflows',
    element: <SettingTeamWorkflowPage />,
  },
  {
    path: ':workspace/settings/:team/members',
    element: <SettingTeamMembersPage />,
  },
];

export default AuthSettingsTeamRoute;
