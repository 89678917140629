import { useContext } from 'react';

import { InfoIcon, MoreHorizontal } from 'lucide-react';
import { useNavigate, useParams } from 'react-router-dom';

import { Button } from '@/components/Button';
import { Label } from '@/components/Label';
import { Separator } from '@/components/Separator';
import { useErrorHandler } from '@/hooks/useToasterHandler';
import { ArrowRightIcon, FavoriteFilledIcon, FavoriteIcon } from '@/icons/core';
import { TopNavigationBody } from '@/providers/main/components/TopNavigationBody';
import WorkspaceContext from '@/providers/workspace/WorkspaceContext';
import { MemoizedRenderFavorite } from '@/renders/RenderFavorite';
import { MemoizedRenderIssue } from '@/renders/RenderIssue';
import { MemoizedRenderTeam } from '@/renders/RenderTeam';
import {
  FavoriteType,
  IssueFragment,
  TeamFragment,
  useToggleFavoriteMutation,
  WorkspaceFragment,
} from '@/types/gql/generated';

import IssueDetailComponent from './components/IssueDetail';
import IssueNavigationComponent from './components/Navigation/IssueNavigationComponent';
import IssueDetailProvider from './providers';
import { ToolsMenuIssue } from '../components/ContextMenuIssue';

const IssueNotFound = () => {
  const params = useParams();
  return (
    <div className="absolute left-1/2 top-1/2 -translate-x-1/2 -translate-y-1/2 text-center">
      <div className="flex flex-col gap-2">
        <h2 className="flex items-center justify-center gap-2 font-semibold">
          <InfoIcon />
          Issue Not Found
        </h2>
        <p>There is no issue with the identifier {params.identifier}</p>
      </div>
    </div>
  );
};

const HeaderDetailContent = (props: {
  data: IssueFragment;
  team: TeamFragment;
}) => {
  const { showErrorToast } = useErrorHandler();
  const navigate = useNavigate();
  const { selected } = useContext(WorkspaceContext);
  const toggleFavorite = useToggleFavoriteMutation({
    onMutate: () => {},
    onError: (res) => {
      showErrorToast({ error: res });
    },
    onSuccess: () => {},
    onSettled: () => {},
  });
  return (
    <TopNavigationBody>
      <div className="flex w-full gap-2">
        <div className="flex grow">
          <div className="flex flex-none flex-row items-center gap-2">
            <div
              onClick={() => navigate(`/${selected?.url}/my-issues/assigned`)}
            >
              <Label className="font-semibold">My issues</Label>
            </div>
            <ArrowRightIcon className="flex-none" width={18} height={18} />
            {!props.data.issue_id && (
              <div
                onClick={() =>
                  navigate(
                    `/${selected?.url}/issue/${props.team.identifier}-${props.data.code}`,
                  )
                }
              >
                <Label>{`${props.team.identifier}-${props.data.code}`}</Label>
              </div>
            )}
            {props.data.issue_id && (
              <MemoizedRenderIssue
                id={props.data.issue_id}
                renderItem={(parent) => (
                  <div className="flex flex-row items-center gap-2">
                    <div
                      onClick={() =>
                        navigate(
                          `/${selected?.url}/issue/${props.team.identifier}-${parent?.code}`,
                        )
                      }
                    >
                      <Label>{`${props.team.identifier}-${parent?.code}`}</Label>
                    </div>
                    <ArrowRightIcon
                      className="flex-none"
                      width={18}
                      height={18}
                    />
                    <Label>{`${props.team.identifier}-${props.data?.code}`}</Label>
                  </div>
                )}
              />
            )}
          </div>
          <MemoizedRenderFavorite
            map_id={props.data.id}
            type={FavoriteType.Issue}
            renderItem={(value) => (
              <div className="flex w-[50px] cursor-pointer items-center justify-center">
                {!value ? (
                  <FavoriteIcon
                    width={18}
                    height={18}
                    onClick={() =>
                      selected?.id &&
                      toggleFavorite.mutate({
                        toggleFavorite: {
                          map_id: props.data.id,
                          type: FavoriteType.Issue,
                          workspace_id: selected?.id,
                        },
                      })
                    }
                  />
                ) : (
                  <FavoriteFilledIcon
                    width={18}
                    height={18}
                    style={{ color: '#ff9000' }}
                    onClick={() =>
                      selected?.id &&
                      toggleFavorite.mutate({
                        toggleFavorite: {
                          map_id: props.data.id,
                          type: FavoriteType.Issue,
                          workspace_id: selected?.id,
                        },
                      })
                    }
                  />
                )}
              </div>
            )}
          />
          <ToolsMenuIssue
            contextMenu={false}
            issue={props.data}
            workspace_id={props.data.workspace_id}
          >
            <Button size={'sm'} variant={'ghost'}>
              <MoreHorizontal />
            </Button>
          </ToolsMenuIssue>
          {props.data.is_deleted && (
            <Button
              variant={'destructive'}
              className="flex items-center font-semibold"
            >
              Deleted
            </Button>
          )}
        </div>
        <IssueNavigationComponent
          issue={props.data}
          team={props.team}
          onNavigation={(value) => {
            navigate(
              `/${selected?.url}/issue/${props.team.identifier}-${value.code}`,
            );
          }}
        />
      </div>
    </TopNavigationBody>
  );
};

const IssueDetailContent = (props: {
  issue: IssueFragment;
  workspace: WorkspaceFragment;
}) => {
  return (
    <MemoizedRenderTeam
      id={props.issue.team_id ?? undefined}
      renderItem={(team) =>
        team && (
          <>
            <HeaderDetailContent data={props.issue} team={team} />
            <Separator />
            <IssueDetailProvider issue={props.issue}>
              <IssueDetailComponent
                issue={props.issue}
                team={team}
                workspace={props.workspace}
              />
            </IssueDetailProvider>
          </>
        )
      }
    />
  );
};

const IssueDetailPage = (props?: {
  identifier?: string | undefined;
  id?: string | undefined;
}) => {
  const params = useParams();
  const { selected } = useContext(WorkspaceContext);
  return (
    <div className="relative flex h-full w-full flex-col overflow-hidden">
      <MemoizedRenderIssue
        identifier={props?.identifier ?? params.identifier ?? ''}
        id={props?.id}
        renderItem={(issue) => {
          return (
            <>
              {issue && selected ? (
                <IssueDetailContent issue={issue} workspace={selected} />
              ) : (
                <IssueNotFound></IssueNotFound>
              )}
            </>
          );
        }}
      />
    </div>
  );
};

export default IssueDetailPage;
