/* eslint-disable import/no-extraneous-dependencies */

import React from 'react';

import { TooltipProvider } from '@radix-ui/react-tooltip';
import { Plate, PlateEditor } from '@udecode/plate-common';

import { Editor } from '@/components/plate-ui/editor';
import { SpinnerDotIcon } from '@/icons/core';

import { plugins } from '../../pages/cores/index';
import { FloatingToolbar } from '../plate-ui/floating-toolbar';
import { FloatingToolbarButtons } from '../plate-ui/floating-toolbar-buttons';

const EditorPlateComponent = React.memo(
  (props: {
    id?: string;
    editorRef: React.MutableRefObject<PlateEditor | null>;
    loading: boolean;
    placeholder?: string;
    readOnly?: boolean;
    disabled?: boolean;
    className?: string;
    onSubmit?: () => void;
  }) => {
    return (
      <div className="relative w-full">
        {props.loading && (
          <SpinnerDotIcon
            width={18}
            height={18}
            className="absolute -top-[20px] right-0"
          />
        )}

        <TooltipProvider>
          <Plate
            plugins={plugins}
            editorRef={props.editorRef}
            readOnly={props.readOnly}
          >
            <Editor
              readOnly={props.readOnly}
              disabled={props.disabled}
              variant={'ghost'}
              focusRing={false}
              placeholder={props.placeholder}
              style={{ padding: 0 }}
              onBlur={() => props.onSubmit && props.onSubmit()}
              className={props.className}
            />
            <FloatingToolbar>
              <FloatingToolbarButtons />
            </FloatingToolbar>
          </Plate>
        </TooltipProvider>
      </div>
    );
  },
);

export default EditorPlateComponent;
