/* eslint-disable import/no-extraneous-dependencies */
import {
  ApolloClient,
  InMemoryCache,
  NormalizedCacheObject,
  from,
  ApolloLink,
  split,
  createHttpLink,
} from '@apollo/client';
import { GraphQLWsLink } from '@apollo/client/link/subscriptions';
import { getMainDefinition } from '@apollo/client/utilities';
import createUploadLink from 'apollo-upload-client/createUploadLink.mjs';
import { createClient } from 'graphql-ws';

import authLink from './auth-link';
import cancelRequestLink from './cancel-request-link';
import { customRequestIdLink } from './custom-request-id-link';
import errorLink from './error-link';
import namedLink from './named-link';
import { API_URL, WS_URL } from '../constant';
import LocalStorage from '../local-storage';

const cache = new InMemoryCache();
const authClientCache = new InMemoryCache();

let anonymousClient: ApolloClient<NormalizedCacheObject> | undefined;
let authClient: ApolloClient<NormalizedCacheObject> | undefined;
const token = LocalStorage.getToken();
const httpLink = createUploadLink({
  uri: API_URL,
});

const wsLink = new GraphQLWsLink(
  createClient({
    url: WS_URL,
    connectionParams: {
      headers: { authorization: token ? `Bearer ${token}` : '' },
    },
  }),
);

export const getClient = () => {
  if (anonymousClient) return anonymousClient;

  anonymousClient = new ApolloClient({
    link: from([
      namedLink,
      customRequestIdLink,
      errorLink,
      createHttpLink({ uri: API_URL }),
    ]),
    cache,
    defaultOptions: {
      query: {
        fetchPolicy: 'network-only',
      },
    },
  });

  return anonymousClient;
};
function splitByOperation({ query }: { query: any }) {
  const definition = getMainDefinition(query);
  return (
    definition.kind === 'OperationDefinition' &&
    definition.operation === 'subscription'
  );
}
const terminatingLink = split(
  splitByOperation,
  wsLink,
  authLink.concat(httpLink),
);
const link = ApolloLink.from([
  cancelRequestLink,
  namedLink,
  customRequestIdLink,
  errorLink,
  terminatingLink,
]);

export const getAuthClient = () => {
  if (authClient) return authClient;

  authClient = new ApolloClient({
    link: link,
    cache: authClientCache,
    queryDeduplication: false,
    defaultOptions: {
      query: {
        fetchPolicy: 'network-only',
      },
      watchQuery: {
        fetchPolicy: 'network-only',
      },
    },
  });

  return authClient;
};
