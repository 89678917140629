import { useState } from 'react';

import { Label } from '@radix-ui/react-dropdown-menu';

import { Button } from '@/components/Button';
import { Popover, PopoverContent, PopoverTrigger } from '@/components/Popover';
import { TooltipRoot } from '@/components/Tooltip';

import { ConditionFilterList, IConditionFilter } from '../../constant/constant';

const FilterType = (props: {
  children: React.ReactNode;
  selected: IConditionFilter;
  onSelected: (value: IConditionFilter) => void;
}) => {
  const [isOpen, setIsOpen] = useState<boolean>(false);
  const [selected, setSelected] = useState<IConditionFilter>(props.selected);

  return (
    <Popover
      modal={true}
      open={isOpen}
      onOpenChange={(value) => {
        setIsOpen(value);
      }}
    >
      <TooltipRoot content="Filter issues">
        <PopoverTrigger className="w-full">{props.children}</PopoverTrigger>
      </TooltipRoot>
      <PopoverContent className="p-[5px]">
        <div className="flex w-full flex-col">
          {ConditionFilterList.map((item) => (
            <div key={item.name} className="flex flex-row items-center">
              <Button
                disabled={selected.id === item.id}
                onClick={() => {
                  props.onSelected(item);
                  setSelected(item);
                  setIsOpen(false);
                }}
                type={'button'}
                variant={'ghost'}
                className="w-full"
              >
                <div className="flex w-full flex-row items-center gap-2">
                  {item.icon}
                  <Label>{item.name}</Label>
                </div>
              </Button>
            </div>
          ))}
        </div>
      </PopoverContent>
    </Popover>
  );
};

export { FilterType };
