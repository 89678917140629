import { create } from 'zustand';

type IPageState = {
  isOpen: boolean;
  documentId: string | undefined;
  setData: (props: { isOpen: boolean; documentId?: string }) => void;
};

const createStore = create<IPageState>((set) => ({
  isOpen: false,
  documentId: undefined,
  setData: (props: { isOpen: boolean; documentId?: string }) =>
    set({ isOpen: props.isOpen, documentId: props.documentId }),
}));

const useDocumentDeleteProvider = () => {
  const { isOpen, documentId, setData } = createStore();
  return {
    isOpen,
    documentId,
    event: {
      setData,
    },
  };
};

export default useDocumentDeleteProvider;
