import { useState } from 'react';

import { Button } from '@/components/Button';
import { Input } from '@/components/Input';
import { Label } from '@/components/Label';
import {
  Popover,
  PopoverClose,
  PopoverContent,
  PopoverTrigger,
} from '@/components/Popover';
import { ScrollArea, ScrollBar } from '@/components/ScrollArea';
import { Separator } from '@/components/Separator';
import EmojiIcon, { IEmojiIcon } from '@/icons/emoji';
import { TeamFragment } from '@/types/gql/generated';

const TeamSelection = (props: {
  children: React.ReactNode;
  selected: TeamFragment[];
  teams: TeamFragment[];
  onSelected: (data: TeamFragment) => void;
}) => {
  const [filter, setFilter] = useState<string>('');
  const filterList = props.teams
    .filter((item) => item.name.includes(filter))
    .filter((item) => !props.selected.find((x) => x.id === item.id));
  return (
    <Popover>
      <PopoverTrigger>{props.children}</PopoverTrigger>
      <PopoverContent style={{ padding: '4px 0' }} className="max-w-[400px]">
        <div className="flex w-full items-center p-[8px]">
          <Input
            className="w-full"
            placeholder="Search team"
            value={filter}
            onChange={(e) => setFilter(e.currentTarget.value)}
          ></Input>
        </div>
        <Separator className="w-full" />
        <ScrollArea type="auto" style={{ maxHeight: 180 }}>
          <div className="w-full p-[8px]">
            {filterList.length > 0 &&
              filterList.map((item) => (
                <PopoverClose
                  onClick={() => props.onSelected(item)}
                  key={item.id}
                  className="w-full text-left"
                >
                  <Button
                    size={'sm'}
                    type="button"
                    variant={'ghost'}
                    className="w-full px-[5px] text-left"
                  >
                    <div className="flex w-full flex-row items-center gap-3">
                      <div className="w-[20px] flex-none">
                        {item.icon && (
                          <span className="flex h-[24px] w-[24px] items-center rounded bg-secondary p-1">
                            <EmojiIcon
                              tag={item.icon as IEmojiIcon}
                              attrs={{
                                width: 20,
                                height: 20,
                                color: item.color,
                              }}
                            />
                          </span>
                        )}
                      </div>
                      <div className="grow">{item.name}</div>
                    </div>
                  </Button>
                </PopoverClose>
              ))}
            {filterList.length === 0 && (
              <div className="p-2 text-center">
                <Label>No results</Label>
              </div>
            )}
          </div>
          <ScrollBar orientation="vertical" />
        </ScrollArea>
      </PopoverContent>
    </Popover>
  );
};

export default TeamSelection;
