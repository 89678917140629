import { useContext, useEffect, useState } from 'react';

import SubscriptionContext from '@/providers/subscriptions/SubscriptionContext';
import { PushEventType } from '@/types/gql/generated';
import { ActivityFragment } from '@/types/gql/generated';

const useActivities = () => {
  const { pubsub } = useContext(SubscriptionContext);
  const [activities, setActivities] = useState<ActivityFragment[]>([]);
  const [issueId, setIssueId] = useState<string>();

  const storeActivities = async (props: {
    issue_id: string;
    activities: ActivityFragment[];
  }) => {
    setActivities(props.activities);
    setIssueId(props.issue_id);
  };

  const pushItem = async (props: { activity: ActivityFragment }) => {
    const clone = Object.assign([], activities);
    clone.push(props.activity);
    setActivities(clone);
  };

  const updateItem = async (props: { activity: ActivityFragment }) => {
    const clone: ActivityFragment[] = Object.assign([], activities);
    setActivities(
      clone.map((item) => {
        if (item.id === props.activity.id) return { ...props.activity };
        return { ...item };
      }),
    );
  };

  const popItem = async (props: { activity_id: string }) => {
    const clone: ActivityFragment[] = Object.assign(
      [],
      activities.filter((item) => item.id !== props.activity_id),
    );
    setActivities(clone);
  };

  useEffect(() => {
    if (pubsub && pubsub.event) {
      switch (pubsub.event) {
        case PushEventType.ActivityUpdatedAssignee:
        case PushEventType.ActivityUpdatedLabels:
        case PushEventType.ActivityUpdatedPriority:
        case PushEventType.ActivityUpdatedDescription:
        case PushEventType.ActivityUpdatedWorkflow:
        case PushEventType.ActivityAddedComment:
          {
            const activity = pubsub.data as ActivityFragment;
            if (activity.issue_id !== issueId) {
              break;
            }
            const findItem = activities.find(
              (item) => item.id === activity?.id,
            );

            if (!findItem) {
              pushItem({ activity: activity });
            } else {
              updateItem({ activity: activity });
            }
          }
          break;
        case PushEventType.ActivityDeletedComment:
          if (!pubsub.id) break;
          popItem({ activity_id: pubsub.id });
          break;
        default:
          break;
      }
    }
  }, [pubsub]);

  return {
    activities,
    event: {
      storeActivities,
    },
  };
};

export default useActivities;
