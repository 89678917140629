import { createContext } from 'react';

type IProjectServicesContext = {
  isShowCreateProject: boolean;
  showCreateProjectPopup: (props: {
    isOpen: boolean;
    initTeamId?: string;
  }) => void;
};

const ProjectServicesContext = createContext<IProjectServicesContext>({
  isShowCreateProject: false,
  showCreateProjectPopup: () => undefined,
});

export default ProjectServicesContext;
