import { createContext } from 'react';

type IIssueDeleteContext = {
  isOpen: boolean;
  issueId: string | undefined;
  showModal: (props: { isOpen: boolean; issueId?: string | undefined }) => void;
};

const IssueDeleteContext = createContext<IIssueDeleteContext>({
  isOpen: false,
  issueId: undefined,
  showModal: () => undefined,
});

export default IssueDeleteContext;
