import { useContext } from 'react';

import { Button } from '@/components/Button';
import { TablerListIcon } from '@/icons/core';

import MainContext from '../MainContext';

export const ToggleMenuSidebar = () => {
  const { toggleMenuSidebar } = useContext(MainContext);

  return (
    <Button
      type="button"
      variant={'secondary'}
      className="flex flex-row items-center lg:hidden"
      onClick={() => toggleMenuSidebar!(true)}
    >
      <TablerListIcon width={20} height={20} />
    </Button>
  );
};
