import {
  DocumentTextSolid,
  ImageIcon,
  PhFolderOpenFill,
  PowerpointIcon,
  TextCaseTitle16Filled,
} from '@/icons/core';

const FileMimeType = (props: { type: string }) => {
  if (
    props.type.includes('image') ||
    props.type.includes('x-figma') ||
    props.type.includes('postscript') ||
    props.type.includes('x-figma')
  ) {
    return <ImageIcon width={18} height={18} />;
  }

  if (
    props.type.includes('pdf') ||
    props.type.includes('word') ||
    props.type.includes('excel') ||
    props.type.includes('text')
  ) {
    return <TextCaseTitle16Filled color="blue" width={18} height={18} />;
  }
  if (props.type.includes('presentation')) {
    return <PowerpointIcon width={18} height={18} />;
  }
  if (
    props.type.includes('application/octet-stream') ||
    props.type.includes('application/zip')
  ) {
    return <PhFolderOpenFill color="red" width={18} height={18} />;
  }
  return <DocumentTextSolid color="blue" width={18} height={18} />;
};
export default FileMimeType;
