const key = {
  token: 'token',
  refreshToken: 'refreshToken',
};

export default class LocalStorage {
  // Token
  static setToken = (value: string) => localStorage.setItem(key.token, value);

  static getToken = () => localStorage.getItem(key.token) ?? '';

  static setRefreshToken = (value: string) =>
    localStorage.setItem(key.refreshToken, value);

  static getRefreshToken = () => localStorage.getItem(key.refreshToken) ?? '';
}
