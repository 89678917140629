import {
  KeyboardCode,
  KeyboardCoordinateGetter,
  closestCorners,
  getFirstCollision,
} from '@dnd-kit/core';

const directions = [
  KeyboardCode.Down,
  KeyboardCode.Right,
  KeyboardCode.Up,
  KeyboardCode.Left,
];

export const coordinateGetter: KeyboardCoordinateGetter = (
  event,
  { context: { active, droppableRects, droppableContainers, collisionRect } },
) => {
  if (!directions.includes(event.code as KeyboardCode)) {
    return undefined;
  }

  event.preventDefault();

  if (!active || !collisionRect) {
    return undefined;
  }

  const activeType = active.data.current?.type;
  const filteredContainers = droppableContainers
    .getEnabled()
    .filter((entry) => {
      if (!entry || entry.disabled) {
        return false;
      }

      const rect = droppableRects.get(entry.id);
      if (!rect) {
        return false;
      }

      const data = entry.data.current;
      if (data) {
        const { type, children } = data;
        if (
          type === 'Column' &&
          children?.length > 0 &&
          activeType !== 'Column'
        ) {
          return false;
        }
      }

      switch (event.code) {
        case KeyboardCode.Down:
          return activeType !== 'Column' && collisionRect.top < rect.top;
        case KeyboardCode.Up:
          return activeType !== 'Column' && collisionRect.top > rect.top;
        case KeyboardCode.Left:
          return collisionRect.left >= rect.left + rect.width;
        case KeyboardCode.Right:
          return collisionRect.left + collisionRect.width <= rect.left;
        default:
          return false;
      }
    });

  const collisions = closestCorners({
    active,
    collisionRect,
    droppableRects,
    droppableContainers: filteredContainers,
    pointerCoordinates: null,
  });

  const closestId = getFirstCollision(collisions, 'id');
  if (closestId != null) {
    const newDroppable = droppableContainers.get(closestId);
    const newRect = newDroppable?.rect.current;

    if (newRect) {
      return {
        x: newRect.left,
        y: newRect.top,
      };
    }
  }

  return undefined;
};
