import React, { lazy } from 'react';

import * as Sentry from '@sentry/react';
import { createBrowserRouter } from 'react-router-dom';

import AuthenticatedWrapper from '@/providers/auth';
import ServicesWrapper from '@/providers/services';
import ThemeProvider from '@/providers/theme';
import { compose } from '@/utils/compose';

import AuthRoutes from './auth';
import Root from './Root';

const LoginPage = lazy(() => import('../pages/login/index'));
const InviteEmailPage = lazy(() => import('../pages/invite/invite-email'));
const Notfound404Page = lazy(() => import('../pages/notfound'));

const sentryCreateBrowserRouter =
  Sentry.wrapCreateBrowserRouter(createBrowserRouter);

const Error = () => {
  return <Notfound404Page />;
};
const router = sentryCreateBrowserRouter([
  {
    path: '/',
    element: compose(
      <ThemeProvider />,
      <ServicesWrapper />,
      <AuthenticatedWrapper />,
      <Root />,
    ),
    errorElement: <Error />,
    children: [...AuthRoutes],
  },

  {
    path: '/',
    element: compose(<ServicesWrapper />, <Root />),
    errorElement: <Error />,
    children: [
      {
        path: 'invite/:invite',
        element: <InviteEmailPage />,
      },
    ],
  },
  {
    path: '/login',
    element: compose(<ThemeProvider />, <ServicesWrapper />, <LoginPage />),
    errorElement: <Error />,
  },
]);

export default router;
