/* eslint-disable import/no-extraneous-dependencies */

import { useRef, useState } from 'react';

import { PlateEditor } from '@udecode/plate-common';
import { insertImage } from '@udecode/plate-media';

import { Button } from '@/components/Button';
import { Card } from '@/components/Card';
import EditorPlateComponent from '@/components/Editor/EditorPlateComponent';
import { useErrorHandler } from '@/hooks/useToasterHandler';
import useUploadAttachment from '@/hooks/useUploadAttachment';
import { useWarningHandler } from '@/hooks/useWarningHandler';
import { SpinnerDotIcon, AttachmentIcon } from '@/icons/core';
import { useCreateCommentMutation } from '@/types/gql/generated';
import PlateUtils from '@/utils/plate-utils';

const CreateCommentComponent = (props: {
  issue_id: string;
  workspace_id: string;
}) => {
  const { showWarningToast } = useWarningHandler();
  const { showErrorToast } = useErrorHandler();
  const [idRandom, setIdRandom] = useState<number>(new Date().getTime());
  const editorContentRef = useRef<PlateEditor | null>(null);
  const createComment = useCreateCommentMutation({
    onMutate: () => {},
    onError: (res) => {
      showErrorToast({ error: res });
    },
    onSuccess: () => {
      setIdRandom(new Date().getTime());
      editorContentRef.current?.reset();
    },
    onSettled: () => {},
  });

  const { onUpload, loading } = useUploadAttachment({
    type: 'comment',
    workspace_id: props.workspace_id,
    onUploadSuccess: (path) => insertImage(editorContentRef.current, path),
  });

  const onSubmit = () => {
    const getContent = PlateUtils.getContentFromTElement(
      editorContentRef.current?.children,
    );

    if (!getContent || getContent?.length === 0) {
      showWarningToast({
        title: 'Comment is required',
        description: 'Please add a comment before submitting.',
      });
      return;
    }

    createComment.mutate({
      createInput: {
        content: getContent ?? '',
        issue_id: props.issue_id,
        workspace_id: props.workspace_id,
      },
    });
  };

  return (
    <Card className="bg-secondary p-4">
      <EditorPlateComponent
        id={idRandom.toString()}
        editorRef={editorContentRef}
        loading={false}
        placeholder="Leave comment ..."
        className="min-h-[50px] bg-secondary"
      />
      <div className="flex w-full justify-end gap-2 pt-2">
        <Button
          type="button"
          size={'sm'}
          variant={'ghost'}
          disabled={loading}
          onClick={() => onUpload()}
        >
          <div className="flex flex-row items-center gap-2">
            {loading && <SpinnerDotIcon width={18} />}
            <AttachmentIcon width={16} height={16} />
          </div>
        </Button>
        <Button onClick={() => onSubmit()} type="button">
          Comment
        </Button>
      </div>
    </Card>
  );
};

export default CreateCommentComponent;
