import { SVGProps } from 'react';

export function CaretRightThin(props: SVGProps<SVGSVGElement>) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="1em"
      height="1em"
      viewBox="0 0 256 256"
      {...props}
    >
      <path
        fill="currentColor"
        d="m178.83 130.83l-80 80a4 4 0 0 1-5.66-5.66L170.34 128L93.17 50.83a4 4 0 0 1 5.66-5.66l80 80a4 4 0 0 1 0 5.66"
      ></path>
    </svg>
  );
}

export function YoutubeLogoThin(props: SVGProps<SVGSVGElement>) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="1em"
      height="1em"
      viewBox="0 0 256 256"
      {...props}
    >
      <path
        fill="currentColor"
        d="m162.22 124.67l-48-32A4 4 0 0 0 108 96v64a4 4 0 0 0 2.11 3.53a4 4 0 0 0 4.11-.2l48-32a4 4 0 0 0 0-6.66M116 152.53v-49.06L152.79 128Zm114.46-82a20 20 0 0 0-12.06-13.68C184.6 43.79 130.27 44 128 44s-56.6-.21-90.4 12.85a20 20 0 0 0-12.06 13.67C23 80.27 20 98.16 20 128s3 47.73 5.54 57.48a20 20 0 0 0 12.06 13.67C71.4 212.21 125.73 212 128 212h.71c6.89 0 57.58-.43 89.72-12.85a20 20 0 0 0 12.06-13.67C233 175.72 236 157.84 236 128s-3-47.73-5.54-57.48Zm-7.74 113a12 12 0 0 1-7.21 8.22C183.14 204.19 128.57 204 128 204s-55.11.19-87.48-12.31a12 12 0 0 1-7.21-8.22C30.87 174.17 28 157 28 128s2.87-46.17 5.28-55.47a12 12 0 0 1 7.21-8.22C72.86 51.81 127.43 52 128 52s55.11-.2 87.48 12.31a12 12 0 0 1 7.21 8.22C225.13 81.83 228 99 228 128s-2.87 46.17-5.28 55.47Z"
      ></path>
    </svg>
  );
}

export function ArrowsClockwiseThin(props: SVGProps<SVGSVGElement>) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="1em"
      height="1em"
      viewBox="0 0 256 256"
      {...props}
    >
      <path
        fill="currentColor"
        d="M220 48v48a4 4 0 0 1-4 4h-48a4 4 0 0 1 0-8h38.34l-21.45-21.46a84 84 0 0 0-118.09-.69a4 4 0 1 1-5.6-5.72a92 92 0 0 1 129.34.76L212 86.34V48a4 4 0 0 1 8 0m-30.8 138.15a83.44 83.44 0 0 1-58.68 23.91h-.47a83.52 83.52 0 0 1-58.94-24.6L49.66 164H88a4 4 0 0 0 0-8H40a4 4 0 0 0-4 4v48a4 4 0 0 0 8 0v-38.34l21.46 21.45A91.43 91.43 0 0 0 130 218.06h.51a91.45 91.45 0 0 0 64.28-26.19a4 4 0 1 0-5.6-5.72Z"
      ></path>
    </svg>
  );
}

export function UserSquareThin(props: SVGProps<SVGSVGElement>) {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 256 256" {...props}>
      <path
        fill="currentColor"
        d="M208 36H48a12 12 0 0 0-12 12v160a12 12 0 0 0 12 12h160a12 12 0 0 0 12-12V48a12 12 0 0 0-12-12M63 212a68 68 0 0 1 130 0Zm149-4a4 4 0 0 1-4 4h-6.66a76 76 0 0 0-52.75-53.13a44 44 0 1 0-41.18 0A76 76 0 0 0 54.66 212H48a4 4 0 0 1-4-4V48a4 4 0 0 1 4-4h160a4 4 0 0 1 4 4Zm-84-52a36 36 0 1 1 36-36a36 36 0 0 1-36 36"
      ></path>
    </svg>
  );
}
