/* eslint-disable import/no-extraneous-dependencies */
import { useEffect, useRef, useState } from 'react';

import { PlateEditor, TElement } from '@udecode/plate-common';
import { InfoIcon } from 'lucide-react';
import { useParams } from 'react-router-dom';

import EditorPlateComponent from '@/components/Editor/EditorPlateComponent';
import SingleLineEditorComponent from '@/components/Editor/TitleEditorComponent';
import { Label } from '@/components/Label';
import { ScrollArea, ScrollBar } from '@/components/ScrollArea';
import { Separator } from '@/components/Separator';
import { useErrorHandler } from '@/hooks/useToasterHandler';
import { MemoizedRenderProject } from '@/renders/RenderProject';
import {
  PriorityType,
  ProjectFragment,
  useUpdateProjectMutation,
} from '@/types/gql/generated';
import PlateUtils from '@/utils/plate-utils';

import ProjectPropertiesComponent from './components/ProjectPropertiesComponent';
import HeaderProjectComponent from './HeaderProjectComponent';
import { initialValueEditor } from '../cores';

const ProjectNotFound = () => {
  return (
    <div className="absolute left-1/2 top-1/2 -translate-x-1/2 -translate-y-1/2 text-center">
      <div className="flex flex-col gap-2">
        <h2 className="flex items-center justify-center gap-2 font-semibold">
          <InfoIcon />
          Project Not Found
        </h2>
        <p>There is no project with the identifier </p>
      </div>
    </div>
  );
};

const ProjectDetailComponent = (props: { project: ProjectFragment }) => {
  const { showErrorToast } = useErrorHandler();
  const editorTitleRef = useRef<PlateEditor | null>(null);
  const editorShortSummaryRef = useRef<PlateEditor | null>(null);
  const editorContentRef = useRef<PlateEditor | null>(null);

  const [selectedProjectStatusId, setSelectedProjectStatusId] = useState<
    string | undefined
  >(props.project.project_status_id ?? undefined);
  const [selectedTeamId, setSelectedTeamId] = useState<string | undefined>(
    props.project.team_ids?.[0] ?? undefined,
  );
  const [selectedLeadId, onSelectedLeadId] = useState<string | undefined>(
    props.project.leader_id ?? undefined,
  );
  const [selectedMemberIds, setSelectedMemberIds] = useState<string[]>(
    props.project.member_ids?.split(',') ?? [],
  );
  const [selectedPriority, setSelectedPriority] = useState<
    PriorityType | undefined
  >(props.project.priority);
  const [selectedLabelIds, setSelectedLabelIds] = useState<string[]>(
    props.project.label_ids?.split(',') ?? [],
  );

  const updateProject = useUpdateProjectMutation({
    onMutate: () => {},
    onError: (res) => {
      showErrorToast({ error: res });
    },
    onSuccess: () => {},
    onSettled: () => {},
  });

  useEffect(() => {
    if (props.project) {
      if (!editorContentRef.current) return;
      if (props.project.description) {
        editorContentRef.current.children = JSON.parse(
          props.project.description,
        );
        editorContentRef.current.onChange();
      } else {
        editorContentRef.current.children = initialValueEditor;
      }
      // Editor Title
      if (!editorTitleRef.current) return;
      if (props.project.title) {
        editorTitleRef.current.children = [
          { id: 1, type: 'p', children: [{ text: props.project.title }] },
        ] as TElement[];
        editorTitleRef.current.onChange();
      } else {
        editorTitleRef.current.children = initialValueEditor;
      }
      // Editor Title
      if (!editorShortSummaryRef.current) return;
      if (props.project.short_summary) {
        editorShortSummaryRef.current.children = [
          {
            id: 1,
            type: 'p',
            children: [{ text: props.project.short_summary }],
          },
        ] as TElement[];
        editorShortSummaryRef.current.onChange();
      } else {
        editorShortSummaryRef.current.children = initialValueEditor;
      }
    }
  }, [props.project]);

  return (
    <>
      {<HeaderProjectComponent data={props.project} />}
      <Separator />
      <div className="relative z-10 flex h-full grow md:pt-0">
        <ScrollArea className="w-full" type="auto">
          <div className="m-auto w-full max-w-[900px] items-start px-[24px] pb-[70px]">
            <div className="flex w-full flex-col">
              <div className="mb-[10px] mt-10 flex ">
                <SingleLineEditorComponent
                  className="min-h-[10px] text-2xl"
                  id={props.project.id}
                  loading={false}
                  editorRef={editorTitleRef}
                  disabled={props.project.is_deleted}
                  placeholder={'Document title'}
                  onSubmit={() => {
                    const getData = PlateUtils.getTitleFromTElement(
                      editorTitleRef.current?.children,
                    );
                    updateProject.mutate({
                      updateInput: {
                        id: props.project.id,
                        title: getData ?? undefined,
                      },
                    });
                  }}
                />
              </div>
              <div className="mb-[10px] flex">
                <SingleLineEditorComponent
                  id={props.project.id}
                  className="h-auto"
                  loading={false}
                  editorRef={editorShortSummaryRef}
                  disabled={props.project.is_deleted}
                  placeholder={'Short summary'}
                  onSubmit={() => {
                    const getData = PlateUtils.getTitleFromTElement(
                      editorShortSummaryRef.current?.children,
                    );
                    updateProject.mutate({
                      updateInput: {
                        id: props.project.id,
                        short_summary: getData ?? undefined,
                      },
                    });
                  }}
                />
              </div>

              <div>
                <ProjectPropertiesComponent
                  data={props.project}
                  disabled={false}
                  teamIds={selectedTeamId ? [selectedTeamId] : []}
                  selectedProjectStatusId={selectedProjectStatusId}
                  onSelectedProjectStatusId={(value) => {
                    setSelectedProjectStatusId(value);
                    if (props.project?.id) {
                      updateProject.mutate({
                        updateInput: {
                          id: props.project?.id,
                          project_status_id: value,
                        },
                      });
                    }
                  }}
                  selectedPriority={selectedPriority}
                  onSelectedPriority={(value) => {
                    setSelectedPriority(value);
                    if (props.project?.id) {
                      updateProject.mutate({
                        updateInput: {
                          id: props.project?.id,
                          priority: value,
                        },
                      });
                    }
                  }}
                  selectedLabelIds={selectedLabelIds}
                  onSelectedLabelIds={(value) => {
                    setSelectedLabelIds(value);
                    if (props.project?.id) {
                      updateProject.mutate({
                        updateInput: {
                          id: props.project?.id,
                          label_ids: value.join(','),
                        },
                      });
                    }
                  }}
                  selectedLeadId={selectedLeadId}
                  onSelectedLeadId={(value) => {
                    onSelectedLeadId(value);
                    if (props.project?.id) {
                      updateProject.mutate({
                        updateInput: {
                          id: props.project?.id,
                          leader_id: value,
                        },
                      });
                    }
                  }}
                  selectedMemberIds={selectedMemberIds}
                  onSelectedMemberIds={(value) => {
                    setSelectedMemberIds(value);
                    if (props.project?.id) {
                      updateProject.mutate({
                        updateInput: {
                          id: props.project?.id,
                          member_ids: value.join(','),
                        },
                      });
                    }
                  }}
                  onCopyId={() => {}}
                  onCopyLink={() => {}}
                />
              </div>

              <div className="my-[20px] flex w-full flex-col gap-2">
                <div>
                  <Label>Description</Label>
                </div>
                <EditorPlateComponent
                  id={props.project.id}
                  loading={false}
                  disabled={props.project.is_deleted}
                  editorRef={editorContentRef}
                  placeholder="Add description ..."
                  onSubmit={() => {
                    const getContent = PlateUtils.getContentFromTElement(
                      editorContentRef.current?.children,
                    );
                    if (getContent === props.project.description) return;
                    updateProject.mutate({
                      updateInput: {
                        id: props.project.id,
                        description: getContent,
                      },
                    });
                  }}
                />
              </div>
            </div>
          </div>
          <ScrollBar orientation="vertical" />
        </ScrollArea>
      </div>
    </>
  );
};

const ProjectDetailPage = (props?: { code?: string }) => {
  const params = useParams();

  return (
    <div className="relative flex w-full flex-col md:h-full md:overflow-hidden">
      <MemoizedRenderProject
        code={props?.code ?? params.code}
        renderItem={(project) => {
          return (
            <>
              {project ? (
                <ProjectDetailComponent project={project} />
              ) : (
                <ProjectNotFound />
              )}
            </>
          );
        }}
      />
    </div>
  );
};

export default ProjectDetailPage;
