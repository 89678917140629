import React, { useContext, useState } from 'react';

import classNames from 'classnames';
import orderBy from 'lodash/orderBy';

import { Button } from '@/components/Button';
import { Input } from '@/components/Input';
import { Label } from '@/components/Label';
import { Popover, PopoverContent, PopoverTrigger } from '@/components/Popover';
import { ScrollArea } from '@/components/ScrollArea';
import { Separator } from '@/components/Separator';
import {
  BacklogIcon,
  CancelIcon,
  CompletedIcon,
  InProgressIcon,
  PlannedIcon,
} from '@/icons/core/issue';
import WorkspaceContext from '@/providers/workspace/WorkspaceContext';
import { BaseWorkflows } from '@/services/core/constant';
import { WorkflowType, WorkflowFragment } from '@/types/gql/generated';
import { match } from '@/utils/custom-matcher';

import CheckedIcon from '../../../../icons/checked';

const RenderStatusIcon = (props?: {
  type: WorkflowType | undefined | null;
  color: string | undefined | null;
  processing?: number | undefined | null;
}) => {
  if (!props?.type) {
    return <BacklogIcon width={20} height={20} style={{ color: 'black' }} />;
  }
  return (
    props.type &&
    match([props.type])({
      [WorkflowType.Backlog]: () => (
        <BacklogIcon
          width={20}
          height={20}
          style={{ color: props.color ?? '#9e9fa8' }}
        />
      ),
      [WorkflowType.Canceled]: () => (
        <CancelIcon
          width={20}
          height={20}
          style={{ color: props.color ?? 'black' }}
        />
      ),
      [WorkflowType.Completed]: () => (
        <CompletedIcon
          width={20}
          height={20}
          style={{ color: props.color ?? 'green' }}
        />
      ),
      [WorkflowType.InProgress]: () => (
        <InProgressIcon
          attrs={{
            width: 20,
            height: 20,
            style: { color: props.color ?? 'black' },
          }}
          percent={(props.processing ? props.processing / 100 : 0) * 13}
        />
      ),
      [WorkflowType.Planned]: () => (
        <PlannedIcon
          width={20}
          height={20}
          style={{ color: props.color ?? 'black' }}
        />
      ),
    })
  );
};

const OrderButton = (props: {
  data: WorkflowFragment[];
  selected: string | undefined;
  onSelected: (value: string) => void;
}) => {
  return (
    <div>
      {BaseWorkflows.map((workflow) => {
        const getList = props.data.filter(
          (item) => item.type === workflow.type,
        );
        return orderBy(getList, ['order'], ['asc']).map((item) => (
          <Button
            key={item.id}
            type="button"
            variant={item.id === props.selected ? 'secondary' : 'ghost'}
            className="flex h-10 w-full flex-row items-center gap-2 px-[5px] text-left"
            onClick={() => props.onSelected(item.id)}
          >
            <div className="flex w-[24px] flex-none items-center justify-center">
              <RenderStatusIcon
                type={item.type}
                color={item.color}
                processing={item.processing}
              />
            </div>
            <div className="grow">
              <Label>{item.name}</Label>
            </div>
            <div
              className={classNames(
                'w-[16px] flex-none text-center opacity-0',
                {
                  'opacity-100': item.id === props.selected,
                },
              )}
            >
              <CheckedIcon />
            </div>
          </Button>
        ));
      })}
    </div>
  );
};

const ItemList = (props: {
  list: WorkflowFragment[];
  selected: string | undefined;
  onSelected: (value: string | undefined) => void;
}) => {
  return props.list.length > 0 ? (
    <div>
      <Separator className="my-[5px] w-full" orientation="horizontal" />
      <ScrollArea className="h-full max-h-[300px] w-full">
        <OrderButton
          data={props.list}
          onSelected={props.onSelected}
          selected={props.selected}
        />
      </ScrollArea>
    </div>
  ) : (
    <></>
  );
};

const StatusButton = (props: {
  title?: string;
  teamId?: string;
  selected: string | undefined;
  onSelected: (value: string | undefined) => void;
  renderItem: (value: WorkflowFragment | undefined) => React.ReactNode;
}) => {
  const { getDataByTeam } = useContext(WorkspaceContext);
  const teamData = getDataByTeam && getDataByTeam({ team_id: props.teamId });
  const [filter, setFilter] = useState<string>('');
  const [isOpen, setIsOpen] = useState<boolean>(false);
  const selectedWorkflow = props.selected
    ? teamData?.workflows.find((item) => item.id === props.selected)
    : teamData?.workflows[0];

  return (
    <Popover modal={true} open={isOpen} onOpenChange={setIsOpen}>
      <PopoverTrigger asChild className="md:w-full">
        {props.renderItem(selectedWorkflow)}
      </PopoverTrigger>

      <PopoverContent className="p-[5px]">
        <div className="w-full px-[8px] pt-[4px]">
          {props.title && (
            <Label className="line-clamp-1 max-w-[250px]">{props.title}</Label>
          )}
        </div>
        <div className="flex w-full items-center p-[8px]">
          <Input
            className="h-[30px] w-full border-none p-0 text-lg shadow-none"
            placeholder="Change status..."
            value={filter}
            autoFocus
            onChange={(e) => setFilter(e.currentTarget.value)}
          ></Input>
        </div>
        <ItemList
          list={
            teamData?.workflows.filter((item) => item.name.includes(filter)) ??
            []
          }
          onSelected={(data) => {
            props.onSelected(data);
            setIsOpen(false);
          }}
          selected={props.selected}
        />
      </PopoverContent>
    </Popover>
  );
};

const MemoizedStatusButton = React.memo(StatusButton);
const MemoizedRenderStatusIcon = React.memo(RenderStatusIcon);

export { MemoizedStatusButton, MemoizedRenderStatusIcon };
