import { ApolloLink } from '@apollo/client';

export const customRequestIdLink = new ApolloLink((operation, forward) => {
  operation.setContext(({ headers }: { headers: any }) => {
    return {
      headers: {
        ...headers,
      },
    };
  });
  return forward(operation);
});
