import { useContext, useState } from 'react';

import { Button } from '@/components/Button';
import { Card, CardContent } from '@/components/Card';
import { Label } from '@/components/Label';
import LoadingFullPage from '@/components/LoadingPage';
import { ScrollArea, ScrollBar } from '@/components/ScrollArea';
import { IssuesGrouping } from '@/pages/issue/components/ListGrouping/ListGrouping';
import { KanbanBoard } from '@/pages/kanban/KanbanBoard';
import IssueServicesContext from '@/providers/issue/IssueServicesContext';
import WorkspaceContext from '@/providers/workspace/WorkspaceContext';
import { IGroupingIssue, ILayoutIssue } from '@/types/core/core';
import { mayBeMatch } from '@/utils/custom-matcher';

import useTeamBacklog from './useTeamBacklog';
import FilterIssue from '../../issue/components/FilterIssue/FilterIssue';
import { ConditionFilterList } from '../../issue/constant/constant';
import TeamHeader from '../components/TeamHeader';
import useTeam from '../useTeam';

const TeamBacklogPage = () => {
  const { selected: selectedWorkspace } = useContext(WorkspaceContext);
  const { showCreatePopup } = useContext(IssueServicesContext);
  const { team } = useTeam();
  const {
    initial,
    issues,
    workflows,
    labels,
    members,
    event,
    selectedStatuses,
    selectedConditionStatus,
    selectedPriorities,
    selectedConditionPriority,
    selectedLabels,
    selectedConditionLabel,
    selectedAssignees,
    selectedConditionAssignee,
  } = useTeamBacklog();
  const [layout, setLayout] = useState<ILayoutIssue>('list');
  const [grouping, setGrouping] = useState<IGroupingIssue>('no_grouping');

  return (
    <div className="flex w-full flex-col" key={team?.team?.id}>
      <TeamHeader team={team?.team} name={'Backlog'} isCreateDocument={false} />
      <FilterIssue
        workflows={workflows}
        labels={labels}
        members={members}
        selectedLayout={layout}
        onSelectedLayout={setLayout}
        selectedGrouping={grouping}
        onSelectedGrouping={setGrouping}
        onClearStatuses={() => {
          event.setSelectedStatuses([]);
          event.setSelectedConditionStatus(ConditionFilterList[0]!);
        }}
        onSelectedStatuses={event.setSelectedStatuses}
        selectedStatuses={selectedStatuses}
        selectedConditionStatus={selectedConditionStatus}
        onChangeConditionStatus={event.setSelectedConditionStatus}
        onClearPriorities={() => {
          event.setSelectedPriorities([]);
          event.setSelectedConditionPriority(ConditionFilterList[0]!);
        }}
        onSelectedPriorities={event.setSelectedPriorities}
        selectedPriorities={selectedPriorities}
        selectedConditionPriority={selectedConditionPriority}
        onChangeConditionPriority={event.setSelectedConditionPriority}
        onClearLabels={() => {
          event.setSelectedLabels([]);
          event.setSelectedConditionLabel(ConditionFilterList[0]!);
        }}
        onSelectedLabels={event.setSelectedLabels}
        selectedLabels={selectedLabels}
        selectedConditionLabel={selectedConditionLabel}
        onChangeConditionLabel={event.setSelectedConditionLabel}
        onClearAssignees={() => {
          event.setSelectedAssignees([]);
          event.setSelectedConditionAssignee(ConditionFilterList[0]!);
        }}
        onSelectedAssignees={event.setSelectedAssignees}
        selectedAssignees={selectedAssignees}
        selectedConditionAssignee={selectedConditionAssignee}
        onChangeConditionAssignee={event.setSelectedConditionAssignee}
      />
      {initial &&
        issues.length > 0 &&
        mayBeMatch([layout])({
          board: () => (
            <>
              {workflows.length > 0 &&
                selectedWorkspace &&
                issues.length > 0 &&
                team?.team && (
                  <div className="grow overflow-hidden pt-2">
                    <KanbanBoard
                      workflows={workflows}
                      issues={issues}
                      team={team?.team}
                      workspace={selectedWorkspace}
                    />
                  </div>
                )}
            </>
          ),
          list: () => (
            <>
              <ScrollArea type="auto" style={{ maxHeight: '100%' }}>
                <IssuesGrouping
                  grouping={grouping}
                  issues={issues}
                  team={team?.team}
                />
                <ScrollBar orientation="vertical" />
              </ScrollArea>
            </>
          ),
          orElse: () => <></>,
        })}
      {initial && issues.length === 0 && (
        <div className="flex grow flex-col items-center justify-center">
          <Card className="max-w-[500px] bg-secondary">
            <CardContent className="p-6">
              <div className="flex flex-col gap-2">
                <Label className="text-xl font-semibold">Backlog</Label>
                <Label className="opacity-50">
                  The backlog is a place for new issues and ideas. These are
                  issues that have yet to be prioritized and put on your team’s
                  roadmap.
                </Label>
                <Label className="opacity-50">
                  Add new issues and requests to the backlog. When your team is
                  ready to work on issues in the backlog, prioritize them, move
                  them out of the backlog by setting their statuses or adding
                  them to a Cycle.
                </Label>
                <div className="pt-[20px]">
                  <Button
                    size={'sm'}
                    type="button"
                    onClick={() => {
                      showCreatePopup({
                        isOpen: true,
                        initTeamId: team?.team?.id,
                        initWorkflowId: workflows[0]?.id,
                      });
                    }}
                    variant={'default'}
                  >
                    Create a new issue
                  </Button>
                </div>
              </div>
            </CardContent>
          </Card>
        </div>
      )}
      {!initial && <LoadingFullPage description={'Loading issues...'} />}
    </div>
  );
};

export default TeamBacklogPage;
