import {
  MoreHorizontal,
  Edit2,
  Info,
  Circle,
  MoreVertical,
  Users,
  ChevronDown,
  ChevronUp,
  ChevronRight,
} from 'react-feather';

import { X, CarbonSearch } from './core';
import {
  UserSquareThin,
  ArrowsClockwiseThin,
  YoutubeLogoThin,
  CaretRightThin,
} from './thin-icons';

export const XIcon = X;
export const SearchIcon = CarbonSearch;
export const MoreHorizontalIcon = MoreHorizontal;
export const MoreVerticalIcon = MoreVertical;
export const Edit2Icon = Edit2;
export const InfoIcon = Info;
export const CircleIcon = Circle;
export const UsersIcon = Users;
export const ChevronDownIcon = ChevronDown;
export const ChevronUpIcon = ChevronUp;
export const ChevronRightIcon = ChevronRight;
export const UserSquareThinIcon = UserSquareThin;
export const ArrowsClockwiseThinIcon = ArrowsClockwiseThin;
export const YoutubeLogoThinIcon = YoutubeLogoThin;
export const CaretRightThinIcon = CaretRightThin;
