/* eslint-disable import/no-extraneous-dependencies */

import {
  MARK_BOLD,
  MARK_CODE,
  MARK_ITALIC,
  MARK_STRIKETHROUGH,
  MARK_UNDERLINE,
} from '@udecode/plate-basic-marks';
import { insertEmptyCodeBlock } from '@udecode/plate-code-block';
import { useEditorReadOnly, useEditorRef } from '@udecode/plate-common';
import { MARK_HIGHLIGHT } from '@udecode/plate-highlight';

import { Icons } from '@/components/icons';

import { MarkToolbarButton } from './mark-toolbar-button';
import { MoreDropdownMenu } from './more-dropdown-menu';
import { Separator } from './separator';
import { ToolbarGroup } from './toolbar';
import { TurnIntoDropdownMenu } from './turn-into-dropdown-menu';

export function FloatingToolbarButtons() {
  const readOnly = useEditorReadOnly();
  const editor = useEditorRef();
  return (
    <>
      {!readOnly && (
        <>
          <TurnIntoDropdownMenu />

          <ToolbarGroup>
            <Separator orientation="vertical" />
            <MarkToolbarButton nodeType={MARK_BOLD} tooltip="Bold (⌘+B)">
              <Icons.bold />
            </MarkToolbarButton>
            <MarkToolbarButton nodeType={MARK_ITALIC} tooltip="Italic (⌘+I)">
              <Icons.italic />
            </MarkToolbarButton>
            <MarkToolbarButton
              nodeType={MARK_UNDERLINE}
              tooltip="Underline (⌘+U)"
            >
              <Icons.underline />
            </MarkToolbarButton>
            <MarkToolbarButton
              nodeType={MARK_STRIKETHROUGH}
              tooltip="Strikethrough (⌘+⇧+M)"
            >
              <Icons.strikethrough />
            </MarkToolbarButton>
          </ToolbarGroup>

          <ToolbarGroup>
            <Separator orientation="vertical" />
            <MarkToolbarButton nodeType={MARK_HIGHLIGHT} tooltip="Highlight">
              <Icons.highlight />
            </MarkToolbarButton>
            <MarkToolbarButton nodeType={MARK_CODE} tooltip="Code (⌘+E)">
              <Icons.code />
            </MarkToolbarButton>
            <MarkToolbarButton
              nodeType=""
              onClick={() => {
                insertEmptyCodeBlock(editor);
              }}
              tooltip="Code (⌘+E)"
            >
              <Icons.codeblock />
            </MarkToolbarButton>
            <Separator orientation="vertical" />
          </ToolbarGroup>
        </>
      )}

      <MoreDropdownMenu />
    </>
  );
}
