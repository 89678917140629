import { useContext, useState } from 'react';

import isEmpty from 'lodash/isEmpty';
import { PlusIcon } from 'lucide-react';

import { Button } from '@/components/Button';
import { Input } from '@/components/Input';
import LoadingFullPage from '@/components/LoadingPage';
import DocumentServicesContext from '@/providers/document/DocumentServicesContext';

import useDocuments from './useDocuments';
import DocumentTableList from '../../document/DocumentTableList/DocumentTableList';
import DocumentTreeList from '../../document/DocumentTreeList/DocumentTreeList';
import TeamHeader from '../components/TeamHeader';
import useTeam from '../useTeam';

const DocumentsPage = () => {
  const [filter, setFilter] = useState<string>();
  const { initial, documents } = useDocuments();
  const { showCreateDocumentPopup } = useContext(DocumentServicesContext);

  const { team } = useTeam();

  return (
    <>
      {initial && (
        <div className="flex w-full flex-col">
          <TeamHeader
            team={team?.team}
            name={'Document'}
            isCreateDocument={true}
            right={
              <div className="flex-none">
                <Button
                  type="button"
                  variant={'ghost'}
                  size={'sm'}
                  onClick={() =>
                    showCreateDocumentPopup({
                      isOpen: true,
                      initTeamId: team?.team?.id,
                    })
                  }
                >
                  <PlusIcon width={20} height={20} />
                </Button>
              </div>
            }
          />
          <div className="p-1">
            <Input
              placeholder="Search ..."
              onChange={(e) => setFilter(e.target.value)}
            />
          </div>
          {isEmpty(filter) ? (
            <DocumentTreeList
              documents={documents.filter(
                (item) => item.title.includes(filter ?? '') && !item.is_deleted,
              )}
            />
          ) : (
            <DocumentTableList
              isSubDocuments={false}
              documents={documents.filter(
                (item) => item.title.includes(filter ?? '') && !item.is_deleted,
              )}
            />
          )}
        </div>
      )}
      {!initial && <LoadingFullPage description={'Loading issues...'} />}
    </>
  );
};

export default DocumentsPage;
