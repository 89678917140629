import React, { useContext, useState } from 'react';

import classNames from 'classnames';
import orderBy from 'lodash/orderBy';

import { Button } from '@/components/Button';
import { Input } from '@/components/Input';
import { Label } from '@/components/Label';
import { Popover, PopoverContent, PopoverTrigger } from '@/components/Popover';
import { ScrollArea } from '@/components/ScrollArea';
import { Separator } from '@/components/Separator';
import {
  BacklogIcon,
  CancelIcon,
  CompletedIcon,
  InProgressIcon,
  PlannedIcon,
} from '@/icons/core/issue';
import WorkspaceContext from '@/providers/workspace/WorkspaceContext';
import { MemoizedRenderProject } from '@/renders/RenderProject';
import { BaseProjectStatuses } from '@/services/core/constant';
import {
  ProjectStatusFragment,
  ProjectStatusType,
} from '@/types/gql/generated';
import { match } from '@/utils/custom-matcher';

import CheckedIcon from '../../../icons/checked';

const RenderStatusIcon = (props?: {
  type: ProjectStatusType | undefined | null;
  color: string | undefined | null;
  processing?: number | undefined | null;
}) => {
  if (!props?.type) {
    return <BacklogIcon width={20} height={20} style={{ color: 'black' }} />;
  }
  return (
    props.type &&
    match([props.type])({
      [ProjectStatusType.Backlog]: () => (
        <BacklogIcon
          width={20}
          height={20}
          style={{ color: props.color ?? '#9e9fa8' }}
        />
      ),
      [ProjectStatusType.Canceled]: () => (
        <CancelIcon
          width={20}
          height={20}
          style={{ color: props.color ?? 'black' }}
        />
      ),
      [ProjectStatusType.Completed]: () => (
        <CompletedIcon
          width={20}
          height={20}
          style={{ color: props.color ?? 'green' }}
        />
      ),
      [ProjectStatusType.InProgress]: () => (
        <InProgressIcon
          attrs={{
            width: 20,
            height: 20,
            style: { color: props.color ?? 'black' },
          }}
          percent={(props.processing ? props.processing / 100 : 0) * 13}
        />
      ),
      [ProjectStatusType.Planned]: () => (
        <PlannedIcon
          width={20}
          height={20}
          style={{ color: props.color ?? 'black' }}
        />
      ),
    })
  );
};

const OrderButton = (props: {
  data: ProjectStatusFragment[];
  selected: string | undefined;
  onSelected: (value: string) => void;
}) => {
  return (
    <div>
      {BaseProjectStatuses.map((workflow) => {
        const getList = props.data.filter(
          (item) => item.type === workflow.type,
        );
        return orderBy(getList, ['order'], ['asc']).map((item) => (
          <Button
            key={item.id}
            type="button"
            variant={item.id === props.selected ? 'secondary' : 'ghost'}
            className="flex h-10 w-full flex-row items-center gap-2 px-[5px] text-left"
            onClick={() => props.onSelected(item.id)}
          >
            <div className="flex w-[24px] flex-none items-center justify-center">
              <RenderStatusIcon
                type={item.type}
                color={item.color}
                processing={item.processing}
              />
            </div>
            <div className="grow">
              <Label>{item.name}</Label>
            </div>
            <div
              className={classNames(
                'w-[16px] flex-none text-center opacity-0',
                {
                  'opacity-100': item.id === props.selected,
                },
              )}
            >
              <CheckedIcon />
            </div>
          </Button>
        ));
      })}
    </div>
  );
};

const ItemList = (props: {
  list: ProjectStatusFragment[];
  selected: string | undefined;
  onSelected: (value: string | undefined) => void;
}) => {
  return props.list.length > 0 ? (
    <div>
      <Separator className="my-[5px] w-full" orientation="horizontal" />
      <ScrollArea className="h-full max-h-[300px] w-full">
        <OrderButton
          data={props.list}
          onSelected={props.onSelected}
          selected={props.selected}
        />
      </ScrollArea>
    </div>
  ) : (
    <></>
  );
};

const ProjectStatusButton = (props: {
  project_id?: string;
  selected: string | undefined;
  onSelected: (value: string | undefined) => void;
  renderItem: (value: ProjectStatusFragment | undefined) => React.ReactNode;
}) => {
  const { projectStatuses } = useContext(WorkspaceContext);
  const [filter, setFilter] = useState<string>('');
  const [isOpen, setIsOpen] = useState<boolean>(false);
  const selectedWorkflow = props.selected
    ? projectStatuses.find((item) => item.id === props.selected)
    : projectStatuses[0];

  return (
    <Popover modal={true} open={isOpen} onOpenChange={setIsOpen}>
      <PopoverTrigger asChild className="md:w-full">
        {props.renderItem(selectedWorkflow)}
      </PopoverTrigger>

      <PopoverContent className="p-[5px]">
        <div className="w-full px-[8px] pt-[4px]">
          <MemoizedRenderProject
            id={props.project_id}
            renderItem={(project) =>
              project && (
                <Label className="line-clamp-1 max-w-[250px]">
                  {project?.title}
                </Label>
              )
            }
          />
        </div>
        <div className="flex w-full items-center p-[8px]">
          <Input
            className="h-[30px] w-full border-none p-0 text-lg shadow-none"
            placeholder="Change status..."
            value={filter}
            autoFocus
            onChange={(e) => setFilter(e.currentTarget.value)}
          ></Input>
        </div>
        <ItemList
          list={
            projectStatuses.filter((item) => item.name.includes(filter)) ?? []
          }
          onSelected={(data) => {
            props.onSelected(data);
            setIsOpen(false);
          }}
          selected={props.selected}
        />
      </PopoverContent>
    </Popover>
  );
};

const MemoizedProjectStatusButton = React.memo(ProjectStatusButton);
const MemoizedRenderProjectStatusIcon = React.memo(RenderStatusIcon);

export { MemoizedProjectStatusButton, MemoizedRenderProjectStatusIcon };
