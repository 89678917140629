import { useState } from 'react';

const useProvider = () => {
  const [isShowCreateProject, setIsShowCreateProject] =
    useState<boolean>(false);

  const showCreateProjectPopup = (value: boolean) => {
    setIsShowCreateProject(value);
  };
  return {
    isShowCreateProject,
    event: {
      showCreateProjectPopup,
    },
  };
};

export default useProvider;
