import { useContext } from 'react';

import { FileText } from 'lucide-react';
import { useNavigate } from 'react-router-dom';

import { Label } from '@/components/Label';
import WorkspaceContext from '@/providers/workspace/WorkspaceContext';
import { IDocumentFragment } from '@/types/gql/generated';

import { ToolsMenuDocument } from '../ContextMenuDocument';

const RowItem = (props: {
  document: IDocumentFragment;
  isSubDocuments: boolean;
}) => {
  const { selected } = useContext(WorkspaceContext);
  const navigate = useNavigate();
  return (
    <div
      className="border-bottom flex h-[40px] w-full cursor-pointer items-center gap-2 overflow-hidden border-[1px] border-secondary px-[10px] hover:bg-secondary"
      onClick={() =>
        navigate(`/${selected?.url}/document/${props.document.id}`)
      }
    >
      <div className="flex w-full flex-row gap-2">
        <div className="flex flex-row items-center gap-2">
          <span>
            <FileText width={20} />
          </span>
          <Label asChild className="line-clamp-1 flex-auto">
            <span> {props.document.title}</span>
          </Label>
        </div>
      </div>
    </div>
  );
};

const DocumentTableList = (props: {
  documents: IDocumentFragment[];
  isSubDocuments: boolean;
}) => {
  return (
    <div className="w-full">
      {props.documents.map((item) => (
        <ToolsMenuDocument
          contextMenu={true}
          key={item.id}
          document={item}
          workspace_id={item.workspace_id}
        >
          <RowItem
            key={item.id}
            document={item}
            isSubDocuments={props.isSubDocuments}
          />
        </ToolsMenuDocument>
      ))}
    </div>
  );
};

export default DocumentTableList;
