/* eslint-disable import/no-extraneous-dependencies */
import React, { useContext, useEffect, useRef, useState } from 'react';

import { PlateEditor } from '@udecode/plate-common';
import { insertImage } from '@udecode/plate-media';
import classNames from 'classnames';
import { useNavigate } from 'react-router-dom';

import EditorPlateComponent from '@/components/Editor/EditorPlateComponent';
import SingleLineEditorComponent from '@/components/Editor/TitleEditorComponent';
import { Label } from '@/components/Label';
import {
  useErrorHandler,
  useSuccessHandler,
  useWarningHandler,
} from '@/hooks/useToasterHandler';
import useUploadAttachment from '@/hooks/useUploadAttachment';
import { SpinnerDotIcon, AttachmentIcon } from '@/icons/core';
import EmojiIcon, { IEmojiIcon } from '@/icons/emoji';
import IssueServicesContext from '@/providers/issue/IssueServicesContext';
import WorkspaceContext from '@/providers/workspace/WorkspaceContext';
import { BasePriorities } from '@/services/issue/issue-utils';
import { PriorityType, useCreateIssueMutation } from '@/types/gql/generated';
import { ICreateIssueType } from '@/types/issue/issue.interface';
import CustomDateTime from '@/utils/format-datetime';
import PlateUtils from '@/utils/plate-utils';

import { Button } from '../../../../components/Button';
import { Separator } from '../../../../components/Separator';
import { IssuePropertiesComponent } from '../IssuePropertiesComponent';
import TeamButton from '../Properties/TeamButton';

export const CreateIssueComponent = (props: {
  initTeamId?: string;
  initWorkflowId?: string;
  initMemberId?: string;
  initIssueId?: string;
  initTitle?: string;
  initContent?: string;
  type: ICreateIssueType;
  onSuccess: () => void;
  onCancel: () => void;
}) => {
  const navigation = useNavigate();
  const { showErrorToast } = useErrorHandler();
  const { showSuccessToast } = useSuccessHandler();
  const { showWarningToast } = useWarningHandler();

  const {
    teams,
    getDataByTeam,
    selected: selectedWorkspace,
  } = useContext(WorkspaceContext);

  const [selectedTeamId, setSelectedTeamId] = useState<string>();
  const [selectedWorkflowId, setSelectedWorkflowId] = useState<string>();
  const [selectedMemberId, setSelectedMemberId] = useState<string>();
  const [selectedLabelIds, setSelectedLabelIds] = useState<string[]>([]);
  const [selectedPriority, setSelectedPriority] = useState<PriorityType>();
  const { isShowCreateIssue, showCreatePopup } =
    useContext(IssueServicesContext);

  const createIssue = useCreateIssueMutation({
    onMutate: () => {},
    onError: (res) => {
      showErrorToast({ error: res });
    },
    onSuccess: (res) => {
      const dataTeam =
        getDataByTeam &&
        getDataByTeam({ team_id: res.createIssue.team_id ?? '' });

      showCreatePopup({ isOpen: false });
      props.onSuccess();

      showSuccessToast({
        title: 'Issue has been created',
        description: `${CustomDateTime.convertStringToCommon(
          res.createIssue.created_at,
        )}`,
        actions: (
          <Button
            className="absolute right-[10px]"
            variant={'outline'}
            onClick={() =>
              navigation(
                `/${selectedWorkspace?.url}/issue/${dataTeam?.team?.identifier}-${res.createIssue.code}`,
              )
            }
          >
            Detail
          </Button>
        ),
      });
    },
    onSettled: () => {},
  });

  const editorContentRef = useRef<PlateEditor | null>(null);
  const editorTitleRef = useRef<PlateEditor | null>(null);

  const { onUpload, loading } = useUploadAttachment({
    type: 'comment',
    workspace_id: selectedWorkspace?.id ?? '',
    onUploadSuccess: (path) => {
      insertImage(editorContentRef.current, path);
    },
  });

  const onSubmitCreate = () => {
    if (
      selectedTeamId &&
      selectedWorkflowId &&
      selectedWorkspace &&
      selectedPriority
    ) {
      const getTitle = PlateUtils.getTitleFromTElement(
        editorTitleRef.current?.children,
      );
      if (!getTitle || getTitle?.length === 0) {
        showWarningToast({
          title: 'Title issue is required',
          description: 'Please add a title before submitting.',
        });
        return;
      }
      const getContent = PlateUtils.getContentFromTElement(
        editorContentRef.current?.children,
      );
      createIssue.mutate({
        createInput: {
          title: getTitle,
          description: getContent ?? '',
          workspace_id: selectedWorkspace?.id,
          team_id: selectedTeamId,
          assignee_id: selectedMemberId,
          priority: selectedPriority,
          label_ids: selectedLabelIds.map((item) => item),
          workflow_id: selectedWorkflowId,
          issue_id: props.initIssueId,
        },
      });
    }
  };

  const onReset = () => {
    setSelectedWorkflowId(undefined);
    setSelectedMemberId(undefined);
    setSelectedLabelIds([]);
    setSelectedPriority(BasePriorities[0]?.type);
  };

  const onInitForm = () => {
    if (selectedTeamId && getDataByTeam) {
      const getTeamData = getDataByTeam({ team_id: selectedTeamId });
      if (props.initWorkflowId) {
        setSelectedWorkflowId(props.initWorkflowId);
      } else {
        setSelectedWorkflowId(getTeamData?.workflows[0]?.id ?? undefined);
      }
    }
  };

  useEffect(() => {
    if (teams.length > 0 && !props.initTeamId) {
      setSelectedTeamId(teams[0]?.id);
    } else {
      setSelectedTeamId(props.initTeamId);
    }

    if (props.initTitle && editorTitleRef.current) {
      editorTitleRef.current.children = PlateUtils.setStringToTElement(
        props.initTitle,
      );
    }

    if (props.initContent && editorContentRef.current) {
      editorContentRef.current.children = PlateUtils.getTElementFromContent(
        props.initContent,
      );
    }
  }, [props]);

  useEffect(() => {
    onReset();
    onInitForm();
  }, [selectedTeamId]);

  useEffect(() => {
    if (!isShowCreateIssue) {
      onReset();
    } else {
      onInitForm();
    }
  }, [isShowCreateIssue]);

  return (
    <>
      <div className="flex-none">
        <div className="flex items-center gap-2 p-[10px]">
          <TeamButton
            selected={selectedTeamId}
            onChange={setSelectedTeamId}
            renderItem={(value) => (
              <Button
                size={'sm'}
                className="h-[26px] max-w-[80px]"
                type="button"
              >
                <div className="flex flex-row items-center gap-2">
                  <EmojiIcon
                    tag={value?.icon as IEmojiIcon}
                    attrs={{
                      width: 16,
                      height: 16,
                      color: value?.color,
                    }}
                  />
                  <Label>{value?.identifier}</Label>
                </div>
              </Button>
            )}
          />
          <Label>›</Label>
          <Label>
            {props.type === 'modal' ? 'New issue' : 'New sub-issue'}
          </Label>
        </div>
      </div>
      <div className="flex flex-none flex-col px-[10px]">
        <SingleLineEditorComponent
          loading={false}
          placeholder="Title Issue"
          editorRef={editorTitleRef}
          className="min-h-[10px] text-2xl"
        />
      </div>
      <div
        className={classNames(
          'relative flex w-full grow flex-col overflow-auto px-[10px]',
          {
            'min-h-[200px]': props.type === 'modal',
            'min-h-[100px]': props.type !== 'modal',
          },
        )}
      >
        <EditorPlateComponent
          editorRef={editorContentRef}
          loading={false}
          placeholder="Add description ..."
        />
        <div className="absolute bottom-[5px] right-[10px] flex">
          <Button
            type="button"
            size={'sm'}
            variant={'ghost'}
            disabled={loading}
            onClick={() => onUpload()}
          >
            <div className="flex flex-row items-center gap-2">
              {loading && <SpinnerDotIcon width={18} />}
              <AttachmentIcon width={16} height={16} />
            </div>
          </Button>
        </div>
      </div>
      <div className="flex-none">
        <div className="w-full flex-none">
          <div className="mt-[10px] flex-none px-[10px]">
            <IssuePropertiesComponent
              teamId={selectedTeamId ?? ''}
              selectedWorkflowId={selectedWorkflowId}
              onSelectedWorkflowId={setSelectedWorkflowId}
              selectedPriority={selectedPriority}
              onSelectedPriority={setSelectedPriority}
              selectedLabelIds={selectedLabelIds}
              onSelectedLabelIds={setSelectedLabelIds}
              selectedMemberId={selectedMemberId}
              onSelectedMemberId={setSelectedMemberId}
            />
          </div>
          <Separator className="my-[10px]  w-full flex-none" />
          <div className="flex flex-none flex-row justify-end gap-2 p-[10px] pt-0">
            {props.type === 'component' && (
              <Button
                disabled={createIssue.isPending}
                type="button"
                size={'sm'}
                variant={'ghost'}
                onClick={() => props.onCancel()}
              >
                <Label>Cancel</Label>
              </Button>
            )}

            <Button
              size={'sm'}
              variant={'secondary'}
              onClick={() => onSubmitCreate()}
            >
              <Label>{props.type === 'modal' ? 'Create issue' : 'Save'}</Label>
            </Button>
          </div>
        </div>
      </div>
    </>
  );
};
