import { ApolloLink } from '@apollo/client';

import { API_URL } from '../constant';

const namedLink = new ApolloLink((operation, forward) => {
  operation.setContext(() => ({
    uri: `${API_URL}?${operation.operationName}`,
  }));
  return forward(operation);
});

export default namedLink;
