import { Outlet } from 'react-router-dom';

const Root = () => {
  return (
    <div className="relative flex h-screen w-full flex-col overflow-hidden pb-safe-offset-20 md:pb-safe-offset-0">
      <Outlet />
    </div>
  );
};

export default Root;
