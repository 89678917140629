import { create } from 'zustand';

type IPageState = {
  isOpen: boolean;
  projectId: string | undefined;
  setDataHistory: (props: { isOpen: boolean; projectId?: string }) => void;
};

const createStore = create<IPageState>((set) => ({
  isOpen: false,
  projectId: undefined,
  setDataHistory: (props: { isOpen: boolean; projectId?: string }) =>
    set({ isOpen: props.isOpen, projectId: props.projectId }),
}));

const useProjectContentHistoryProvider = () => {
  const { isOpen, projectId, setDataHistory } = createStore();
  return {
    isOpen,
    projectId,
    event: {
      setDataHistory,
    },
  };
};

export default useProjectContentHistoryProvider;
