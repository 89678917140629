import { useContext } from 'react';

import ProjectServicesContext from './ProjectServicesContext';

export const useProjectServices = () => {
  const { isShowCreateProject, showCreateProjectPopup } = useContext(
    ProjectServicesContext,
  );

  return {
    isShowCreateProject,
    showCreateProjectPopup,
  };
};

export default useProjectServices;
