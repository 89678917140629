/* eslint-disable import/no-extraneous-dependencies */

import { Plate, PlateEditor } from '@udecode/plate-common';

import { Editor } from '@/components/plate-ui/editor';
import { SpinnerDotIcon } from '@/icons/core';
import { titlePlugins } from '@/pages/cores';

const SingleLineEditorComponent = (props: {
  id?: string;
  editorRef: React.MutableRefObject<PlateEditor | null>;
  loading: boolean;
  placeholder?: string;
  disabled?: boolean;
  readOnly?: boolean;
  onSubmit?: () => void;
  className?: string;
}) => {
  return (
    <div className="relative w-full">
      {props.loading && (
        <SpinnerDotIcon
          width={18}
          height={18}
          className="absolute -top-[20px] right-0"
        />
      )}

      <Plate
        plugins={titlePlugins}
        editorRef={props.editorRef}
        readOnly={props.readOnly}
      >
        <Editor
          readOnly={props.readOnly}
          disabled={props.disabled}
          variant={'ghost'}
          focusRing={false}
          placeholder={props.placeholder}
          style={{ padding: 0 }}
          onBlur={() => props.onSubmit && props.onSubmit()}
          className={props.className}
        />
      </Plate>
    </div>
  );
};

export default SingleLineEditorComponent;
