import { useContext } from 'react';

import isNil from 'lodash/isNil';
import omitBy from 'lodash/omitBy';
import * as yup from 'yup';

import { Card } from '@/components/Card';
import { Label } from '@/components/Label';
import {
  Select,
  SelectContent,
  SelectGroup,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from '@/components/Select';
import { useErrorHandler } from '@/hooks/useToasterHandler';
import AuthenticatedUserContext from '@/providers/auth/AuthenticatedUserContext';
import ThemeContext from '@/providers/theme/ThemeContext';
import { ThemeType } from '@/types/gql/generated';
import {
  UpdateUserInput,
  UserFragment,
  useUpdateUserMutation,
} from '@/types/gql/generated';

import HeaderSetting from '../../components/HeaderSetting';

export type IUpdateUser = {
  full_name?: string;
  username?: string;
  image?: string;
  theme?: ThemeType;
};

export const schemaUpdateUser = yup.object().shape({
  full_name: yup.string().required(),
  username: yup.string().required(),
  image: yup.string(),
  theme: yup.mixed<ThemeType>().oneOf(Object.values(ThemeType)),
});

const RowSection = (props: {
  title?: string;
  description?: string;
  children: React.ReactNode;
}) => {
  return (
    <div className="flex flex-row items-center gap-2">
      <div className="flex grow flex-col gap-1">
        <Label className="grow">{props.title}</Label>
        <Label className="text-xs opacity-50">{props.description}</Label>
      </div>
      <div className="w-[200px]">{props.children}</div>
    </div>
  );
};

const ThemeSection = (props: {
  initValue?: ThemeType | undefined;
  onChange: (value: ThemeType) => void;
}) => {
  const items = [
    { title: 'Light', value: ThemeType.Light },
    { title: 'Dark', value: ThemeType.Dark },
    { title: 'System', value: ThemeType.System },
  ];
  return (
    <RowSection
      title="Interface theme"
      description="Select or customize your interface color scheme."
    >
      <Select defaultValue={props.initValue} onValueChange={props.onChange}>
        <SelectTrigger className="w-[180px]">
          <SelectValue placeholder="Select a theme" />
        </SelectTrigger>
        <SelectContent>
          <SelectGroup>
            {items.map((item) => (
              <SelectItem value={item.value}>{item.title}</SelectItem>
            ))}
          </SelectGroup>
        </SelectContent>
      </Select>
    </RowSection>
  );
};

const InformationSection = (props: { user: UserFragment }) => {
  const { onChange } = useContext(ThemeContext);
  const { showErrorToast } = useErrorHandler();
  const updateUser = useUpdateUserMutation({
    onMutate: () => {},
    onError: (res) => {
      showErrorToast({ error: res });
    },
    onSuccess: () => {},
    onSettled: () => {},
  });

  const onUpdate = (data: IUpdateUser) => {
    onChange(data.theme ?? ThemeType.System);
    const getFields = omitBy(data, isNil) as UpdateUserInput;
    updateUser.mutate({
      updateInput: { ...getFields },
    });
  };

  return (
    <Card className="p-0">
      <div className="flex flex-col gap-6 p-4">
        <ThemeSection
          initValue={props.user.theme ?? undefined}
          onChange={(value) => onUpdate({ theme: value })}
        />
      </div>
    </Card>
  );
};

const SettingAccountPreferencesPage = () => {
  const { user } = useContext(AuthenticatedUserContext);

  return (
    <div>
      <HeaderSetting
        title={'Preferences'}
        subtitle={'Manage your preferences'}
      ></HeaderSetting>
      {user && (
        <div className="w-full">
          <InformationSection user={user} />
        </div>
      )}
    </div>
  );
};

export default SettingAccountPreferencesPage;
