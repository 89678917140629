import { CreateDocumentComponent } from './CreateDocumentComponent';

export const CreateSubDocumentDialog = (props: {
  initDocumentId: string;
  initTeamId?: string;
  onSuccess: () => void;
  onCancel: () => void;
}) => {
  return <CreateDocumentComponent {...props} type={'component'} />;
};
