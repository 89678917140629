import {
  ProjectStatusFragment,
  useSortableProjectStatusMutation,
} from '@/types/gql/generated';

const useProjectStatuses = () => {
  const sortProjectStatuses = useSortableProjectStatusMutation({
    onMutate: () => {},
    onError: () => {},
    onSuccess: () => {},
    onSettled: () => {},
  });

  const sortItems = async (props: {
    projectStatuses: ProjectStatusFragment[];
  }) => {
    sortProjectStatuses.mutate({
      ids: props.projectStatuses.map((item) => item.id),
    });
  };

  return {
    event: {
      sortItems,
    },
  };
};

export default useProjectStatuses;
