import { useEffect, useState } from 'react';

import { Avatar, AvatarFallback } from '@/components/Avatar';
import { Button } from '@/components/Button';
import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuItem,
  DropdownMenuSeparator,
  DropdownMenuTrigger,
} from '@/components/DropdownMenu';
import { Input } from '@/components/Input';
import { Label } from '@/components/Label';
import { MoreHorizontalIcon } from '@/icons/index';
import {
  MemberTeamFragment,
  TeamFragment,
  useGetMembersTeamQuery,
  WorkspaceFragment,
} from '@/types/gql/generated';

import DeleteInviteMemberDialog from './delete/DeleteInviteMemberDialog';
import useInviteMembers from './useInviteMembers';
import CreateInviteMember from '../../../../members/components/invite-member/create/CreateInviteMember';

const MoreButton = (props: {
  children: React.ReactNode;
  invite: MemberTeamFragment;
  onDelete: (invite: MemberTeamFragment) => void;
}) => {
  return (
    <>
      <DropdownMenu>
        <DropdownMenuTrigger>{props.children}</DropdownMenuTrigger>
        <DropdownMenuContent>
          <DropdownMenuItem>Resend Invite</DropdownMenuItem>
          <DropdownMenuSeparator />
          <DropdownMenuItem
            onClick={() => props.onDelete(props.invite)}
            color="red"
          >
            Delete
          </DropdownMenuItem>
        </DropdownMenuContent>
      </DropdownMenu>
    </>
  );
};

const InviteList = (props: {
  invites: MemberTeamFragment[];
  onDelete: (label: MemberTeamFragment) => void;
}) => {
  return (
    <div className="mt-[30px] flex flex-col gap-4">
      {props.invites.map((item) => (
        <div key={item.id} className="flex flex-row items-center gap-4">
          <div>
            <Avatar className="h-[40px] w-[40px]">
              <AvatarFallback className="uppercase">
                {item.email.slice(0, 2)}
              </AvatarFallback>
            </Avatar>
          </div>
          <div className="grow">
            <Label className="block font-semibold">{item.email}</Label>
          </div>
          <div>
            <MoreButton onDelete={props.onDelete} invite={item}>
              <Button
                size={'sm'}
                type="button"
                variant="ghost"
                className="opacity-30 hover:opacity-100"
              >
                <MoreHorizontalIcon size={18} />
              </Button>
            </MoreButton>
          </div>
        </div>
      ))}
    </div>
  );
};

const InviteMember = (props: {
  team?: TeamFragment;
  workspace: WorkspaceFragment;
}) => {
  const { event, membersTeam } = useInviteMembers();
  const [filterName, setFilterName] = useState<string>();
  const [selectedDelete, setSelectedDelete] = useState<
    MemberTeamFragment | undefined
  >();

  const getMembersTeamQuery = useGetMembersTeamQuery(
    {
      getInput: {
        team_id: props.team?.id,
      },
    },
    { enabled: !!props.team?.id },
  );

  useEffect(() => {
    event.setItems(getMembersTeamQuery.data?.membersTeam ?? []);
  }, [getMembersTeamQuery.data?.membersTeam]);

  const onDeleteSuccess = (data: MemberTeamFragment) => {
    event.popItem({ inviteMember: data });
    setSelectedDelete(undefined);
  };

  const onDeleteInviteMember = (data: MemberTeamFragment) => {
    setSelectedDelete(data);
  };

  return (
    <div className="mt-[30px] w-full">
      <div className="flex flex-col gap-2">
        <Label className="font-semibold">Manage members</Label>
      </div>
      <div className="mt-[30px] flex w-full flex-row items-center gap-4">
        <div className="grow">
          <div className="max-w-[300px]">
            <Input
              placeholder="Search by name or email"
              value={filterName}
              onChange={(e) => setFilterName(e.currentTarget.value)}
            ></Input>
          </div>
        </div>
        <div>
          <CreateInviteMember
            workspace={props.workspace}
            initSelectedTeams={props.team ? [props.team] : []}
          >
            <Button size={'sm'} type="button">
              Add a member
            </Button>
          </CreateInviteMember>
        </div>
      </div>
      <InviteList
        invites={membersTeam.filter((item) =>
          item.email.includes(filterName ?? ''),
        )}
        onDelete={onDeleteInviteMember}
      />
      <DeleteInviteMemberDialog
        inviteMember={selectedDelete}
        onCancel={() => setSelectedDelete(undefined)}
        onDeleteSuccess={onDeleteSuccess}
      />
    </div>
  );
};

export default InviteMember;
