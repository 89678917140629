import { ServerParseError } from '@apollo/client';
import { GraphQLErrors } from '@apollo/client/errors';
import { onError } from '@apollo/client/link/error';
import { ServerError as GraphqlServerError } from '@apollo/client/link/utils/throwServerError';

import {
  ErrorType,
  GraphDocumentError,
  InvalidCredentialsError,
  InvalidInputError,
  NetworkError,
  NotAllowedError,
  NotFoundError,
  OtherError,
  ServerError,
  UnauthorizedError,
  UnexpectedError,
} from '../error-type';

// let refreshPromise: Promise<FetchResult<RefreshTokenMutation>> | null = null;

const isGraphqlServerError = (error: any): error is GraphqlServerError =>
  error && typeof error === 'object' && 'result' in error;

const isServerParseError = (error: any): error is ServerParseError =>
  error && typeof error === 'object' && 'bodyText' in error;

const convertGraphQlError = (graphQLErrors: GraphQLErrors): ErrorType => {
  let serverError: ServerError | null = null;
  let unexpected: UnexpectedError | null = null;
  let graphDocumentError: GraphDocumentError | null = null;
  const invalidInput: InvalidInputError | null = null;
  let invalidCredentials: InvalidCredentialsError | null = null;
  let unauthorized: UnauthorizedError | null = null;
  let notAllowed: NotAllowedError | null = null;
  let notFound: NotFoundError | null = null;
  let otherError: OtherError | null = null;

  const codes = {
    unexpected: 'unexpected',
    'validation-failed': 'validation-failed',
    'access-denied': 'access-denied',
  };

  const categories = {
    internal: 'internal',
    unauthorized: 'unauthorized',
    invalid_credentials: 'invalid_credentials',
    not_found: 'not_found',
    arguments_validation_error: 'arguments_validation_error',
    Otp_Required: 'Otp_Required',
  };

  graphQLErrors.forEach((graphQLError) => {
    const { category, code } = graphQLError.extensions;
    const { message } = graphQLError;
    if (code === codes['validation-failed'] || category === 'graphql') {
      graphDocumentError = new GraphDocumentError(message);
    } else if (code === codes.unexpected) {
      unexpected = new UnexpectedError(message);
    } else if (code === codes['access-denied']) {
      notAllowed = new NotAllowedError('Permission denied.');
    } else if (category === categories.internal) {
      serverError = new ServerError(message);
    } else if (code === 401 || category === categories.unauthorized) {
      unauthorized = new UnauthorizedError(message);
    } else if (category === categories.invalid_credentials) {
      invalidCredentials = new InvalidCredentialsError(message);
    } else if (category === categories.not_found) {
      notFound = new NotFoundError(message);
    } else {
      const cate = category as string | undefined;
      otherError = new OtherError(message, cate ?? '');
    }
  });

  if (serverError) return serverError;
  if (unexpected) return unexpected;
  if (notAllowed) return notAllowed;
  if (notFound) return notFound;
  if (graphDocumentError) return graphDocumentError;
  if (unauthorized) return unauthorized;
  if (invalidCredentials) return invalidCredentials;
  if (otherError) return otherError;
  if (invalidInput) return invalidInput;

  return new UnexpectedError('Unexpected Graphql Error');
};

const convertNetworkError = (
  networkError: GraphqlServerError | ServerParseError | Error,
): ErrorType => {
  if (isGraphqlServerError(networkError)) {
    if (networkError.statusCode === 401) {
      return new UnauthorizedError(JSON.stringify(networkError.result));
    }

    const serverError = new ServerError(
      `GraphqlServerError:\n 
      Message: ${networkError?.message}\n
      StatusCode: ${networkError.statusCode}\n
      Result: ${JSON.stringify(networkError.result)}
      `,
    );
    serverError.stack = networkError?.stack;
    serverError.cause = networkError?.cause;
    return serverError;
  }

  if (isServerParseError(networkError)) {
    const serverError = new ServerError(
      `ServerParseError:\n
      Message: ${networkError?.message}\n
      StatusCode: ${networkError.statusCode}
      Response: ${networkError.response}
      BodyText: ${networkError.bodyText}
      `,
    );
    serverError.stack = networkError?.stack;
    serverError.cause = networkError?.cause;
    return serverError;
  }

  const serverError = new NetworkError(
    `Graphql NetworkError:\n
     Message: ${networkError?.message}\n
      `,
  );
  serverError.stack = networkError?.stack;
  serverError.cause = networkError?.cause;
  return serverError;
};

const errorLink = onError(({ graphQLErrors, networkError, operation }) => {
  let documentSource: string;

  try {
    // @ts-ignore
    const arr = Array.from(
      operation.getContext().cache.typenameDocumentCache.values(),
    );
    // @ts-ignore
    documentSource = arr[arr.length - 1].loc.source.body;
  } catch (e) {
    documentSource = '';
  }

  let error: ErrorType | undefined;
  if (graphQLErrors) {
    error = convertGraphQlError(graphQLErrors);
  }

  if (networkError) {
    error = convertNetworkError(networkError);
  }

  if (!error) return;

  if (error instanceof UnauthorizedError) {
    // return forward(operation);
    // if (!refreshPromise) {
    //   refreshPromise = AuthRepository.refreshToken();
    // }
    // // eslint-disable-next-line consistent-return
    // return fromPromise(
    //   refreshPromise
    //     // eslint-disable-next-line consistent-return
    //     .then((result) => {
    //       if (result.data) {
    //         LocalStorage.setToken(result.data.refresh_token.token);
    //         LocalStorage.setRefreshToken(
    //           result.data.refresh_token.refresh_token,
    //         );
    //       } else {
    //         LocalStorage.clearUserData();
    //         window.location.reload();
    //       }
    //     })
    //     .catch((e) => {
    //       throw toErrorType(e);
    //     })
    //     .finally(() => {
    //       refreshPromise = null;
    //     }),
    // ).flatMap(() => {
    //   const oldHeaders = operation.getContext().headers;
    //   operation.setContext({
    //     headers: {
    //       ...oldHeaders,
    //       authorization: `Bearer ${LocalStorage.getToken()}`,
    //     },
    //   });
    //   return forward(operation);
    // });
  }
});

export default errorLink;
