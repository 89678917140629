/* eslint-disable @typescript-eslint/no-shadow */
import React, {
  createContext,
  forwardRef,
  useCallback,
  useContext,
  useState,
} from 'react';

import * as AccordionPrimitive from '@radix-ui/react-accordion';
import { cn } from '@udecode/cn';
import { FileText, FolderIcon, FolderOpenIcon } from 'lucide-react';

import { Label } from '../Label';
import { ScrollArea } from '../ScrollArea';

type TreeViewElement = {
  id: string;
  name: string;
  parentId?: string;
  children?: TreeViewElement[];
};

type TreeContextProps = {
  expendedItems: string[] | undefined;
  indicator: boolean;
  handleExpand: (id: string) => void;
  setExpendedItems?: React.Dispatch<React.SetStateAction<string[] | undefined>>;
  onSelectItem: (id: string) => void;
  openIcon?: React.ReactNode;
  closeIcon?: React.ReactNode;
  direction: 'rtl' | 'ltr';
};

const TreeContext = createContext<TreeContextProps | null>(null);

const useTree = () => {
  const context = useContext(TreeContext);
  if (!context) {
    throw new Error('useTree must be used within a TreeProvider');
  }
  return context;
};

interface TreeViewComponentProps extends React.HTMLAttributes<HTMLDivElement> {}

type Direction = 'rtl' | 'ltr' | undefined;

type TreeViewProps = {
  indicator?: boolean;
  elements?: TreeViewElement[];
  initialExpendedItems?: string[];
  openIcon?: React.ReactNode;
  closeIcon?: React.ReactNode;
  onSelectItem: (id: string) => void;
} & TreeViewComponentProps;

/**
 * Tree View Docs: {@link: https://shadcn-extension.vercel.app/docs/tree-view}
 */

const Tree = forwardRef<HTMLDivElement, TreeViewProps>(
  (
    {
      className,
      initialExpendedItems,
      children,
      indicator = true,
      openIcon,
      closeIcon,
      onSelectItem,
      dir,
      ...props
    },
    ref,
  ) => {
    const [expendedItems, setExpendedItems] = useState<string[] | undefined>(
      initialExpendedItems,
    );

    const handleExpand = useCallback((id: string) => {
      setExpendedItems((prev) => {
        if (prev?.includes(id)) {
          return prev.filter((item) => item !== id);
        }
        return [...(prev ?? []), id];
      });
    }, []);

    const direction = dir === 'rtl' ? 'rtl' : 'ltr';

    return (
      <TreeContext.Provider
        value={{
          expendedItems,
          handleExpand,
          setExpendedItems,
          indicator,
          openIcon,
          closeIcon,
          direction,
          onSelectItem,
        }}
      >
        <div className={cn('size-full', className)}>
          <ScrollArea
            ref={ref}
            className="relative h-full px-2"
            dir={dir as Direction}
          >
            <AccordionPrimitive.Root
              {...props}
              type="multiple"
              defaultValue={expendedItems}
              value={expendedItems}
              className="flex flex-col gap-1"
              onValueChange={(value) =>
                setExpendedItems?.((prev) => {
                  return value[0]
                    ? [...(prev ?? []), value[0]]
                    : [...(prev ?? [])];
                })
              }
              dir={dir as Direction}
            >
              {children}
            </AccordionPrimitive.Root>
          </ScrollArea>
        </div>
      </TreeContext.Provider>
    );
  },
);

Tree.displayName = 'Tree';

const TreeIndicator = forwardRef<
  HTMLDivElement,
  React.HTMLAttributes<HTMLDivElement>
>(({ className, ...props }, ref) => {
  const { direction } = useTree();

  return (
    <div
      dir={direction}
      ref={ref}
      className={cn(
        'absolute left-1.5 h-full w-px rounded-md bg-muted py-3 duration-300 ease-in-out hover:bg-slate-300 rtl:right-1.5',
        className,
      )}
      {...props}
    />
  );
});

TreeIndicator.displayName = 'TreeIndicator';

interface FolderComponentProps
  extends React.ComponentPropsWithoutRef<typeof AccordionPrimitive.Item> {}

type FolderProps = {
  expendedItems?: string[];
  element: string;
  isSelectable?: boolean;
  isSelect?: boolean;
} & FolderComponentProps;

const Folder = forwardRef<
  HTMLDivElement,
  FolderProps & React.HTMLAttributes<HTMLDivElement>
>(({ className, element, value, children, ...props }, ref) => {
  const {
    direction,
    handleExpand,
    expendedItems,
    indicator,
    setExpendedItems,
    openIcon,
    closeIcon,
    onSelectItem,
  } = useTree();

  return (
    <AccordionPrimitive.Item
      {...props}
      value={value}
      className="relative h-full overflow-hidden "
    >
      <div className="flex cursor-pointer items-center gap-2 rounded-md text-sm">
        <AccordionPrimitive.Trigger
          className={cn(
            `flex  h-7 cursor-pointer items-center gap-2 rounded-md text-sm`,
            className,
          )}
          onClick={() => handleExpand(value)}
        >
          {expendedItems?.includes(value)
            ? openIcon ?? <FolderOpenIcon className="h-5 w-5" />
            : closeIcon ?? <FolderIcon className="h-5 w-5" />}
        </AccordionPrimitive.Trigger>
        <Label
          className="flex h-7 cursor-pointer items-center gap-2 rounded-md px-2 text-sm duration-200 ease-in-out hover:bg-muted"
          onClick={() => onSelectItem(value)}
        >
          {element}
        </Label>
      </div>

      <AccordionPrimitive.Content className="relative h-full overflow-hidden text-sm data-[state=closed]:animate-accordion-up data-[state=open]:animate-accordion-down">
        {element && indicator && <TreeIndicator aria-hidden="true" />}
        <AccordionPrimitive.Root
          dir={direction}
          type="multiple"
          className="ml-5 flex flex-col gap-1 py-1 rtl:mr-5 "
          defaultValue={expendedItems}
          value={expendedItems}
          onValueChange={(value) => {
            setExpendedItems?.((prev) => {
              return value[0] ? [...(prev ?? []), value[0]] : [...(prev ?? [])];
            });
          }}
        >
          {children}
        </AccordionPrimitive.Root>
      </AccordionPrimitive.Content>
    </AccordionPrimitive.Item>
  );
});

Folder.displayName = 'Folder';

const File = forwardRef<
  HTMLButtonElement,
  {
    value: string;
    isSelect?: boolean;
    fileIcon?: React.ReactNode;
  } & React.HTMLAttributes<HTMLDivElement>
>(({ value, className, fileIcon, children, ...props }) => {
  const { onSelectItem } = useTree();

  return (
    <div
      {...props}
      aria-label="File"
      className={cn(
        'flex cursor-pointer items-center gap-2 rounded-md text-sm duration-200 ease-in-out',
        className,
      )}
      onClick={() => onSelectItem?.(value)}
    >
      {fileIcon ?? <FileText className="h-5 w-5" />}
      {children}
    </div>
  );
});

File.displayName = 'File';

export { Tree, Folder, File, type TreeViewElement };
