import { Button } from '@/components/Button';
import { Label } from '@/components/Label';
import { LevelHighIcon } from '@/icons/core/issue';

import { FilterButton } from './FilterButton';
import { FilterType } from './FilterType';
import { IConditionFilter } from '../../constant/constant';

const FilterPriorities = (props: {
  defaultValue: string[];
  selectedCondition: IConditionFilter;
  onChange: (value: IConditionFilter) => void;
  onSelectedPriorities: (value: string[]) => void;
}) => {
  return (
    <Button variant={'secondary'} size={'sm'} asChild className="rounded-none">
      <div className="flex h-[32px] flex-row items-center gap-0.5">
        <div className="flex flex-row gap-1">
          <LevelHighIcon width={18} height={18} />
          <Label>Priority</Label>
        </div>
        <FilterType
          onSelected={props.onChange}
          selected={props.selectedCondition}
        >
          <Button
            variant={'secondary'}
            size={'sm'}
            asChild
            className="cursor-pointer rounded-none "
          >
            <div className="flex flex-row gap-1">
              <Label>{props.selectedCondition.name}</Label>
            </div>
          </Button>
        </FilterType>
        <FilterButton
          defaultValue={'priority'}
          onSelectedPriorities={props.onSelectedPriorities}
          selectedPriorities={props.defaultValue}
        >
          <Button
            variant={'secondary'}
            size={'sm'}
            asChild
            className="cursor-pointer rounded-none "
          >
            <div className="flex flex-row">
              <Label>{props.defaultValue.length} priorities</Label>
            </div>
          </Button>
        </FilterButton>
      </div>
    </Button>
  );
};
export default FilterPriorities;
