import { Label } from '@/components/Label';

import LineSetting from './LineSetting';

const HeaderSetting = (props: {
  title: string;
  subtitle: string;
  children?: React.ReactNode;
}) => {
  return (
    <>
      <div className="flex flex-col gap-2">
        <Label className="text-2xl font-semibold">{props.title}</Label>
        <Label className="opacity-50">{props.subtitle}</Label>
      </div>
      <LineSetting />
      <div className="">{props.children}</div>
    </>
  );
};
export default HeaderSetting;
