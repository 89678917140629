import { useContext, useEffect, useState } from 'react';

import { useParams } from 'react-router-dom';

import useIssuesFilters from '@/hooks/useIssuesFilters';
import WorkspaceContext from '@/providers/workspace/WorkspaceContext';

import useTeam from '../useTeam';

const useTeamIssues = () => {
  const params = useParams();
  const { getIssuesByTeam, issues: rootIssues } = useContext(WorkspaceContext);

  const { team } = useTeam();
  const [initial, setInitial] = useState<boolean>(false);

  const {
    issues,
    selectedStatuses,
    selectedConditionStatus,
    selectedPriorities,
    selectedConditionPriority,
    selectedLabels,
    selectedConditionLabel,
    selectedAssignees,
    selectedConditionAssignee,
    event: {
      setIssues,
      setSelectedStatuses,
      setSelectedConditionStatus,
      setSelectedPriorities,
      setSelectedConditionPriority,
      setSelectedLabels,
      setSelectedConditionLabel,
      setSelectedAssignees,
      setSelectedConditionAssignee,
    },
  } = useIssuesFilters();

  useEffect(() => {
    if (params.team && getIssuesByTeam && team) {
      const getIssues = getIssuesByTeam({ team_identifier: params.team });
      setIssues(getIssues);
      if (!initial) setTimeout(() => setInitial(true), 500);
    }
  }, [params.team, rootIssues, team]);

  return {
    initial,
    issues,
    workflows: team?.workflows ?? [],
    labels: team?.labels ?? [],
    members: team?.members ?? [],
    selectedStatuses,
    selectedConditionStatus,
    selectedPriorities,
    selectedConditionPriority,
    selectedLabels,
    selectedConditionLabel,
    selectedAssignees,
    selectedConditionAssignee,
    event: {
      setSelectedStatuses,
      setSelectedConditionStatus,
      setSelectedPriorities,
      setSelectedConditionPriority,
      setSelectedLabels,
      setSelectedConditionLabel,
      setSelectedAssignees,
      setSelectedConditionAssignee,
    },
  };
};

export default useTeamIssues;
