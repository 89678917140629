import React from 'react';

import { RouteObject } from 'react-router-dom';

import SettingAccountPreferencesPage from '../../pages/settings/account/preferences';
import SettingAccountProfilePage from '../../pages/settings/account/profile';

const AuthSettingsProfileRoute: RouteObject[] = [
  {
    path: ':workspace/settings/account/profile',
    element: <SettingAccountProfilePage />,
  },
  {
    path: ':workspace/settings/account/preferences',
    element: <SettingAccountPreferencesPage />,
  },
];

export default AuthSettingsProfileRoute;
