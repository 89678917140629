import { useContext, useEffect } from 'react';

import { ThemeType } from '@/types/gql/generated';

import ThemeContext from './ThemeContext';
import AuthenticatedUserContext from '../auth/AuthenticatedUserContext';

const ThemeProvider = (props: { children?: React.ReactNode }) => {
  const { user } = useContext(AuthenticatedUserContext);
  const onChangeTheme = (theme: ThemeType) => {
    const root = window.document.documentElement;
    root.classList.remove(ThemeType.Light, ThemeType.Dark);
    if (theme === ThemeType.System) {
      const systemTheme = window.matchMedia('(prefers-color-scheme: dark)')
        .matches
        ? ThemeType.Dark
        : ThemeType.Light;

      root.classList.add(systemTheme);
      return;
    }
    root.classList.add(theme);
  };
  useEffect(() => {
    onChangeTheme(user?.theme ?? ThemeType.Light);
  }, [user?.theme]);

  return (
    <ThemeContext.Provider
      value={{
        theme: user?.theme ?? ThemeType.Light,
        onChange: (theme: ThemeType) => onChangeTheme(theme),
      }}
    >
      {props.children}
    </ThemeContext.Provider>
  );
};

export default ThemeProvider;
