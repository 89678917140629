import { useContext } from 'react';

import WorkspaceContext from '@/providers/workspace/WorkspaceContext';

import InviteLink from './components/invite-link/InviteLink';
import InviteMember from './components/invite-member/InviteMember';
import HeaderSetting from '../components/HeaderSetting';
import LineSetting from '../components/LineSetting';

const SettingMembersPage = () => {
  const { selected, memberWorkspaces } = useContext(WorkspaceContext);

  return (
    <div>
      <HeaderSetting
        title={'Members'}
        subtitle={'Manage who has access to this workspace'}
      ></HeaderSetting>

      {selected && (
        <>
          <InviteLink workspace={selected} />
          <LineSetting />
          <InviteMember
            workspace={selected}
            memberWorkspaces={memberWorkspaces}
          />
        </>
      )}
    </div>
  );
};

export default SettingMembersPage;
