import { useEffect, useState } from 'react';

import { yupResolver } from '@hookform/resolvers/yup';
import { useForm } from 'react-hook-form';
import * as yup from 'yup';

import { Button } from '@/components/Button';
import { Checkbox } from '@/components/Checkbox';
import { Form, FormField } from '@/components/Form';
import { Label } from '@/components/Label';
import { Popover, PopoverContent, PopoverTrigger } from '@/components/Popover';
import { ScrollArea } from '@/components/ScrollArea';
import { UserAvatar } from '@/components/UserAvatar';
import {
  BacklogIcon,
  LabelIcon,
  LevelHighIcon,
  UserAssignIcon,
} from '@/icons/core/issue';
import {
  BasePriorities,
  getNameByPriority,
} from '@/services/issue/issue-utils';
import {
  WorkflowFragment,
  LabelFragment,
  UserFragment,
} from '@/types/gql/generated';
import { mayBeMatch } from '@/utils/custom-matcher';

import { MemoizedRenderPriorityIcon } from '../Properties/PriorityButton';

type IFilter = 'status' | 'priority' | 'labels' | 'assignee';

export const schemaCheckStatusList = yup.object().shape({
  status: yup.array().of(yup.string().required()).required(),
});
export const schemaCheckPriorityList = yup.object().shape({
  priority: yup.array().of(yup.string().required()).required(),
});
export const schemaCheckLabelList = yup.object().shape({
  label: yup.array().of(yup.string().required()).required(),
});
export const schemaCheckAssigneeList = yup.object().shape({
  assignee: yup.array().of(yup.string().required()).required(),
});
const StatusList = (props: {
  workflows: WorkflowFragment[];
  selectedStatuses: string[];
  onSelected: (value: string[]) => void;
}) => {
  const form = useForm({
    resolver: yupResolver(schemaCheckStatusList),
    defaultValues: {
      status: props.selectedStatuses,
    },
  });

  useEffect(() => {
    props.onSelected(form.getValues('status'));
  }, [form.watch('status')]);

  return (
    <div className="flex w-full flex-col">
      <Form {...form}>
        <form>
          {props.workflows.map((item) => (
            <div key={item.id} className="flex flex-row items-center">
              <Button type={'button'} variant={'ghost'} className="h-10 w-full">
                <div className="flex w-full flex-row items-center gap-2">
                  <FormField
                    control={form.control}
                    name="status"
                    render={({ field }) => (
                      <Checkbox
                        checked={field.value?.includes(item.id)}
                        onCheckedChange={(checked) => {
                          return checked
                            ? field.onChange([...field.value, item.id])
                            : field.onChange(
                                field.value?.filter(
                                  (value) => value !== item.id,
                                ),
                              );
                        }}
                      />
                    )}
                  />

                  <Label>{item.name}</Label>
                </div>
              </Button>
            </div>
          ))}
        </form>
      </Form>
    </div>
  );
};

const LabelList = (props: {
  labels: LabelFragment[];
  selectedLabels: string[];
  onSelected: (value: string[]) => void;
}) => {
  const form = useForm({
    resolver: yupResolver(schemaCheckLabelList),
    defaultValues: {
      label: props.selectedLabels,
    },
  });

  useEffect(() => {
    props.onSelected(form.getValues('label'));
  }, [form.watch('label')]);

  return (
    <div className="flex w-full flex-col">
      <Form {...form}>
        <form>
          {props.labels.map((item, index) => (
            <div key={item.id} className="flex flex-row items-center">
              <Button type={'button'} variant={'ghost'} className="h-10 w-full">
                <div className="flex w-full flex-row items-center gap-2">
                  <FormField
                    control={form.control}
                    name="label"
                    render={({ field }) => (
                      <Checkbox
                        checked={field.value?.includes(item.id)}
                        onCheckedChange={(checked) => {
                          return checked
                            ? field.onChange([...field.value, item.id])
                            : field.onChange(
                                field.value?.filter(
                                  (value) => value !== item.id,
                                ),
                              );
                        }}
                      />
                    )}
                  />
                  <span
                    key={index}
                    className="inline-block h-[12px] w-[12px] rounded-full"
                    style={{
                      backgroundColor: item.color,
                    }}
                  ></span>
                  <Label>{item.name}</Label>
                </div>
              </Button>
            </div>
          ))}
        </form>
      </Form>
    </div>
  );
};

const PriorityList = (props: {
  selectedPriorities: string[];
  onSelected: (value: string[]) => void;
}) => {
  const form = useForm({
    resolver: yupResolver(schemaCheckPriorityList),
    defaultValues: {
      priority: props.selectedPriorities,
    },
  });

  useEffect(() => {
    props.onSelected(form.getValues('priority'));
  }, [form.watch('priority')]);

  return (
    <div className="flex w-full flex-col">
      <Form {...form}>
        <form>
          {BasePriorities.map((item) => {
            return (
              <div key={item.type} className="flex flex-row items-center">
                <Button
                  type={'button'}
                  variant={'ghost'}
                  className="h-10 w-full"
                >
                  <div className="flex w-full flex-row items-center justify-start gap-2">
                    <FormField
                      control={form.control}
                      name="priority"
                      render={({ field }) => (
                        <Checkbox
                          checked={field.value?.includes(item.type)}
                          onCheckedChange={(checked) => {
                            return checked
                              ? field.onChange([...field.value, item.type])
                              : field.onChange(
                                  field.value?.filter(
                                    (value) => value !== item.type,
                                  ),
                                );
                          }}
                        />
                      )}
                    />
                    <div className="flex w-[16px] flex-none items-center">
                      {<MemoizedRenderPriorityIcon type={item.type} />}
                    </div>
                    <div className="grow text-left">
                      <Label>{getNameByPriority(item.type)}</Label>
                    </div>
                  </div>
                </Button>
              </div>
            );
          })}
        </form>
      </Form>
    </div>
  );
};

const MemberList = (props: {
  members: UserFragment[];
  selectedAssignees: string[];
  onSelected: (value: string[]) => void;
}) => {
  const form = useForm({
    resolver: yupResolver(schemaCheckAssigneeList),
    defaultValues: {
      assignee: props.selectedAssignees,
    },
  });

  useEffect(() => {
    props.onSelected(form.getValues('assignee'));
  }, [form.watch('assignee')]);

  return (
    <div className="flex w-full flex-col">
      <Form {...form}>
        <form>
          {props.members.map((item) => (
            <div key={item.id} className="flex flex-row items-center">
              <Button type={'button'} variant={'ghost'} className="h-10 w-full">
                <div className="flex w-full flex-row items-center gap-2">
                  <FormField
                    control={form.control}
                    name="assignee"
                    render={({ field }) => (
                      <Checkbox
                        checked={field.value?.includes(item.id)}
                        onCheckedChange={(checked) => {
                          return checked
                            ? field.onChange([...field.value, item.id])
                            : field.onChange(
                                field.value?.filter(
                                  (value) => value !== item.id,
                                ),
                              );
                        }}
                      />
                    )}
                  />
                  <UserAvatar
                    size="sm"
                    url={item.image}
                    fallback={item.username}
                  />
                  <div className="grow text-left">
                    <Label>{item.username}</Label>
                  </div>
                </div>
              </Button>
            </div>
          ))}
        </form>
      </Form>
    </div>
  );
};

const FilterButton = (props: {
  defaultValue?: IFilter | undefined;
  workflows?: WorkflowFragment[];
  labels?: LabelFragment[];
  members?: UserFragment[];

  children: React.ReactNode;

  selectedStatuses?: string[];
  isDisableStatuses?: boolean;
  onSelectedStatus?: (value: string[]) => void;

  selectedPriorities?: string[];
  isDisablePriorities?: boolean;
  onSelectedPriorities?: (value: string[]) => void;

  selectedLabels?: string[];
  isDisableLabels?: boolean;
  onSelectedLabels?: (value: string[]) => void;

  selectedAssignees?: string[];
  isDisableAssignees?: boolean;
  onSelectedAssignees?: (value: string[]) => void;
}) => {
  const [filter, setFilter] = useState<IFilter | undefined>(
    props.defaultValue ?? undefined,
  );
  const [isOpen, setIsOpen] = useState<boolean>(false);

  const items = [
    {
      name: 'Status',
      icon: <BacklogIcon width={22} height={22} />,
      value: 'status' as IFilter,
      disabled: props.isDisableStatuses,
    },
    {
      name: 'Priority',
      icon: <LevelHighIcon width={20} height={20} />,
      value: 'priority' as IFilter,
      disabled: props.isDisablePriorities,
    },
    {
      name: 'Labels',
      icon: <LabelIcon width={20} height={20} />,
      value: 'labels' as IFilter,
      disabled: props.isDisableLabels,
    },
    {
      name: 'Assignee',
      icon: <UserAssignIcon width={20} height={20} />,
      value: 'assignee' as IFilter,
      disabled: props.isDisableAssignees,
    },
  ];

  const onClose = () => {
    setFilter(undefined);
  };
  useEffect(() => {
    setFilter(props.defaultValue);
  }, [isOpen]);

  return (
    <Popover
      modal={true}
      open={isOpen}
      onOpenChange={(value) => {
        setIsOpen(value);
        if (!value) {
          onClose();
        }
      }}
    >
      <PopoverTrigger className="w-full">{props.children}</PopoverTrigger>
      <PopoverContent className="p-[5px]">
        <ScrollArea className="h-full w-full">
          {!filter && (
            <div className="flex w-full flex-col items-start justify-center">
              {items
                .filter((item) => !item.disabled)
                .map((item) => (
                  <Button
                    key={item.value}
                    onClick={() => setFilter(item.value)}
                    type={'button'}
                    variant={'ghost'}
                    className="h-10 w-full"
                  >
                    <div className="flex w-full flex-row items-center justify-start gap-2">
                      <div className="w-[20px]">{item.icon}</div>
                      <Label>{item.name}</Label>
                    </div>
                  </Button>
                ))}
            </div>
          )}
          {filter &&
            mayBeMatch([filter])({
              status: () => (
                <StatusList
                  workflows={props.workflows ?? []}
                  selectedStatuses={props.selectedStatuses!}
                  onSelected={props.onSelectedStatus!}
                />
              ),
              priority: () => (
                <PriorityList
                  selectedPriorities={props.selectedPriorities!}
                  onSelected={props.onSelectedPriorities!}
                />
              ),
              labels: () => (
                <LabelList
                  labels={props.labels ?? []}
                  selectedLabels={props.selectedLabels!}
                  onSelected={props.onSelectedLabels!}
                />
              ),
              assignee: () => (
                <MemberList
                  members={props.members ?? []}
                  selectedAssignees={props.selectedAssignees!}
                  onSelected={props.onSelectedAssignees!}
                />
              ),
              orElse: () => <></>,
            })}
        </ScrollArea>
      </PopoverContent>
    </Popover>
  );
};

export { FilterButton };
