import { Label } from '@/components/Label';

export const LabelError = (props?: { children?: React.ReactNode }) => {
  return props?.children ? (
    <div className="block w-full text-red-600">
      <Label className="text-sm">* {props?.children}</Label>
    </div>
  ) : (
    <></>
  );
};
