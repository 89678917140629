import { useState } from 'react';

const useProvider = () => {
  const [isShowCreateIssue, setIsShowCreateIssue] = useState<boolean>(false);
  const showCreatePopup = (value: boolean) => {
    setIsShowCreateIssue(value);
  };
  return {
    isShowCreateIssue,
    event: {
      showCreatePopup,
    },
  };
};

export default useProvider;
