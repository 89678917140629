import {
  AlertDialogFooter,
  AlertDialog,
  AlertDialogDescription,
  AlertDialogTitle,
  AlertDialogHeader,
  AlertDialogContent,
} from '@/components/AlertDialog';
import { Button } from '@/components/Button';
import { useErrorHandler, useSuccessHandler } from '@/hooks/useToasterHandler';
import { MemoizedRenderProject } from '@/renders/RenderProject';
import { useDeleteProjectMutation } from '@/types/gql/generated';
import CustomDateTime from '@/utils/format-datetime';

import ProjectDeleteContext from './ProjectDeleteContext';
import useProjectDeleteProvider from './useProjectDeleteProvider';

const ProjectDeleteProvider = ({
  children,
}: {
  children?: React.ReactNode;
}) => {
  const { showSuccessToast } = useSuccessHandler();
  const { showErrorToast } = useErrorHandler();
  const { isOpen, projectId, event } = useProjectDeleteProvider();

  const deleteProject = useDeleteProjectMutation({
    onMutate: () => {},
    onError: (res) => {
      showErrorToast({ error: res });
    },
    onSuccess: () => {
      event.setData({ isOpen: false });
      showSuccessToast({
        title: 'Project has been deleted',
        description: `${CustomDateTime.convertStringToCommon(
          new Date().toISOString(),
        )}`,
      });
    },
    onSettled: () => {},
  });

  return (
    <ProjectDeleteContext.Provider
      value={{
        isOpen: isOpen,
        projectId: projectId,
        showModal: (value) => event.setData(value),
      }}
    >
      {children}

      <AlertDialog
        open={isOpen}
        onOpenChange={(value) => event.setData({ isOpen: value })}
      >
        <AlertDialogContent>
          <AlertDialogHeader>
            <MemoizedRenderProject
              id={projectId}
              renderItem={(project) =>
                project && (
                  <AlertDialogTitle>
                    Are you sure you want to delete "{project.title}" ?
                  </AlertDialogTitle>
                )
              }
            />

            <AlertDialogDescription>
              Deleted project are available in the "Recently deleted" view for
              30 days, before they are permanently deleted.
            </AlertDialogDescription>
          </AlertDialogHeader>
          <AlertDialogFooter>
            <Button
              disabled={deleteProject.isPending}
              variant={'secondary'}
              onClick={() => event.setData({ isOpen: false })}
            >
              Cancel
            </Button>
            <Button
              disabled={deleteProject.isPending}
              variant={'destructive'}
              onClick={() =>
                projectId &&
                deleteProject.mutate({
                  id: projectId,
                })
              }
            >
              Delete
            </Button>
          </AlertDialogFooter>
        </AlertDialogContent>
      </AlertDialog>
    </ProjectDeleteContext.Provider>
  );
};
export default ProjectDeleteProvider;
