import { useState } from 'react';

import { useCopyToClipboard } from 'usehooks-ts';

import { AnimationFadeIn } from '@/components/Animations';
import { Button } from '@/components/Button';
import { Card } from '@/components/Card';
import { Label } from '@/components/Label';
import { Switch } from '@/components/Switch';
import { useErrorHandler, useSuccessHandler } from '@/hooks/useToasterHandler';
import { SpinnerDotIcon } from '@/icons/core';
import { ArrowsClockwiseThinIcon } from '@/icons/index';
import { DOMAIN_URL } from '@/services/core/constant';
import {
  useGenerateInviteLinkMutation,
  useUpdateWorkspaceMutation,
  WorkspaceFragment,
} from '@/types/gql/generated';

const InviteLink = (props: { workspace: WorkspaceFragment }) => {
  const { showErrorToast } = useErrorHandler();
  const { showSuccessToast } = useSuccessHandler();
  const [copiedText, copy] = useCopyToClipboard();

  const [inviteLink, setInviteLink] = useState<string>(
    props.workspace.invite_link ?? '',
  );
  const [disabledInvite, setDisabledInvite] = useState<boolean>(
    !props.workspace.disabled_invite_link,
  );

  const generateInviteLink = useGenerateInviteLinkMutation({
    onMutate: () => {},
    onError: (res) => {
      showErrorToast({ error: res });
    },
    onSuccess: (res) => {
      setInviteLink(res.generateInviteLink);
    },
    onSettled: () => {},
  });

  const disabledInviteLink = useUpdateWorkspaceMutation({
    onMutate: () => {},
    onError: (res) => {
      showErrorToast({ error: res });
    },
    onSuccess: (res) => {
      setDisabledInvite(!res.updateWorkspace.disabled_invite_link);
    },
    onSettled: () => {},
  });

  const handleCopy = (text: string) => () => {
    copy(text);
    showSuccessToast({
      title: 'Copied',
      description: `You have copied invite link ${copiedText}`,
    });
  };

  return (
    <Card className="p-4 lg:p-8">
      <div>
        <div className="flex flex-col gap-6">
          <div className="flex flex-row items-start">
            <div className="flex grow flex-col gap-1">
              <Label className="text-lg font-semibold">Invite link</Label>
              <Label className="opacity-50">
                Share this link with others you'd like to join your workspace.
              </Label>
            </div>
            <div>
              <Switch
                defaultChecked={disabledInvite}
                onCheckedChange={(e) =>
                  disabledInviteLink.mutate({
                    updateInput: {
                      id: props.workspace.id,
                      disabled_invite_link: !e.valueOf(),
                    },
                  })
                }
              />
            </div>
          </div>

          {disabledInvite && (
            <AnimationFadeIn>
              <div className="flex w-full flex-col items-center gap-4 lg:flex-row">
                <div className="w-full grow">
                  <Card className="w-full">
                    <div className="flex h-[47px] w-full grow flex-row items-center gap-1">
                      <div className="grow">
                        <Label className="line-clamp-1">
                          {DOMAIN_URL}/join/
                          {inviteLink}
                        </Label>
                      </div>
                      <div className="flex w-[35px] flex-none items-center justify-center">
                        {generateInviteLink.isPending ? (
                          <SpinnerDotIcon width={18} height={18} />
                        ) : (
                          <Button
                            size={'sm'}
                            variant="ghost"
                            disabled={generateInviteLink.isPending}
                            onClick={() =>
                              generateInviteLink.mutate({
                                workspace_id: props.workspace.id,
                              })
                            }
                          >
                            <ArrowsClockwiseThinIcon width={18} height={18} />
                          </Button>
                        )}
                      </div>
                    </div>
                  </Card>
                </div>

                <Button
                  size={'sm'}
                  type="button"
                  variant={'secondary'}
                  className="h-[44px] w-full px-4 lg:w-auto"
                  onClick={handleCopy(`${DOMAIN_URL}/join/${inviteLink}`)}
                >
                  Copy
                </Button>
              </div>
            </AnimationFadeIn>
          )}
        </div>
      </div>
    </Card>
  );
};
export default InviteLink;
