import { Toaster } from '@/components/Sonner';

const ServicesWrapper = (props: { children?: React.ReactNode }) => {
  return (
    <>
      {props.children}
      <Toaster />
    </>
  );
};

export default ServicesWrapper;
