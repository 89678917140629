import React, { useContext } from 'react';

import { CommentFragment } from '@/types/gql/generated';

import IssueDetailContext from '../providers/IssueDetailContext';

const RenderComment = (props: {
  id: string | undefined;
  renderItem: (value: CommentFragment | undefined) => React.ReactNode;
}) => {
  if (!props.id) {
    return <>{props.renderItem(undefined)}</>;
  }
  const { comments } = useContext(IssueDetailContext);
  return <>{props.renderItem(comments.find((item) => item.id === props.id))}</>;
};
const MemoizedRenderComment = React.memo(RenderComment);
export { MemoizedRenderComment };
