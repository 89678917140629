import { useState } from 'react';

const useProvider = () => {
  const [isShowCreateDocument, setIsShowCreateDocument] =
    useState<boolean>(false);

  const showCreateDocumentPopup = (value: boolean) => {
    setIsShowCreateDocument(value);
  };
  return {
    isShowCreateDocument,
    event: {
      showCreateDocumentPopup,
    },
  };
};

export default useProvider;
