import React, { useContext } from 'react';

import WorkspaceContext from '@/providers/workspace/WorkspaceContext';
import { WorkflowFragment } from '@/types/gql/generated';

const RenderStatus = (props: {
  id: string | undefined;
  renderItem: (value: WorkflowFragment | undefined) => React.ReactNode;
}) => {
  if (!props.id) {
    return <>{props.renderItem(undefined)}</>;
  }
  const { workflows } = useContext(WorkspaceContext);
  return (
    <>{props.renderItem(workflows.find((item) => item.id === props.id))}</>
  );
};
const MemoizedRenderStatus = React.memo(RenderStatus);

export { MemoizedRenderStatus };
