import { Button } from '@/components/Button';
import { Label } from '@/components/Label';
import { BacklogIcon } from '@/icons/core/issue';
import { MemoizedRenderStatus } from '@/renders/RenderStatus';

import { FilterButton } from './FilterButton';
import { FilterType } from './FilterType';
import { IConditionFilter } from '../../constant/constant';
import { MemoizedRenderStatusIcon } from '../Properties/StatusButton';

const FilterStatus = (props: {
  defaultValue: string[];
  selectedCondition: IConditionFilter;
  onChange: (value: IConditionFilter) => void;
  onSelectedStatuses: (value: string[]) => void;
}) => {
  return (
    <Button variant={'secondary'} size={'sm'} asChild className="rounded-none">
      <div className="flex h-[32px] flex-row items-center gap-0.5">
        <div className="flex flex-row items-center gap-1">
          <BacklogIcon width={18} height={18} />
          <Label>Status</Label>
        </div>

        <FilterType
          onSelected={props.onChange}
          selected={props.selectedCondition}
        >
          <Button
            variant={'secondary'}
            size={'sm'}
            asChild
            className="cursor-pointer rounded-none"
          >
            <div className="flex flex-row items-center gap-1">
              <Label>{props.selectedCondition.name}</Label>
            </div>
          </Button>
        </FilterType>
        <FilterButton
          defaultValue={'status'}
          onSelectedStatus={props.onSelectedStatuses}
          selectedStatuses={props.defaultValue}
        >
          <Button
            variant={'secondary'}
            size={'sm'}
            asChild
            className="cursor-pointer rounded-none"
          >
            <div className="flex flex-row items-center gap-1">
              <div className="relative flex items-center">
                {props.defaultValue.map((item, index) => (
                  <MemoizedRenderStatus
                    key={item}
                    id={item}
                    renderItem={(value) => (
                      <div style={{ marginLeft: index === 0 ? 0 : -9 }}>
                        <MemoizedRenderStatusIcon
                          type={value?.type}
                          color={value?.color}
                          processing={value?.processing}
                        />
                      </div>
                    )}
                  />
                ))}
              </div>
              <Label>statuses</Label>
            </div>
          </Button>
        </FilterButton>
      </div>
    </Button>
  );
};
export default FilterStatus;
