/* eslint-disable @typescript-eslint/no-use-before-define */
/* eslint-disable import/no-extraneous-dependencies */
import React, { forwardRef, useRef } from 'react';

import { cn } from '@udecode/cn';

import { TreeViewElement, Tree, File, Folder } from './TreeViewAPI';
import { Label } from '../Label';

// TODO: Add the ability to add custom icons

interface TreeViewComponentProps extends React.HTMLAttributes<HTMLDivElement> {}

type TreeViewProps = {
  initialSelectedId?: string;
  elements: TreeViewElement[];
  indicator?: boolean;
  onSelectItem: (id: string) => void;
} & (
  | {
      initialExpendedItems?: string[];
    }
  | {
      initialExpendedItems?: undefined;
    }
) &
  TreeViewComponentProps;

/**
 * Tree View Docs: {@link: https://shadcn-extension.vercel.app/docs/tree-view}
 */

export const TreeView = ({
  elements,
  className,
  initialExpendedItems,
  indicator = false,
  onSelectItem,
}: TreeViewProps) => {
  const containerRef = useRef<HTMLDivElement>(null);

  return (
    <div
      ref={containerRef}
      className={cn(
        'relative w-full overflow-hidden rounded-md py-1',
        className,
      )}
    >
      <Tree
        initialExpendedItems={initialExpendedItems}
        elements={elements}
        className="h-full w-full overflow-y-auto"
        onSelectItem={onSelectItem}
      >
        {elements.map((element) => {
          return (
            <TreeItem
              aria-label="Root"
              key={element.id}
              elements={[element]}
              indicator={indicator}
            />
          );
        })}
      </Tree>
    </div>
  );
};

TreeView.displayName = 'TreeView';

export const TreeItem = forwardRef<
  HTMLUListElement,
  {
    elements: TreeViewElement[];
    onHandleClick?: (id: string) => void;
    indicator?: boolean;
  } & React.HTMLAttributes<HTMLUListElement>
>(({ elements, indicator, ...props }, ref) => {
  return elements.length > 0 ? (
    <ul ref={ref} className="w-full space-y-1 " {...props}>
      {elements.map((element) => (
        <li key={element.id} className="w-full">
          {element.children && element.children?.length > 0 ? (
            <Folder element={element.name} value={element.id}>
              <TreeItem
                key={element.id}
                aria-label={`folder ${element.name}`}
                elements={element.children}
                indicator={indicator}
              />
            </Folder>
          ) : (
            <File
              value={element.id}
              aria-label={`File ${element.name}`}
              key={element.id}
              className="h-7"
            >
              <Label className="cursor-pointer px-2 hover:bg-muted">
                {element?.name}
              </Label>
            </File>
          )}
        </li>
      ))}
    </ul>
  ) : (
    <></>
  );
});

TreeItem.displayName = 'TreeItem';
