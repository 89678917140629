import { Card } from '@/components/Card';
import { Label } from '@/components/Label';
import { YoutubeLogoThinIcon, CaretRightThinIcon } from '@/icons/index';

import HeaderSetting from '../components/HeaderSetting';

const SettingOverviewPage = () => {
  return (
    <div>
      <HeaderSetting
        title={'Workspace'}
        subtitle={'Manage your workspace settings'}
      ></HeaderSetting>
      <div className="grid w-full gap-4 lg:grid-cols-2">
        <Card className="cursor-pointer">
          <div className="flex flex-row items-center gap-4 p-[20px]">
            <div>
              <YoutubeLogoThinIcon width={30} height={30} />
            </div>
            <div className="flex grow flex-col gap-1">
              <Label>Start guide</Label>
              <Label className="opacity-50">Quick tips for beginners</Label>
            </div>
            <div>
              <CaretRightThinIcon width={20} height={20} />
            </div>
          </div>
        </Card>
        <Card className="cursor-pointer">
          <div className="flex flex-row items-center gap-4 p-[20px]">
            <div>
              <YoutubeLogoThinIcon width={30} height={30} />
            </div>
            <div className="flex grow flex-col gap-1">
              <Label>Start guide</Label>
              <Label className="opacity-50">Quick tips for beginners</Label>
            </div>
            <div>
              <CaretRightThinIcon width={20} height={20} />
            </div>
          </div>
        </Card>
      </div>
    </div>
  );
};

export default SettingOverviewPage;
