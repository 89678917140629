import { Separator } from '@radix-ui/themes';
import classNames from 'classnames';

import {
  Popover,
  PopoverClose,
  PopoverContent,
  PopoverTrigger,
} from '@/components/Popover';
import EmojiIcon, { IEmojiIcon, EmojiIconList } from '@/icons/emoji';
import { BaseColors } from '@/services/core/constant';

const IconPicker = (props: {
  children: React.ReactNode;
  color: string | undefined;
  icon: IEmojiIcon | undefined;
  onSelectedColor: (value: string) => void;
  onSelectedIcon: (value: IEmojiIcon) => void;
}) => {
  return (
    <Popover>
      <PopoverTrigger>{props.children}</PopoverTrigger>
      <PopoverContent className="w-[400px]">
        <div className="flex flex-col gap-4">
          <div className="flex flex-row items-center justify-between">
            {BaseColors.map((item) => (
              <span
                onClick={() => props.onSelectedColor(item)}
                key={item}
                style={{ backgroundColor: item }}
                className={classNames(
                  `inline-block h-[20px] w-[20px] cursor-pointer rounded-full`,
                  {
                    'h-[22px] w-[22px] border-[2px] border-solid border-red-400':
                      item === props.color,
                  },
                )}
              ></span>
            ))}
          </div>
          <Separator className="w-full opacity-50" orientation="horizontal" />
          <PopoverClose>
            <div className="grid grid-cols-8 gap-2">
              {EmojiIconList.map((item) => (
                <div
                  key={item}
                  className="cursor-pointer"
                  onClick={() => props.onSelectedIcon(item)}
                >
                  <EmojiIcon
                    tag={item}
                    attrs={{ width: 30, height: 30, color: props.color }}
                  />
                </div>
              ))}
            </div>
          </PopoverClose>
        </div>
      </PopoverContent>
    </Popover>
  );
};
export default IconPicker;
