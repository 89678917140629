import { useNavigate, useLocation } from 'react-router-dom';

import LoadingFullPage from '@/components/LoadingPage';
import LocalStorage from '@/utils/local-storage';

import AuthenticatedUserContext from './AuthenticatedUserContext';
import { useGetMeQuery } from '../../types/gql/generated';

const AuthenticatedWrapper = (props: { children?: React.ReactNode }) => {
  const navigate = useNavigate();
  const location = useLocation();

  const { data, isLoading, isSuccess, refetch } = useGetMeQuery();
  const goToLoginPage = () => {
    LocalStorage.removeToken();
    const redirect = `${location.pathname}${location.search}${location.hash}`;
    navigate(`/login?redirect=${encodeURIComponent(redirect)}`);
  };
  return (
    <AuthenticatedUserContext.Provider
      value={{
        user: data?.me,
        refetch: () => refetch(),
        logout: () => {
          goToLoginPage();
        },
      }}
    >
      {!isLoading && isSuccess && props.children}
      {isLoading && <LoadingFullPage />}
    </AuthenticatedUserContext.Provider>
  );
};

export default AuthenticatedWrapper;
