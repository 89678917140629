import { useContext, useState } from 'react';

import { Button } from '@/components/Button';
import { Input } from '@/components/Input';
import { Label } from '@/components/Label';
import {
  Popover,
  PopoverClose,
  PopoverContent,
  PopoverTrigger,
} from '@/components/Popover';
import { ScrollArea } from '@/components/ScrollArea';
import { Separator } from '@/components/Separator';
import EmojiIcon, { IEmojiIcon } from '@/icons/emoji';
import WorkspaceContext from '@/providers/workspace/WorkspaceContext';
import { TeamFragment } from '@/types/gql/generated';

import CheckedIcon from '../../../../icons/checked';

const TeamButton = (props: {
  selected?: string;
  onChange: (data: string) => void;
  renderItem: (value: TeamFragment | undefined) => React.ReactNode;
}) => {
  const { teams } = useContext(WorkspaceContext);
  const [filter, setFilter] = useState<string>('');
  const selectedTeam = teams.find((item) => item.id === props.selected);

  return (
    <Popover>
      <PopoverTrigger asChild>{props.renderItem(selectedTeam)}</PopoverTrigger>
      <PopoverContent className="p-[5px]">
        <div className="flex w-full items-center p-[8px]">
          <Input
            className="w-full"
            placeholder="Search ..."
            value={filter}
            onChange={(e) => setFilter(e.currentTarget.value)}
          ></Input>
        </div>
        <Separator className="w-full" orientation="horizontal" />
        <ScrollArea type="auto" style={{ maxHeight: 180 }}>
          <div className="flex w-full flex-col">
            {teams
              .filter((item) => item.name.includes(filter))
              .map((item) => (
                <PopoverClose key={item.id}>
                  <Button
                    type="button"
                    variant={'ghost'}
                    className="flex w-full flex-row gap-2 text-left"
                    onClick={() => props.onChange(item.id)}
                  >
                    <div>
                      <EmojiIcon
                        tag={item.icon as IEmojiIcon}
                        attrs={{
                          width: 20,
                          height: 20,
                          color: item.color,
                        }}
                      />
                    </div>
                    <div className="grow">
                      <Label>{item.name}</Label>
                    </div>
                    {props.selected && props.selected === item.id && (
                      <div className="w-[30px] flex-none text-center">
                        <CheckedIcon />
                      </div>
                    )}
                  </Button>
                </PopoverClose>
              ))}
          </div>
        </ScrollArea>
      </PopoverContent>
    </Popover>
  );
};

export default TeamButton;
