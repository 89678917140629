import { Button } from '@/components/Button';
import { Label } from '@/components/Label';
import { BacklogIcon } from '@/icons/core/issue';
import { MemoizedRenderLabels } from '@/renders/RenderLabel';

import { FilterButton } from './FilterButton';
import { FilterType } from './FilterType';
import { IConditionFilter } from '../../constant/constant';

const FilterLabels = (props: {
  defaultValue: string[];
  selectedCondition: IConditionFilter;
  onChange: (value: IConditionFilter) => void;
  onSelectedLabels: (value: string[]) => void;
}) => {
  return (
    <Button variant={'secondary'} size={'sm'} asChild className="rounded-none">
      <div className="flex h-[32px] flex-row items-center gap-0.5">
        <div className="flex flex-row gap-1">
          <BacklogIcon width={18} height={18} />
          <Label>Labels</Label>
        </div>
        <FilterType
          onSelected={props.onChange}
          selected={props.selectedCondition}
        >
          <Button
            variant={'secondary'}
            size={'sm'}
            asChild
            className="cursor-pointer rounded-none "
          >
            <div className="flex flex-row gap-1">
              <div>
                <Label>{props.selectedCondition.name}</Label>
              </div>
            </div>
          </Button>
        </FilterType>
        <FilterButton
          defaultValue={'labels'}
          onSelectedLabels={props.onSelectedLabels}
          selectedLabels={props.defaultValue}
        >
          <Button
            variant={'secondary'}
            size={'sm'}
            asChild
            className="cursor-pointer rounded-none "
          >
            <div className="flex flex-row items-center gap-1">
              <div className="relative flex items-center">
                <MemoizedRenderLabels
                  ids={props.defaultValue}
                  renderItem={(value) =>
                    value.map((item, index) => (
                      <span
                        key={item.id}
                        className="inline-block h-[16px] w-[16px] rounded-full"
                        style={{
                          marginLeft: index === 0 ? 0 : -8,
                          backgroundColor: item.color,
                        }}
                      ></span>
                    ))
                  }
                />
              </div>
              <div>
                <Label>{props.defaultValue.length} labels</Label>
              </div>
            </div>
          </Button>
        </FilterButton>
      </div>
    </Button>
  );
};
export default FilterLabels;
