import { PriorityType } from '@/types/gql/generated';
import { IPriority } from '@/types/issue/priority.interface';
import { mayBeMatch } from '@/utils/custom-matcher';

export const BasePriorities: IPriority[] = [
  {
    type: PriorityType.NoPriority,
  },
  {
    type: PriorityType.Urgent,
  },
  {
    type: PriorityType.High,
  },
  {
    type: PriorityType.Medium,
  },
  {
    type: PriorityType.Low,
  },
];

export const getNameByPriority = (
  type: PriorityType | null | undefined,
): string => {
  if (!type) {
    return '';
  }
  return mayBeMatch([type])({
    [PriorityType.High]: () => 'High',
    [PriorityType.Low]: () => 'Low',
    [PriorityType.Medium]: () => 'Medium',
    [PriorityType.NoPriority]: () => 'No priority',
    [PriorityType.Urgent]: () => 'Urgent',
    orElse: () => '',
  });
};
