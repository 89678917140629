import { CreateIssueComponent } from './CreateIssueComponent';

export const CreateSubIssueDialog = (props: {
  initTeamId?: string;
  initWorkflowId?: string;
  initMemberId?: string;
  initIssueId: string;
  onSuccess: () => void;
  onCancel: () => void;
}) => {
  return <CreateIssueComponent {...props} type={'component'} />;
};
