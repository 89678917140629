import React, { useContext } from 'react';

import uniq from 'lodash/uniq';

import IssueServicesContext from '@/providers/issue/IssueServicesContext';
import WorkspaceContext from '@/providers/workspace/WorkspaceContext';
import { IGroupingIssue } from '@/types/core/core';
import {
  IssueFragment,
  WorkflowFragment,
  TeamFragment,
} from '@/types/gql/generated';
import { mayBeMatch } from '@/utils/custom-matcher';

import { IssueFilterHeader, StatusFilterHeader } from '../GroupingHeader';
import IssueTableList from '../IssueTableList/IssueTableList';

const StatusIssuesGrouping = React.memo(
  (props: { issues: IssueFragment[]; team_id?: string }) => {
    const { workflows } = useContext(WorkspaceContext);
    const { showCreatePopup } = useContext(IssueServicesContext);

    const issueIds = props.issues
      .filter((item) => item.workflow_id != null)
      .map((item) => item.workflow_id!);

    const getWorkflows = workflows.filter((item) => issueIds.includes(item.id));

    const onShowCreate = (workflow: WorkflowFragment) => {
      showCreatePopup({
        initWorkflowId: workflow.id,
        isOpen: true,
        initTeamId: props.team_id,
      });
    };

    return (
      <>
        {getWorkflows.map((item) => {
          const getIssues = props.issues.filter(
            (issue) => issue.workflow_id === item.id,
          );
          if (getIssues.length === 0) return <></>;
          return (
            <div key={item.id} className="flex flex-col">
              <StatusFilterHeader
                workflow={item}
                onShowCreate={() => onShowCreate(item)}
              />
              <IssueTableList data={getIssues} type={'issues'} />
            </div>
          );
        })}
      </>
    );
  },
);

const ParentIssuesGrouping = React.memo(
  (props: { issues: IssueFragment[]; team_id?: string }) => {
    const parentIds = props.issues
      .filter((item) => item.issue_id != null)
      .map((item) => item.issue_id!);
    const onShowCreate = (parent?: IssueFragment) => {};
    return (
      <>
        {uniq(parentIds).map((item, index) => {
          const getIssue = props.issues.find((issue) => issue.id === item);
          const getIssues = props.issues.filter(
            (issue) => issue.issue_id === getIssue?.id,
          );
          if (getIssues.length === 0 || !getIssue) return <></>;
          return (
            <div key={index} className="flex flex-col">
              <IssueFilterHeader
                issue={getIssue}
                onShowCreate={() => onShowCreate(getIssue)}
              />
              <IssueTableList data={getIssues} type={'issues'} />
            </div>
          );
        })}

        <div className="flex flex-col">
          <IssueFilterHeader onShowCreate={() => onShowCreate()} />
          <IssueTableList
            data={props.issues.filter((item) => item.issue_id === null)}
            type={'issues'}
          />
        </div>
      </>
    );
  },
);

const NoGroupingIssuesGrouping = React.memo(
  (props: { issues: IssueFragment[]; team_id?: string }) => {
    return (
      <>
        <div className="flex flex-col">
          <IssueTableList data={props.issues} type={'issues'} />
        </div>
      </>
    );
  },
);

const IssuesGrouping = React.memo(
  (props: {
    grouping: IGroupingIssue;
    issues: IssueFragment[];
    team?: TeamFragment;
  }) => {
    return mayBeMatch([props.grouping])({
      status: () => (
        <StatusIssuesGrouping
          issues={props.issues}
          team_id={props.team?.id}
        ></StatusIssuesGrouping>
      ),
      parent: () => (
        <ParentIssuesGrouping
          issues={props.issues}
          team_id={props.team?.id}
        ></ParentIssuesGrouping>
      ),
      no_grouping: () => (
        <NoGroupingIssuesGrouping
          issues={props.issues}
          team_id={props.team?.id}
        ></NoGroupingIssuesGrouping>
      ),
      orElse: () => <></>,
    });
  },
);

export { StatusIssuesGrouping, ParentIssuesGrouping, IssuesGrouping };
