import { useContext } from 'react';

import DocumentServicesContext from './DocumentServicesContext';

export const useDocumentServices = () => {
  const { isShowCreateDocument, showCreateDocumentPopup } = useContext(
    DocumentServicesContext,
  );

  return {
    isShowCreateDocument,
    showCreateDocumentPopup,
  };
};

export default useDocumentServices;
