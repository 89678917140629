import { RouteObject } from 'react-router-dom';

import DocumentDetailPage from '@/pages/document';
import ProjectDetailPage from '@/pages/project';
import DocumentsPage from '@/pages/team/documents';
import ProjectsPage from '@/pages/team/projects';

import InboxPage from '../../pages/inbox/index';
import IssueDetailPage from '../../pages/issue/detail/index';
import IssuesActivityPage from '../../pages/issue/my-issue/activity';
import IssuesAssignedPage from '../../pages/issue/my-issue/assigned/index';
import IssuesCreatedPage from '../../pages/issue/my-issue/created/index';
import IssuesSubscribedPage from '../../pages/issue/my-issue/subscribed/index';
import SearchIssuePage from '../../pages/search';
import TeamActivesPage from '../../pages/team/actives/index';
import TeamBacklogPage from '../../pages/team/backlog/index';
import TeamIssuesPage from '../../pages/team/issues';
import TeamsPage from '../../pages/teams';

const AuthIssueRoute: RouteObject[] = [
  {
    path: ':workspace/my-issues/assigned',
    element: <IssuesAssignedPage />,
  },
  {
    path: ':workspace/my-issues/created',
    element: <IssuesCreatedPage />,
  },
  {
    path: ':workspace/my-issues/activity',
    element: <IssuesActivityPage />,
  },
  {
    path: ':workspace/my-issues/subscribed',
    element: <IssuesSubscribedPage />,
  },
  {
    path: ':workspace/search',
    element: <SearchIssuePage />,
  },
  {
    path: ':workspace/inbox',
    element: <InboxPage />,
  },
  {
    path: ':workspace/inbox/:id',
    element: <InboxPage />,
  },
  {
    path: ':workspace/issue/:identifier',
    element: <IssueDetailPage />,
  },
  {
    path: ':workspace/teams',
    element: <TeamsPage />,
  },

  {
    path: ':workspace/document/:id',
    element: <DocumentDetailPage />,
  },
  {
    path: ':workspace/team/:team/issues',
    element: <TeamIssuesPage />,
  },
  {
    path: ':workspace/team/:team/active',
    element: <TeamActivesPage />,
  },
  {
    path: ':workspace/team/:team/backlog',
    element: <TeamBacklogPage />,
  },
  {
    path: ':workspace/team/:team/documents',
    element: <DocumentsPage />,
  },
  {
    path: ':workspace/team/:team/projects',
    element: <ProjectsPage />,
  },
  {
    path: ':workspace/project/:code',
    element: <ProjectDetailPage />,
  },
];

export default AuthIssueRoute;
