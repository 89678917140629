import { useState } from 'react';

import { create } from 'zustand';

type IPageState = {
  isOpenSidebar: boolean;
  setIsOpenSidebar: (value: boolean) => void;
};

const createStore = () =>
  create<IPageState>((set) => ({
    isOpenSidebar: false,
    setIsOpenSidebar: (value: boolean) => set({ isOpenSidebar: value }),
  }));

const useSetting = () => {
  const [useStore] = useState(createStore);
  const { isOpenSidebar, setIsOpenSidebar } = useStore();

  const onToggleSidebar = async (value: boolean) => {
    setIsOpenSidebar(value);
  };

  return { isOpenSidebar, event: { onToggleSidebar } };
};

export default useSetting;
