import { Label } from '@/components/Label';
import { Switch } from '@/components/Switch';

const ToggleSetting = () => {
  return (
    <div className="flex flex-col gap-4">
      <Label className="font-semibold">Copy setting from existing team</Label>
      <div className="flex flex-row gap-4">
        <Label className="opacity-50">
          Private teams and their issues are only visible to members of the team
          and admins. Only admins and team owners can add new users to a private
          team. Public teams and their issues are visible to anyone in the
          workspace.
        </Label>
        <Switch disabled />
      </div>
    </div>
  );
};
export default ToggleSetting;
