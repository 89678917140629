import { useContext } from 'react';

import { yupResolver } from '@hookform/resolvers/yup';
import { useForm } from 'react-hook-form';
import * as yup from 'yup';

import { Button } from '@/components/Button';
import { Card } from '@/components/Card';
import { Form, FormField } from '@/components/Form';
import { Input } from '@/components/Input';
import { LabelError } from '@/components/LabelError';
import { useErrorHandler } from '@/hooks/useToasterHandler';
import WorkspaceContext from '@/providers/workspace/WorkspaceContext';
import { BaseColors } from '@/services/core/constant';
import { LabelFragment, useCreateLabelMutation } from '@/types/gql/generated';

import ColorPicker from '../../../components/ColorPicker';

export type ICreateLabel = {
  name: string;
  color: string;
};

export const schemaCreateLabel = yup.object().shape({
  name: yup.string().required(),
  color: yup.string().required(),
});

const CreateLabelForm = (props: {
  teamId?: string;
  onSuccess: (data: LabelFragment) => void;
  onCancel: () => void;
}) => {
  const { selected } = useContext(WorkspaceContext);
  const { showErrorToast } = useErrorHandler();

  const form = useForm<ICreateLabel>({
    resolver: yupResolver<ICreateLabel>(schemaCreateLabel),
    defaultValues: {
      name: '',
      color: BaseColors[0],
    },
  });

  const createLabel = useCreateLabelMutation({
    onMutate: () => {},
    onError: (res) => {
      showErrorToast({ error: res });
    },
    onSuccess: (res) => {
      form.reset();
      props.onSuccess(res.createLabel);
    },
    onSettled: () => {},
  });

  const onSubmitCreate = (data: ICreateLabel) => {
    if (!selected?.id) return;
    createLabel.mutate({
      createInput: {
        name: data.name,
        color: data.color,
        team_id: props.teamId,
        description: '',
        workspace_id: selected.id,
      },
    });
  };

  return (
    <Card className="animate-fade-down animate-delay-0 animate-duration-100 animate-ease-in p-2">
      <Form {...form}>
        <form onSubmit={form.handleSubmit(onSubmitCreate)}>
          <div className="flex flex-row items-center gap-4">
            <ColorPicker
              onSelected={(data) => form.setValue('color', data)}
              color={form.watch('color')}
            >
              <Button size={'sm'} type="button" variant={'secondary'}>
                <span
                  className={`inline-block h-[10px] w-[10px] rounded-full`}
                  style={{ backgroundColor: form.watch('color') }}
                ></span>
              </Button>
            </ColorPicker>

            <div className="w-full">
              <FormField
                control={form.control}
                name="name"
                render={({ field }) => (
                  <>
                    <Input {...field} placeholder="Label name"></Input>

                    <LabelError>
                      {form.formState.errors.name?.message}
                    </LabelError>
                  </>
                )}
              />
            </div>

            <Button
              variant={'secondary'}
              type="button"
              size={'sm'}
              onClick={props.onCancel}
            >
              Cancel
            </Button>
            <Button
              size={'sm'}
              disabled={createLabel.isPending || !form.formState.isValid}
              type="submit"
            >
              Save
            </Button>
          </div>
        </form>
      </Form>
    </Card>
  );
};

export default CreateLabelForm;
