import { useEffect, useState } from 'react';

import { Avatar, AvatarFallback } from '@/components/Avatar';
import { Button } from '@/components/Button';
import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuItem,
  DropdownMenuSeparator,
  DropdownMenuTrigger,
} from '@/components/DropdownMenu';
import { Input } from '@/components/Input';
import { Label } from '@/components/Label';
import { MoreHorizontalIcon } from '@/icons/index';
import {
  MemberWorkspaceFragment,
  TeamFragment,
  WorkspaceFragment,
} from '@/types/gql/generated';

import CreateInviteMember from './create/CreateInviteMember';
import DeleteInviteMemberDialog from './delete/DeleteInviteMemberDialog';

const MoreButton = (props: {
  children: React.ReactNode;
  invite: MemberWorkspaceFragment;
  onDelete: (invite: MemberWorkspaceFragment) => void;
}) => {
  return (
    <>
      <DropdownMenu>
        <DropdownMenuTrigger>{props.children}</DropdownMenuTrigger>
        <DropdownMenuContent>
          <DropdownMenuItem>Resend Invite</DropdownMenuItem>
          <DropdownMenuSeparator />
          <DropdownMenuItem
            onClick={() => props.onDelete(props.invite)}
            color="red"
          >
            Delete
          </DropdownMenuItem>
        </DropdownMenuContent>
      </DropdownMenu>
    </>
  );
};

const InviteList = (props: {
  invites: MemberWorkspaceFragment[];
  onDelete: (label: MemberWorkspaceFragment) => void;
}) => {
  return (
    <div className="mt-[30px] flex flex-col gap-4">
      {props.invites.map((item) => (
        <div key={item.id} className="flex flex-row items-center gap-4">
          <div>
            <Avatar className="h-[40px] w-[40px]">
              <AvatarFallback className="uppercase">
                {item.email.slice(0, 2)}
              </AvatarFallback>
            </Avatar>
          </div>
          <div className="grow">
            <Label className="block font-semibold">{item.email}</Label>
            <Label className="opacity-50">
              {item.is_joined ? '' : 'Pending'}
            </Label>
          </div>

          <div>
            <MoreButton onDelete={props.onDelete} invite={item}>
              <Button
                size={'sm'}
                variant="ghost"
                type="button"
                className="opacity-30 hover:opacity-100"
              >
                <MoreHorizontalIcon size={18} />
              </Button>
            </MoreButton>
          </div>
        </div>
      ))}
    </div>
  );
};

const InviteMember = (props: {
  team?: TeamFragment;
  workspace: WorkspaceFragment;
  memberWorkspaces: MemberWorkspaceFragment[];
}) => {
  const [filterName, setFilterName] = useState<string>();
  const [selectedDelete, setSelectedDelete] = useState<
    MemberWorkspaceFragment | undefined
  >();

  const onDeleteInviteMember = (data: MemberWorkspaceFragment) => {
    setSelectedDelete(data);
  };

  useEffect(() => {
    // fetchEvent.fetchMembersWorkspace({ team_id: props.team?.id });
  }, [props.team]);

  return (
    <div className="mt-[30px] w-full">
      <div className="flex flex-col gap-2">
        <Label className="font-semibold">Manage members</Label>
        <Label className="opacity-50">
          On the Free plan all members in a workspace are administrators.
          Upgrade to the Standard plan to add the ability to assign or remove
          administrator roles.
        </Label>
      </div>
      <div className="mt-[30px] flex w-full flex-row items-center gap-4">
        <div className="grow">
          <div className="max-w-[300px]">
            <Input
              placeholder="Search by name or email"
              value={filterName}
              onChange={(e) => setFilterName(e.currentTarget.value)}
            ></Input>
          </div>
        </div>
        <div>
          <CreateInviteMember
            initSelectedTeams={props.team ? [props.team] : []}
            workspace={props.workspace}
          >
            <Button type="button">Invite people</Button>
          </CreateInviteMember>
        </div>
      </div>
      <InviteList
        invites={props.memberWorkspaces.filter((item) =>
          item.email.includes(filterName ?? ''),
        )}
        onDelete={onDeleteInviteMember}
      />
      <DeleteInviteMemberDialog
        inviteMember={selectedDelete}
        onCancel={() => setSelectedDelete(undefined)}
        onDeleteSuccess={() => setSelectedDelete(undefined)}
      />
    </div>
  );
};

export default InviteMember;
