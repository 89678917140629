import { createContext } from 'react';

type IIssueContentHistoryContext = {
  isOpen: boolean;
  issueId: string | undefined;
  showModal: (props: { isOpen: boolean; issueId?: string | undefined }) => void;
};

const IssueContentHistoryContext = createContext<IIssueContentHistoryContext>({
  isOpen: false,
  issueId: undefined,
  showModal: () => undefined,
});

export default IssueContentHistoryContext;
