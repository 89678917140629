import { Button } from '@/components/Button';
import { Label } from '@/components/Label';
import { PlusIcon } from '@/icons/core';
import { TopNavigationBody } from '@/providers/main/components/TopNavigationBody';
import useProjectServices from '@/providers/project/useProjectServices';
import { ProjectFragment } from '@/types/gql/generated';

const HeaderProjectComponent = (props: { data: ProjectFragment }) => {
  const { showCreateProjectPopup } = useProjectServices();

  return (
    <TopNavigationBody>
      <div className="flex w-full flex-row items-center gap-2">
        <div className="flex grow flex-row items-center gap-2">
          <div className="flex flex-row items-center">
            <Label className="font-semibold">Projects</Label>
          </div>

          {props.data.is_deleted && (
            <Button
              variant={'destructive'}
              className="flex items-center font-semibold"
            >
              Deleted
            </Button>
          )}
        </div>
        <div className="flex-none">
          <Button
            type="button"
            variant={'ghost'}
            size={'sm'}
            onClick={() => showCreateProjectPopup({ isOpen: true })}
          >
            <PlusIcon width={20} height={20} />
          </Button>
        </div>
      </div>
    </TopNavigationBody>
  );
};
export default HeaderProjectComponent;
