import { useContext } from 'react';

import IssueServicesContext from './IssueServicesContext';

export const useIssueServices = () => {
  const { isShowCreateIssue, showCreatePopup } =
    useContext(IssueServicesContext);

  return {
    isShowCreateIssue,
    showCreatePopup,
  };
};

export default useIssueServices;
