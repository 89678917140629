import { createContext } from 'react';

import {
  WorkspaceFragment,
  LabelFragment,
  TeamFragment,
  WorkflowFragment,
  ProjectStatusFragment,
  IssueFragment,
  UserFragment,
  NotificationFragment,
  SubscriberFragment,
  FavoriteFragment,
  MemberWorkspaceFragment,
  RoleType,
  ProjectFragment,
  IDocumentFragment,
} from '@/types/gql/generated';
import { ITeamData } from '@/types/team/team.interface';

type IWorkspaceContext = {
  workspaces: WorkspaceFragment[];
  selected: WorkspaceFragment | undefined;
  labels: LabelFragment[];
  teams: TeamFragment[];
  workflows: WorkflowFragment[];
  projectStatuses: ProjectStatusFragment[];
  issues: IssueFragment[];
  documents: IDocumentFragment[];
  projects: ProjectFragment[];
  members: UserFragment[];
  notifications: NotificationFragment[];
  subscribers: SubscriberFragment[];
  favorites: FavoriteFragment[];
  memberWorkspaces: MemberWorkspaceFragment[];
  role: RoleType;
  getDataByTeam?: (props: {
    team_id?: string;
    team_identifier?: string;
  }) => ITeamData;
  getIssuesByTeam?: (props?: {
    team_id?: string;
    team_identifier?: string;
  }) => IssueFragment[];
  getDocumentsByTeam?: (props?: {
    team_id?: string;
    team_identifier?: string;
  }) => IDocumentFragment[];
  getProjectsByTeam?: (props?: {
    team_id?: string;
    team_identifier?: string;
  }) => ProjectFragment[];
};

const WorkspaceContext = createContext<IWorkspaceContext>({
  workspaces: [],
  selected: undefined,
  labels: [],
  teams: [],
  members: [],
  issues: [],
  documents: [],
  projects: [],
  workflows: [],
  notifications: [],
  projectStatuses: [],
  subscribers: [],
  favorites: [],
  memberWorkspaces: [],
  role: RoleType.Member,
});

export default WorkspaceContext;
