import { cn } from '@/utils/utils';

export const AnimationFadeIn = (props: {
  children: React.ReactNode;
  className?: string;
}) => {
  return (
    <div
      className={cn(
        'animate-fade-down animate-delay-0 animate-duration-100 animate-ease-in',
        props.className,
      )}
    >
      {props.children}
    </div>
  );
};
export const AnimationFade = (props: {
  children: React.ReactNode;
  className?: string;
}) => {
  return (
    <div
      className={cn(
        'animate-fade animate-delay-0 animate-duration-500 animate-ease-in',
        props.className,
      )}
    >
      {props.children}
    </div>
  );
};
