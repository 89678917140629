/* eslint-disable import/no-extraneous-dependencies */
import { TElement } from '@udecode/plate-common';
import isEmpty from 'lodash/isEmpty';

import { initialValueEditor } from '@/pages/cores';

class PlateUtils {
  static setStringToTElement(value: string) {
    return [{ id: 1, type: 'p', children: [{ text: value }] }] as TElement[];
  }

  static getTitleFromTElement(value: TElement[] | undefined) {
    if (!value) return undefined;
    if (
      value.length === 1 &&
      value[0] &&
      value[0].type === 'p' &&
      value[0].children.length === 1 &&
      isEmpty(value[0].children[0]?.text)
    ) {
      return undefined;
    }
    const getContent = value[0]?.children[0]?.text as string;
    return getContent;
  }

  static getContentFromTElement(value: TElement[] | undefined) {
    if (!value) return undefined;

    if (
      value.length === 1 &&
      value[0] &&
      value[0].type === 'p' &&
      value[0].children.length === 1 &&
      isEmpty(value[0].children[0]?.text)
    ) {
      return undefined;
    }
    const getContent = JSON.stringify(value);
    return getContent;
  }

  static getTElementFromContent(value: string | undefined) {
    if (!value) return initialValueEditor;
    const getContent = JSON.parse(value);
    return getContent as TElement[];
  }
}

export default PlateUtils;
