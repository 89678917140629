import React, { useContext } from 'react';

import WorkspaceContext from '@/providers/workspace/WorkspaceContext';
import { UserFragment } from '@/types/gql/generated';

const RenderMember = (props: {
  id: string | undefined;
  renderItem: (value: UserFragment | undefined) => React.ReactNode;
}) => {
  if (!props.id) {
    return <>{props.renderItem(undefined)}</>;
  }
  const { members } = useContext(WorkspaceContext);
  return <>{props.renderItem(members.find((item) => item.id === props.id))}</>;
};
const RenderMembers = (props: {
  ids: string[];
  renderItem: (values: UserFragment[]) => React.ReactNode;
}) => {
  const { members } = useContext(WorkspaceContext);
  return (
    <>
      {props.renderItem(members.filter((item) => props.ids.includes(item.id)))}
    </>
  );
};
const MemoizedRenderMembers = React.memo(RenderMembers);
const MemoizedRenderMember = React.memo(RenderMember);
export { MemoizedRenderMember, MemoizedRenderMembers };
