import {
  AlertDialog,
  AlertDialogAction,
  AlertDialogCancel,
  AlertDialogContent,
  AlertDialogDescription,
  AlertDialogTitle,
} from '@/components/AlertDialog';
import { useErrorHandler } from '@/hooks/useToasterHandler';
import {
  MemberTeamFragment,
  useRemoveMemberTeamMutation,
} from '@/types/gql/generated';

const DeleteInviteMemberDialog = (props: {
  inviteMember: MemberTeamFragment | undefined;
  onCancel: () => void;
  onDeleteSuccess: (inviteMember: MemberTeamFragment) => void;
}) => {
  const { showErrorToast } = useErrorHandler();

  const removeMemberTeam = useRemoveMemberTeamMutation({
    onMutate: () => {},
    onError: (res) => {
      showErrorToast({ error: res });
    },
    onSuccess: (res) => {
      props.onDeleteSuccess(res.removeMemberTeam);
    },
    onSettled: () => {},
  });

  return (
    <AlertDialog open={!!props.inviteMember}>
      <AlertDialogContent className="max-w-[450px]">
        <AlertDialogTitle className="text-md">
          Are you sure you want to delete the "{props.inviteMember?.email}"
          invite?
        </AlertDialogTitle>
        <AlertDialogDescription>
          The invite will be removed. This might also affect issues and drafts
          you do not have access to. This action cannot be undone.
        </AlertDialogDescription>

        <div className="flex flex-row items-center justify-end gap-4">
          <AlertDialogCancel onClick={() => props.onCancel()}>
            Cancel
          </AlertDialogCancel>
          <AlertDialogAction
            onClick={() =>
              props.inviteMember &&
              removeMemberTeam.mutate({ id: props.inviteMember.id })
            }
          >
            Delete
          </AlertDialogAction>
        </div>
      </AlertDialogContent>
    </AlertDialog>
  );
};

export default DeleteInviteMemberDialog;
