import React, { useContext, useEffect } from 'react';

import { Dialog, DialogContent } from '@/components/Dialog';
import DocumentServicesContext from '@/providers/document/DocumentServicesContext';

import { CreateDocumentComponent } from './CreateDocumentComponent';

export const CreateDocumentDialog = (props: {
  initTeamId?: string;
  onSuccess: () => void;
  onCancel: () => void;
}) => {
  const { isShowCreateDocument, showCreateDocumentPopup } = useContext(
    DocumentServicesContext,
  );

  useEffect(() => {}, [isShowCreateDocument]);

  return (
    <>
      <Dialog
        modal={true}
        open={isShowCreateDocument}
        onOpenChange={(value) => showCreateDocumentPopup({ isOpen: value })}
      >
        <DialogContent
          className="flex max-w-[820px] flex-col gap-2"
          style={{ padding: 0 }}
        >
          <CreateDocumentComponent {...props} type={'modal'} />
        </DialogContent>
      </Dialog>
    </>
  );
};
