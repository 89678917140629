import { useEffect } from 'react';

import { IssueFragment, useGetCommentsQuery } from '@/types/gql/generated';

import IssueDetailContext from './IssueDetailContext';
import useComments from '../components/Comment/useComments';

const IssueDetailProvider = (props: {
  children?: React.ReactNode;
  issue: IssueFragment;
}) => {
  const { event, comments } = useComments();
  const getComments = useGetCommentsQuery({
    getInput: {
      workspace_id: props.issue.workspace_id,
      issue_id: props.issue.id,
    },
  });
  useEffect(() => {
    event.storeComments(getComments.data?.comments ?? []);
  }, [getComments.data?.comments]);
  return (
    <IssueDetailContext.Provider
      value={{
        comments: comments,
      }}
    >
      {props.children}
    </IssueDetailContext.Provider>
  );
};

export default IssueDetailProvider;
