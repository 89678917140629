import { useContext, useEffect, useState } from 'react';

import { useParams } from 'react-router-dom';

import WorkspaceContext from '@/providers/workspace/WorkspaceContext';
import { IDocumentFragment } from '@/types/gql/generated';

import useTeam from '../useTeam';

const useDocuments = () => {
  const params = useParams();
  const { getDocumentsByTeam, documents: rootDocuments } =
    useContext(WorkspaceContext);

  const { team } = useTeam();
  const [initial, setInitial] = useState<boolean>(false);
  const [documents, setDocuments] = useState<IDocumentFragment[]>([]);

  useEffect(() => {
    if (params.team && getDocumentsByTeam && team) {
      const getDocuments = getDocumentsByTeam({ team_identifier: params.team });

      setDocuments(getDocuments);

      if (!initial) setTimeout(() => setInitial(true), 500);
    }
  }, [params.team, rootDocuments, team]);

  return {
    initial,
    documents,
  };
};

export default useDocuments;
