import { useContext, useEffect } from 'react';

import { Dialog, DialogContent } from '@/components/Dialog';
import ProjectServicesContext from '@/providers/project/ProjectServicesContext';

import { CreateProjectComponent } from './CreateProjectComponent';

export const CreateProjectDialog = (props: {
  initTeamId?: string;
  onSuccess: () => void;
  onCancel: () => void;
}) => {
  const { isShowCreateProject, showCreateProjectPopup } = useContext(
    ProjectServicesContext,
  );

  useEffect(() => {}, [isShowCreateProject]);

  return (
    <>
      <Dialog
        modal={true}
        open={isShowCreateProject}
        onOpenChange={(value) => showCreateProjectPopup({ isOpen: value })}
      >
        <DialogContent
          className="flex max-w-[820px] flex-col gap-2"
          style={{ padding: 0 }}
        >
          <CreateProjectComponent {...props} />
        </DialogContent>
      </Dialog>
    </>
  );
};
