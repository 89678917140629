import { create } from 'zustand';

type IPageState = {
  isOpen: boolean;
  issueId: string | undefined;
  setData: (props: { isOpen: boolean; issueId?: string }) => void;
};

const createStore = create<IPageState>((set) => ({
  isOpen: false,
  issueId: undefined,
  setData: (props: { isOpen: boolean; issueId?: string }) =>
    set({ isOpen: props.isOpen, issueId: props.issueId }),
}));

const useIssueContentHistoryProvider = () => {
  const { isOpen, issueId, setData } = createStore();
  return {
    isOpen,
    issueId,
    event: {
      setData,
    },
  };
};

export default useIssueContentHistoryProvider;
