import React, { lazy } from 'react';

import { RouteObject } from 'react-router-dom';

const JoinOverviewPage = lazy(() => import('../../pages/join'));
const InviteLinkPage = lazy(() => import('../../pages/invite/invite-link'));
const StartupRoute: RouteObject[] = [
  {
    path: 'join',
    element: <JoinOverviewPage />,
  },
  {
    path: 'join/:invite',
    element: <InviteLinkPage />,
  },
];

export default StartupRoute;
