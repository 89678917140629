import React from 'react';

import classNames from 'classnames';

import {
  Popover,
  PopoverClose,
  PopoverContent,
  PopoverTrigger,
} from '@/components/Popover';
import { BaseColors } from '@/services/core/constant';

const ColorPicker = (props: {
  children: React.ReactNode;
  color?: string;
  onSelected: (value: string) => void;
}) => {
  return (
    <Popover>
      <PopoverTrigger>{props.children}</PopoverTrigger>
      <PopoverContent className="w-[400px]">
        <div className="flex flex-row items-center justify-between">
          {BaseColors.map((item) => (
            <PopoverClose key={item}>
              <span
                onClick={() => props.onSelected(item)}
                key={item}
                style={{ backgroundColor: item }}
                className={classNames(
                  `inline-block h-[20px] w-[20px] cursor-pointer rounded-full`,
                  {
                    'h-[22px] w-[22px] border-[2px] border-solid border-red-400':
                      item === props.color,
                  },
                )}
              ></span>
            </PopoverClose>
          ))}
        </div>
      </PopoverContent>
    </Popover>
  );
};
export default ColorPicker;
