import { useContext, useState } from 'react';

import { ScrollArea, ScrollBar } from '@/components/ScrollArea';
import WorkspaceContext from '@/providers/workspace/WorkspaceContext';
import { IGroupingIssue } from '@/types/core/core';

import useIssuesCreated from './useIssuesCreated';
import FilterIssue from '../../components/FilterIssue/FilterIssue';
import { IssuesGrouping } from '../../components/ListGrouping/ListGrouping';
import MyIssueHeader from '../../components/MyIssueHeader/MyIssueHeader';
import { ConditionFilterList } from '../../constant/constant';

const IssuesCreatedPage = () => {
  const { selected: selectedWorkspace } = useContext(WorkspaceContext);
  const [grouping, setGrouping] = useState<IGroupingIssue>('no_grouping');

  const {
    issues,
    event,
    workflows,
    labels,
    members,
    selectedStatuses,
    selectedConditionStatus,
    selectedPriorities,
    selectedConditionPriority,
    selectedLabels,
    selectedConditionLabel,
    selectedAssignees,
    selectedConditionAssignee,
  } = useIssuesCreated();
  return (
    <div className="flex w-full flex-col">
      <MyIssueHeader active={'created'} />
      <FilterIssue
        selectedGrouping={grouping}
        onSelectedGrouping={setGrouping}
        onClearStatuses={() => {
          event.setSelectedStatuses([]);
          event.setSelectedConditionStatus(ConditionFilterList[0]!);
        }}
        onSelectedStatuses={event.setSelectedStatuses}
        selectedStatuses={selectedStatuses}
        selectedConditionStatus={selectedConditionStatus}
        onChangeConditionStatus={event.setSelectedConditionStatus}
        onClearPriorities={() => {
          event.setSelectedPriorities([]);
          event.setSelectedConditionPriority(ConditionFilterList[0]!);
        }}
        onSelectedPriorities={event.setSelectedPriorities}
        selectedPriorities={selectedPriorities}
        selectedConditionPriority={selectedConditionPriority}
        onChangeConditionPriority={event.setSelectedConditionPriority}
        onClearLabels={() => {
          event.setSelectedLabels([]);
          event.setSelectedConditionLabel(ConditionFilterList[0]!);
        }}
        onSelectedLabels={event.setSelectedLabels}
        selectedLabels={selectedLabels}
        selectedConditionLabel={selectedConditionLabel}
        onChangeConditionLabel={event.setSelectedConditionLabel}
        onClearAssignees={() => {
          event.setSelectedAssignees([]);
          event.setSelectedConditionAssignee(ConditionFilterList[0]!);
        }}
        onSelectedAssignees={event.setSelectedAssignees}
        selectedAssignees={selectedAssignees}
        selectedConditionAssignee={selectedConditionAssignee}
        onChangeConditionAssignee={event.setSelectedConditionAssignee}
        workflows={workflows}
        labels={labels}
        members={members}
      />
      {selectedWorkspace && (
        <ScrollArea type="auto" style={{ maxHeight: '100%' }}>
          <IssuesGrouping grouping={grouping} issues={issues} />
          <ScrollBar orientation="vertical" />
        </ScrollArea>
      )}
    </div>
  );
};

export default IssuesCreatedPage;
