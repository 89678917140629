import { useContext } from 'react';

import { PlusIcon } from 'lucide-react';

import { Button } from '@/components/Button';
import LoadingFullPage from '@/components/LoadingPage';
import ProjectTableList from '@/pages/project/components/ProjectTableList/ProjectTableList';
import ProjectServicesContext from '@/providers/project/ProjectServicesContext';

import useProjects from './useProjects';
import TeamHeader from '../components/TeamHeader';
import useTeam from '../useTeam';

const ProjectsPage = () => {
  const { initial, projects } = useProjects();
  const { team } = useTeam();
  const { showCreateProjectPopup } = useContext(ProjectServicesContext);

  return (
    <>
      {initial && (
        <div className="flex w-full flex-col">
          <TeamHeader
            team={team?.team}
            name={'Project'}
            isCreateDocument={true}
            right={
              <div className="flex-none">
                <Button
                  type="button"
                  variant={'ghost'}
                  size={'sm'}
                  onClick={() =>
                    showCreateProjectPopup({
                      isOpen: true,
                      initTeamId: team?.team?.id,
                    })
                  }
                >
                  <PlusIcon width={20} height={20} />
                </Button>
              </div>
            }
          />

          <ProjectTableList projects={projects.filter((item) => item.title)} />
        </div>
      )}
      {!initial && <LoadingFullPage description={'Loading issues...'} />}
    </>
  );
};

export default ProjectsPage;
