import { useContext, useEffect, useState } from 'react';

import WorkspaceContext from '@/providers/workspace/WorkspaceContext';
import { LabelFragment } from '@/types/gql/generated';

import SettingLabelsComponent from './SettingLabelsComponent';

const SettingLabelsPage = () => {
  const { labels: coreLabels } = useContext(WorkspaceContext);

  const [labels, setLabels] = useState<LabelFragment[]>([]);

  useEffect(() => {
    setLabels(coreLabels.filter((item) => item.team_id === null));
  }, [coreLabels]);

  return <SettingLabelsComponent labels={labels} team={undefined} />;
};

export default SettingLabelsPage;
