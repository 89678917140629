import {
  ErrorType,
  InvalidInputError,
  NotFoundError,
  UnauthorizedError,
  UnexpectedError,
} from './error-type';

export const toErrorType = (error: any): ErrorType => {
  // const stackTrace = new Error().stack;
  if (
    error.response &&
    error.response.errors &&
    error.response.errors.length > 0
  ) {
    const statusCode =
      (error.response.errors[0].extensions.originalError &&
        error.response.errors[0].extensions.originalError.statusCode) ??
      undefined;
    if (statusCode && statusCode === 401) {
      return new UnauthorizedError(error.response.errors[0].message);
    }
    if (statusCode && statusCode === 404) {
      return new NotFoundError(error.response.errors[0].message);
    }

    if (error.response.errors[0].extensions.code === 'BAD_USER_INPUT') {
      return new InvalidInputError(error.response.errors[0].message);
    }

    return new UnexpectedError(error.response.errors[0].message);
  }

  return error;
};
