import { yupResolver } from '@hookform/resolvers/yup';
import { useForm } from 'react-hook-form';
import * as yup from 'yup';

import { Button } from '@/components/Button';
import { Form, FormField } from '@/components/Form';
import { Input } from '@/components/Input';
import { Label } from '@/components/Label';
import { LabelError } from '@/components/LabelError';
import { useErrorHandler } from '@/hooks/useToasterHandler';
import { BaseColors } from '@/services/core/constant';
import {
  useUpdateWorkflowMutation,
  WorkflowFragment,
} from '@/types/gql/generated';
import { convertHexToRGBA } from '@/utils/color';

import { MemoizedRenderStatusIcon } from '../../../../../issue/components/Properties/StatusButton';
import ColorPicker from '../../../../components/ColorPicker';

export type IUpdateWorkflow = {
  name: string;
  description?: string;
  color?: string;
};

export const schemaUpdateWorkflow = yup.object().shape({
  name: yup.string().required(),
  color: yup.string(),
  description: yup.string(),
});

const UpdateWorkflowForm = (props: {
  workflow: WorkflowFragment;
  onSuccess: (data: WorkflowFragment) => void;
  onCancel: () => void;
}) => {
  const { showErrorToast } = useErrorHandler();
  const updateWorkflow = useUpdateWorkflowMutation({
    onMutate: () => {},
    onError: (res) => {
      showErrorToast({ error: res });
    },
    onSuccess: (res) => {
      props.onSuccess(res.updateWorkflow);
    },
    onSettled: () => {},
  });
  const form = useForm<IUpdateWorkflow>({
    resolver: yupResolver<IUpdateWorkflow>(schemaUpdateWorkflow),
    defaultValues: {
      name: props.workflow.name,
      description: props.workflow.description ?? '',
      color: props.workflow.color ?? BaseColors[0],
    },
  });
  const onSubmitUpdate = (data: IUpdateWorkflow) => {
    updateWorkflow.mutate({
      updateInput: {
        id: props.workflow.id,
        name: data.name,
        description: data.description,
        color: data.color,
      },
    });
  };

  return (
    <div className="animate-fade-down animate-delay-0 animate-duration-100 animate-ease-in w-full">
      <Form {...form}>
        <form onSubmit={form.handleSubmit(onSubmitUpdate)}>
          <div className="flex flex-row gap-4">
            <ColorPicker
              onSelected={(data) => form.setValue('color', data)}
              color={form.watch('color')}
            >
              <Button
                size={'sm'}
                type="button"
                className={`bg-opacity-50! h-[36px] w-[36px] flex-none p-0`}
                style={{
                  backgroundColor: convertHexToRGBA(
                    form.watch('color') ?? '',
                    0.3,
                  ),
                }}
              >
                <MemoizedRenderStatusIcon
                  type={props.workflow.type}
                  color={form.watch('color')}
                />
              </Button>
            </ColorPicker>

            <div className="w-[160px] flex-none">
              <FormField
                control={form.control}
                name="name"
                render={({ field }) => (
                  <>
                    <Input {...field} placeholder="Name"></Input>

                    <LabelError>
                      {form.formState.errors.name?.message}
                    </LabelError>
                  </>
                )}
              />
            </div>
            <div className="w-full">
              <FormField
                control={form.control}
                name="description"
                render={({ field }) => (
                  <>
                    <Input {...field} placeholder="Name"></Input>
                    <div className="block w-full">
                      <Label>
                        {form.formState.errors.description?.message}
                      </Label>
                    </div>
                  </>
                )}
              />
            </div>
            <Button
              variant={'secondary'}
              type="button"
              onClick={props.onCancel}
              className="h-[36px]"
              size={'sm'}
            >
              Cancel
            </Button>
            <Button
              disabled={updateWorkflow.isPending || !form.formState.isValid}
              type="submit"
              className="h-[36px]"
              size={'sm'}
            >
              Save
            </Button>
          </div>
        </form>
      </Form>
    </div>
  );
};

export default UpdateWorkflowForm;
