import { useContext } from 'react';

import { Button } from '@/components/Button';
import { TablerListIcon } from '@/icons/core';

import SettingContext from '../SettingContext';

export const ToggleMenuSettingSidebar = () => {
  const { toggleMenuSidebar } = useContext(SettingContext);

  return (
    <Button
      type="button"
      variant={'ghost'}
      className="block lg:hidden"
      onClick={() => toggleMenuSidebar!(true)}
    >
      <TablerListIcon width={20} height={20} />
    </Button>
  );
};
