import { Separator } from '@/components/Separator';

const LineSetting = () => {
  return (
    <div className="my-[16px] py-[16px]">
      <Separator className="w-full opacity-50" orientation="horizontal" />
    </div>
  );
};
export default LineSetting;
