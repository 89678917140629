/* eslint-disable import/no-extraneous-dependencies */
import classNames from 'classnames';
import stc from 'string-to-color';

import { Label } from '@/components/Label';

import { Avatar, AvatarFallback, AvatarImage } from './Avatar';
import { match } from '../utils/custom-matcher';

type ICustomSize = 'lg' | 'md' | 'sm' | 'xl' | '2xl';

const getCustomSize = (size: ICustomSize = 'sm'): string => {
  return match([size])({
    sm: () => 'w-[20px] h-[20px] text-xs',
    md: () => 'w-[32px] h-[32px]',
    lg: () => 'w-[40px] h-[40px]',
    xl: () => 'w-[60px] h-[60px] ',
    '2xl': () => 'w-[120px] h-[120px] text-2xl',
  });
};

const getCustomTextSize = (size: ICustomSize = 'sm'): string => {
  return match([size])({
    sm: () => 'text-xs',
    md: () => 'text-base',
    lg: () => 'text-md',
    xl: () => 'text-2xl',
    '2xl': () => 'text-4xl',
  });
};

export function UserAvatar(props: {
  size: ICustomSize;
  className?: string;
  url?: string | undefined | null;
  fallback: string | undefined | null;
  style?: React.CSSProperties | undefined;
}) {
  const color = stc(props.fallback); // => "#1ad64b"

  return (
    <Avatar
      style={props.style}
      className={classNames(props.className, getCustomSize(props.size))}
    >
      {props.url && <AvatarImage src={props.url ?? ''} />}
      <AvatarFallback style={{ backgroundColor: color }} className="uppercase">
        <Label
          className={classNames('text-white', getCustomTextSize(props.size))}
        >
          {props.fallback?.slice(0, 2) ?? 'AB'}
        </Label>
      </AvatarFallback>
    </Avatar>
  );
}
