import { useContext } from 'react';

import { useNavigate } from 'react-router-dom';

import { TreeView } from '@/components/TreeView/Tree';
import { TreeViewElement } from '@/components/TreeView/TreeViewAPI';
import WorkspaceContext from '@/providers/workspace/WorkspaceContext';
import { IDocumentFragment } from '@/types/gql/generated';

const DocumentTableList = (props: { documents: IDocumentFragment[] }) => {
  const { selected } = useContext(WorkspaceContext);
  const navigate = useNavigate();
  const convertData = props.documents.map((item) => {
    return {
      id: item.id,
      name: item.title,
      children: [],
      parentId: item.document_id,
    } as TreeViewElement;
  });
  function BuildTree(flatList: TreeViewElement[]): TreeViewElement[] {
    const idToElementMap: { [id: string]: TreeViewElement } = {};
    const rootElements: TreeViewElement[] = [];

    flatList.forEach((element) => {
      idToElementMap[element.id] = { ...element, children: [] };
    });

    flatList.forEach((element) => {
      if (element.parentId) {
        const parentElement = idToElementMap[element.parentId];
        if (parentElement) {
          parentElement.children?.push(idToElementMap[element.id]!);
        }
      } else {
        rootElements.push(idToElementMap[element.id]!);
      }
    });

    return rootElements;
  }

  const elements = BuildTree(convertData);
  return (
    <div className="w-full">
      <TreeView
        elements={elements}
        initialExpendedItems={convertData.map((item) => item.id)}
        onSelectItem={(value) =>
          navigate(`/${selected?.url}/document/${value}`)
        }
      />
    </div>
  );
};

export default DocumentTableList;
