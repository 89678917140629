/* eslint-disable import/no-extraneous-dependencies */
import TimeAgo from 'react-timeago';
import { Node } from 'slate';

export const serialize = (value: any) => {
  return value
    .map((n: any) => {
      const result = Node.string(n);
      return result;
    })
    .join('\n');
};

export const deserialize = (value: string) => {
  return value.split('\n').map((line) => {
    return {
      children: [{ text: line }],
    };
  });
};

export const FormatTimeAgo = (props: { date: string }) => {
  return (
    <TimeAgo
      live={false}
      date={props.date}
      minPeriod={2}
      formatter={(value, unit, suffix) => {
        if (unit === 'second') {
          return `Just now`;
        }

        return `${value} ${value > 0 ? `${unit}s` : unit} ${suffix}`;
      }}
    />
  );
};
