import { useContext, useState } from 'react';

import { Input } from '@/components/Input';
import { ScrollArea, ScrollBar } from '@/components/ScrollArea';
import { TopNavigationBody } from '@/providers/main/components/TopNavigationBody';
import WorkspaceContext from '@/providers/workspace/WorkspaceContext';

import IssueTableList from '../issue/components/IssueTableList/IssueTableList';

const SearchIssuePage = () => {
  const { selected: selectedWorkspace, issues } = useContext(WorkspaceContext);
  const [search, setSearch] = useState<string>();
  return (
    <div className="flex w-full flex-col">
      <TopNavigationBody>
        <div className="grow">
          <Input
            placeholder="Search ..."
            autoFocus={true}
            onChange={(e) => setSearch(e.target.value)}
          />
        </div>
      </TopNavigationBody>

      {selectedWorkspace &&
        search &&
        search?.length > 0 &&
        issues.length > 0 && (
          <ScrollArea type="auto" style={{ maxHeight: '100%' }}>
            <IssueTableList
              data={issues.filter((item) => item.title.includes(search ?? ''))}
              type={'issues'}
            />
            <ScrollBar orientation="vertical" />
          </ScrollArea>
        )}
    </div>
  );
};

export default SearchIssuePage;
