import { SVGProps } from 'react';

import { match } from '@/utils/custom-matcher';

import {
  Airplane,
  Automobile,
  BearFace,
  Boar,
  CatFace,
  CowFace,
  Deer,
  DepartmentStore,
  DogFace,
  Eagle,
  EmojiBabyChick,
  FerrisWheel,
  GlobeShowingEuropeAfrica,
  HighSpeedTrain,
  Hotel,
  JapaneseCastle,
  LionFace,
  PassengerShip,
  SmilingFaceWithHorns,
  SmilingFaceWithSmilingEyes,
  SneezingFace,
  ThinkingFace,
  WinkingFace,
  WinkingFaceWithTongue,
} from './icons';

export type IEmojiIcon =
  | 'EmojiBabyChick'
  | 'BearFace'
  | 'Boar'
  | 'CatFace'
  | 'CowFace'
  | 'Deer'
  | 'DogFace'
  | 'Eagle'
  | 'LionFace'
  | 'Hotel'
  | 'Airplane'
  | 'Automobile'
  | 'GlobeShowingEuropeAfrica'
  | 'PassengerShip'
  | 'HighSpeedTrain'
  | 'JapaneseCastle'
  | 'DepartmentStore'
  | 'FerrisWheel'
  | 'SmilingFaceWithSmilingEyes'
  | 'SmilingFaceWithHorns'
  | 'ThinkingFace'
  | 'WinkingFaceWithTongue'
  | 'WinkingFace'
  | 'SneezingFace';
export const EmojiIconList: IEmojiIcon[] = [
  'EmojiBabyChick',
  'BearFace',
  'Boar',
  'CatFace',
  'CowFace',
  'Deer',
  'DogFace',
  'Eagle',
  'LionFace',
  'Hotel',
  'Airplane',
  'Automobile',
  'GlobeShowingEuropeAfrica',
  'PassengerShip',
  'HighSpeedTrain',
  'JapaneseCastle',
  'DepartmentStore',
  'FerrisWheel',
  'SmilingFaceWithSmilingEyes',
  'SmilingFaceWithHorns',
  'ThinkingFace',
  'WinkingFaceWithTongue',
  'WinkingFace',
  'SneezingFace',
];

const EmojiIcon = (props: {
  tag: IEmojiIcon | undefined;
  attrs?: SVGProps<SVGSVGElement>;
}) => {
  return props.tag ? (
    match([props.tag])({
      EmojiBabyChick: () => <EmojiBabyChick {...props.attrs} />,
      BearFace: () => <BearFace {...props.attrs} />,
      Boar: () => <Boar {...props.attrs} />,
      CatFace: () => <CatFace {...props.attrs} />,
      CowFace: () => <CowFace {...props.attrs} />,
      Deer: () => <Deer {...props.attrs} />,
      DogFace: () => <DogFace {...props.attrs} />,
      Eagle: () => <Eagle {...props.attrs} />,
      LionFace: () => <LionFace {...props.attrs} />,
      Hotel: () => <Hotel {...props.attrs} />,
      Airplane: () => <Airplane {...props.attrs} />,
      Automobile: () => <Automobile {...props.attrs} />,
      GlobeShowingEuropeAfrica: () => (
        <GlobeShowingEuropeAfrica {...props.attrs} />
      ),
      PassengerShip: () => <PassengerShip {...props.attrs} />,
      HighSpeedTrain: () => <HighSpeedTrain {...props.attrs} />,
      JapaneseCastle: () => <JapaneseCastle {...props.attrs} />,
      DepartmentStore: () => <DepartmentStore {...props.attrs} />,
      FerrisWheel: () => <FerrisWheel {...props.attrs} />,
      SmilingFaceWithSmilingEyes: () => (
        <SmilingFaceWithSmilingEyes {...props.attrs} />
      ),
      SmilingFaceWithHorns: () => <SmilingFaceWithHorns {...props.attrs} />,
      ThinkingFace: () => <ThinkingFace {...props.attrs} />,
      WinkingFaceWithTongue: () => <WinkingFaceWithTongue {...props.attrs} />,
      WinkingFace: () => <WinkingFace {...props.attrs} />,
      SneezingFace: () => <SneezingFace {...props.attrs} />,
    })
  ) : (
    <></>
  );
};
export default EmojiIcon;
