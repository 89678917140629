import { useContext } from 'react';

import classNames from 'classnames';
import { useNavigate } from 'react-router-dom';

import { Button } from '@/components/Button';
import { Label } from '@/components/Label';
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableHeader,
  TableRow,
} from '@/components/Table';
import { TooltipRoot } from '@/components/Tooltip';
import { UserAvatar } from '@/components/UserAvatar';
import EmojiIcon, { IEmojiIcon } from '@/icons/emoji';
import { TopNavigationBody } from '@/providers/main/components/TopNavigationBody';
import WorkspaceContext from '@/providers/workspace/WorkspaceContext';
import { TeamFragment } from '@/types/gql/generated';

const HeaderSection = (props: { teams: TeamFragment[] }) => {
  return (
    <TopNavigationBody>
      <div className="flex flex-row items-center gap-2">
        <Label className="font-semibold">Teams </Label>
        <Button type="button" size={'sm'} variant={'ghost'}>
          {props.teams.length}
        </Button>
      </div>
    </TopNavigationBody>
  );
};

const TeamRowItem = (props: { team: TeamFragment }) => {
  const { getDataByTeam, selected } = useContext(WorkspaceContext);
  const getData = getDataByTeam && getDataByTeam({ team_id: props.team.id });
  const navigate = useNavigate();
  return (
    <TableRow
      onClick={() =>
        navigate(`/${selected?.url}/team/${props.team.identifier}/issues`)
      }
    >
      <TableCell className="font-medium">
        <div className="flex flex-row items-center gap-2">
          <EmojiIcon
            tag={props.team.icon as IEmojiIcon}
            attrs={{
              width: 18,
              height: 18,
              color: props.team.color,
            }}
          />
          <Label>{props.team.name}</Label>
        </div>
      </TableCell>
      <TableCell>
        <Label>{props.team.identifier}</Label>
      </TableCell>
      <TableCell>
        <div className="relative flex flex-row items-center">
          {getData?.members.map((item, index) => (
            <TooltipRoot key={item.id} content={item.username}>
              <UserAvatar
                className={classNames('absolute')}
                style={{ left: 12 * index }}
                size="sm"
                url={item.image}
                fallback={item.username}
              />
            </TooltipRoot>
          ))}
        </div>
      </TableCell>
      <TableCell className="text-right"></TableCell>
    </TableRow>
  );
};

const TeamList = (props: { teams: TeamFragment[] }) => {
  return (
    <Table>
      <TableHeader>
        <TableRow>
          <TableHead>
            <Label>Name</Label>
          </TableHead>
          <TableHead className="w-[100px]">
            <Label>Identifier</Label>
          </TableHead>
          <TableHead className="w-[200px]">
            <Label>Members</Label>
          </TableHead>
          <TableHead className="w-[100px] text-right">
            <Label>Projects</Label>
          </TableHead>
        </TableRow>
      </TableHeader>
      <TableBody>
        {props.teams.map((item) => (
          <TeamRowItem key={item.id} team={item} />
        ))}
      </TableBody>
    </Table>
  );
};

const TeamsPage = () => {
  const { teams } = useContext(WorkspaceContext);

  return (
    <div className="flex w-full flex-col">
      <HeaderSection teams={teams} />
      <TeamList teams={teams} />
    </div>
  );
};

export default TeamsPage;
