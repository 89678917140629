import { useState } from 'react';

import { CreateDocumentDialog } from '@/pages/document/CreateDocument/CreateDocumentDialog';

import DocumentServicesContext from './DocumentServicesContext';
import useProvider from './useProvider';

const DocumentServicesWrapper = ({
  children,
}: {
  children?: React.ReactNode;
}) => {
  const { isShowCreateDocument, event } = useProvider();
  const [initCreate, setInitCreate] = useState<{
    initTeamId?: string;
  }>();
  return (
    <DocumentServicesContext.Provider
      value={{
        isShowCreateDocument,
        showCreateDocumentPopup: (value) => {
          event.showCreateDocumentPopup(value.isOpen);
          setInitCreate({ ...value });
        },
      }}
    >
      {children}

      <CreateDocumentDialog
        {...initCreate}
        onSuccess={() => {}}
        onCancel={() => {}}
      />
    </DocumentServicesContext.Provider>
  );
};

export default DocumentServicesWrapper;
