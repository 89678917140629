import { ToggleMenuSidebar } from './ToggleMenuSidebar';

export const TopNavigationBody = (props: { children: React.ReactNode }) => {
  return (
    <div className="flex h-[50px] w-full flex-none flex-row items-center justify-start gap-2 p-[10px]">
      <ToggleMenuSidebar />
      <div className="flex w-full flex-row">{props.children}</div>
    </div>
  );
};
