import { Dialog, DialogContent } from '@/components/Dialog';

import DocumentContentHistoryContext from './DocumentServicesContext';
import useDocumentContentHistoryProvider from './useDocumentContentHistoryProvider';
import DocumentContentHistoryComponent from '../../../pages/document/DocumentContentHistory/DocumentContentHistoryComponent';

const DocumentContentHistoryProvider = ({
  children,
}: {
  children?: React.ReactNode;
}) => {
  const { isOpen, documentId, event } = useDocumentContentHistoryProvider();
  return (
    <DocumentContentHistoryContext.Provider
      value={{
        isOpen: isOpen,
        documentId: documentId,
        showModal: (value) => event.setDataHistory(value),
      }}
    >
      {children}
      <Dialog
        modal={true}
        open={isOpen}
        onOpenChange={(value) => event.setDataHistory({ isOpen: value })}
      >
        <DialogContent className="h-[94%] w-[94%] max-w-4xl">
          {documentId && (
            <DocumentContentHistoryComponent
              document_id={documentId}
              onCancel={() => event.setDataHistory({ isOpen: false })}
            />
          )}
        </DialogContent>
      </Dialog>
    </DocumentContentHistoryContext.Provider>
  );
};
export default DocumentContentHistoryProvider;
