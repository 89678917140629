import React from 'react';

import './styles/globals.scss';
import { ApolloProvider } from '@apollo/client';
import { createRoot } from 'react-dom/client';
import { RouterProvider } from 'react-router-dom';

import ReactQueryClientProvider from './providers/ReactQueryClientProvider';
import router from './routes';
import { getAuthClient } from './services/core/grahpql-client/grahpql-client';
import { compose } from './utils/compose';

const container = document.getElementById('root');
const root = createRoot(container!);

root.render(
  compose(
    <React.StrictMode />,
    <React.Suspense fallback={'....'} />,
    <ReactQueryClientProvider />,
    <ApolloProvider client={getAuthClient()}>
      <RouterProvider router={router} />
    </ApolloProvider>,
  ),
);
