import { toast } from 'sonner';

export const useWarningHandler = () => {
  function showWarningToast(props: {
    title: string;
    description?: any;
    actions?: any;
  }) {
    toast.warning(props.title, {
      description: props.description,
      action: props.actions,
    });
  }

  return { showWarningToast };
};
