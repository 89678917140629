export const LocalStorageKey = {
  Token: 'token',
  WebsiteId: 'websiteId',
};

class LocalStorage {
  static getToken(): string | null {
    try {
      const result = localStorage.getItem(LocalStorageKey.Token);
      return result;
    } catch {
      return null;
    }
  }

  static setToken(value: string): boolean {
    try {
      localStorage.setItem(LocalStorageKey.Token, value);
      return true;
    } catch {
      return false;
    }
  }

  static removeToken(): boolean {
    try {
      localStorage.removeItem(LocalStorageKey.Token);
      return true;
    } catch {
      return false;
    }
  }

  static getWebsiteId(): number | null {
    try {
      const result = localStorage.getItem(LocalStorageKey.WebsiteId);
      return Number(result);
    } catch {
      return null;
    }
  }

  static setWebsiteId(value: number): boolean {
    try {
      localStorage.setItem(LocalStorageKey.WebsiteId, value.toString());
      return true;
    } catch {
      return false;
    }
  }

  static removeWebsiteId(): boolean {
    try {
      localStorage.removeItem(LocalStorageKey.WebsiteId);
      return true;
    } catch {
      return false;
    }
  }
}

export default LocalStorage;
