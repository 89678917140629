import { useFilePicker } from 'use-file-picker';
import {
  FileAmountLimitValidator,
  FileSizeValidator,
} from 'use-file-picker/validators';

import { InvalidInputError } from '@/services/core/error-type';
import { useCreateAttachmentMutation } from '@/types/gql/generated';

import { useErrorHandler } from './useToasterHandler';

const useUploadAttachment = (props: {
  type: string;
  workspace_id: string;
  onUploadSuccess: (path: string) => void;
}) => {
  const { showErrorToast } = useErrorHandler();

  const createAttachment = useCreateAttachmentMutation({
    onMutate: () => {},
    onError: (res) => {
      showErrorToast({ error: res });
    },
    onSuccess: (res) => {
      if (!res.createAttachment) return;
      props.onUploadSuccess(res.createAttachment.path);
    },
    onSettled: () => {},
  });

  const onAttachment = async (file: File) => {
    if (file) {
      createAttachment.mutate({
        createInput: {
          type: props.type,
          file: file,
          workspace_id: props.workspace_id,
        },
      });
    }
  };

  const { openFilePicker } = useFilePicker({
    readAs: 'DataURL',
    multiple: false,
    validators: [
      new FileAmountLimitValidator({ max: 1 }),
      new FileSizeValidator({ maxFileSize: 50 * 1024 * 1024 /* 50 MB */ }),
    ],

    onFilesRejected: () => {
      showErrorToast({
        error: new InvalidInputError('Input size invalid'),
      });
    },
    onFilesSuccessfullySelected: ({ plainFiles }) => {
      if (plainFiles[0]) {
        onAttachment(plainFiles[0]);
      }
    },
  });

  const onUpload = () => {
    openFilePicker();
  };

  return { loading: createAttachment.isPending, onUpload };
};
export default useUploadAttachment;
