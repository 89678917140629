import React, { useContext } from 'react';

import { Dialog, DialogContent } from '@/components/Dialog';
import IssueServicesContext from '@/providers/issue/IssueServicesContext';

import { CreateIssueComponent } from './CreateIssueComponent';

export const CreateIssueDialog = (props: {
  initTeamId?: string;
  initWorkflowId?: string;
  initMemberId?: string;
  initTitle?: string;
  initContent?: string;
  onSuccess: () => void;
  onCancel: () => void;
}) => {
  const { isShowCreateIssue, showCreatePopup } =
    useContext(IssueServicesContext);

  return (
    <>
      <Dialog
        open={isShowCreateIssue}
        onOpenChange={(value) => showCreatePopup({ isOpen: value })}
      >
        <DialogContent
          className="flex max-w-[820px] flex-col gap-2"
          style={{ padding: 0 }}
        >
          <CreateIssueComponent {...props} type={'modal'} />
        </DialogContent>
      </Dialog>
    </>
  );
};
