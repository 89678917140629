import { createContext } from 'react';

import { ThemeType } from '@/types/gql/generated';

type IThemeContext = {
  theme: ThemeType;
  onChange: (value: ThemeType) => void;
};

const ThemeContext = createContext<IThemeContext>({
  theme: ThemeType.Light,
  onChange: () => {},
});

export default ThemeContext;
