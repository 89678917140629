import { createContext } from 'react';

type DocumentDeleteContext = {
  isOpen: boolean;
  documentId: string | undefined;
  showModal: (props: {
    isOpen: boolean;
    documentId?: string | undefined;
  }) => void;
};

const DocumentDeleteContext = createContext<DocumentDeleteContext>({
  isOpen: false,
  documentId: undefined,
  showModal: () => undefined,
});

export default DocumentDeleteContext;
