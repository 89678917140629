import { useContext } from 'react';

import { Plus } from 'react-feather';

import {
  Accordion,
  AccordionContent,
  AccordionItem,
  AccordionTrigger,
} from '@/components/Accordion';
import { Button } from '@/components/Button';
import { Label } from '@/components/Label';
import EmojiIcon, { IEmojiIcon } from '@/icons/emoji';
import { TeamFragment } from '@/types/gql/generated';

import WorkspaceContext from '../../workspace/WorkspaceContext';

const Item = (props: {
  title: string;
  path: string;
  onNavigation: (path: string) => void;
}) => {
  return (
    <Button
      className="h-[30px] pl-[52px]"
      variant="ghost"
      onClick={() => props.onNavigation(props.path)}
    >
      <div className="flex w-full items-center gap-4">
        <Label>{props.title}</Label>
      </div>
    </Button>
  );
};

const Team = (props: {
  team: TeamFragment;
  onNavigate: (path: string) => void;
}) => {
  const { selected } = useContext(WorkspaceContext);
  const MenuTeams = [
    { title: 'General', path: 'general' },
    { title: 'Members', path: 'members' },
    { title: 'Labels', path: 'labels' },
    { title: 'Workflow', path: 'workflows' },
  ];
  const onNavigation = (path: string) => {
    props.onNavigate(`/${selected?.url}/settings/${path}`);
  };
  return (
    <Accordion type="single" collapsible>
      <AccordionItem value={props.team.id}>
        <Button
          size={'sm'}
          type="button"
          variant={'ghost'}
          className="flex h-[30px] w-full flex-row justify-start px-[5px] pl-[25px] text-left"
        >
          <AccordionTrigger className="w-full flex-none">
            <div className="flex w-full flex-row items-center gap-2">
              <EmojiIcon
                tag={props.team.icon as IEmojiIcon}
                attrs={{
                  width: 18,
                  height: 18,
                  color: props.team.color,
                }}
              />
              <Label className="max-w-[115px] flex-none truncate pr-[5px]">
                {props.team.name}
              </Label>
            </div>
          </AccordionTrigger>
        </Button>
        <AccordionContent>
          <div className="flex w-full flex-col">
            {MenuTeams.map((item, index) => {
              return (
                <Item
                  key={index}
                  title={item.title}
                  path={`${props.team.identifier}/${item.path}`}
                  onNavigation={onNavigation}
                />
              );
            })}
          </div>
        </AccordionContent>
      </AccordionItem>
    </Accordion>
  );
};

const TeamList = (props: { onNavigate: (path: string) => void }) => {
  const { selected, teams } = useContext(WorkspaceContext);
  const onNavigation = (path: string) => {
    props.onNavigate(path);
  };

  return (
    <div className="flex w-full flex-col">
      {teams.map((team) => (
        <Team key={team.id} team={team} onNavigate={onNavigation} />
      ))}

      <div className="flex w-full grow">
        <Button
          size={'sm'}
          type="button"
          variant="ghost"
          className="h-[30px] w-full pl-[28px] pr-[8px]"
          onClick={() => onNavigation(`/${selected?.url}/settings/new-team`)}
        >
          <div className="flex w-full flex-row items-center gap-2">
            <Plus width={16} height={16} />
            <Label className="pl-[2px]">Add team</Label>
          </div>
        </Button>
      </div>
    </div>
  );
};

export default TeamList;
