import { createContext } from 'react';

type IProjectContentHistoryContext = {
  isOpen: boolean;
  projectId: string | undefined;
  showModal: (props: {
    isOpen: boolean;
    projectId?: string | undefined;
  }) => void;
};

const ProjectContentHistoryContext =
  createContext<IProjectContentHistoryContext>({
    isOpen: false,
    projectId: undefined,
    showModal: () => undefined,
  });

export default ProjectContentHistoryContext;
