import { useContext, useEffect, useState } from 'react';

import classNames from 'classnames';
import { useNavigate, useParams } from 'react-router-dom';
import TimeAgo from 'react-timeago';

import { Label } from '@/components/Label';
import {
  ResizableHandle,
  ResizablePanel,
  ResizablePanelGroup,
} from '@/components/Resizeable';
import { ScrollArea, ScrollBar } from '@/components/ScrollArea';
import { Table, TableBody, TableCell, TableRow } from '@/components/Table';
import { UserAvatar } from '@/components/UserAvatar';
import { useErrorHandler } from '@/hooks/useToasterHandler';
import InboxEmpty from '@/icons/inbox-empty';
import { TopNavigationBody } from '@/providers/main/components/TopNavigationBody';
import WorkspaceContext from '@/providers/workspace/WorkspaceContext';
import { MemoizedRenderDocument } from '@/renders/RenderDocument';
import { MemoizedRenderIssue } from '@/renders/RenderIssue';
import { MemoizedRenderMember } from '@/renders/RenderMember';
import { MemoizedRenderStatus } from '@/renders/RenderStatus';
import { MemoizedRenderTeam } from '@/renders/RenderTeam';
import { NotificationType, PushEventType } from '@/types/gql/generated';
import {
  NotificationFragment,
  useMarkReadNotificationMutation,
} from '@/types/gql/generated';
import { CalcPercentByWidth } from '@/utils/calc-percent-screen';

import { mayBeMatch } from '../../utils/custom-matcher';
import DocumentDetailPage from '../document';
import { MemoizedRenderStatusIcon } from '../issue/components/Properties/StatusButton';
import IssueDetailPage from '../issue/detail';

const EmptyContent = () => {
  return (
    <div className="flex w-full flex-col items-center justify-center gap-1">
      <div className="py-4">
        <InboxEmpty />
      </div>
      <Label>Inbox</Label>
      <Label className="opacity-50">No unread notifications</Label>
    </div>
  );
};
const Header = () => {
  return (
    <TopNavigationBody>
      <div className="flex h-[38px] w-full flex-row items-center gap-4 border-b-[1px] border-solid border-secondary px-[24px]">
        <div>
          <Label className="font-semibold">Inbox</Label>
        </div>
        <div className="flex flex-row items-center gap-4"></div>
      </div>
    </TopNavigationBody>
  );
};

const ItemIssueInbox = (props: {
  notification: NotificationFragment;
  onSelected: (props: {
    notification: NotificationFragment;
    path: string;
  }) => void;
}) => {
  return (
    <MemoizedRenderIssue
      id={props.notification.map_id}
      renderItem={(issue) =>
        issue && (
          <MemoizedRenderTeam
            id={issue.team_id ?? undefined}
            renderItem={(team) =>
              team && (
                <TableRow
                  onClick={() =>
                    props.onSelected({
                      notification: props.notification,
                      // path: `${team.identifier}-${issue.code}#${props.notification.activity_id}`,
                      path: `${props.notification.id}#${props.notification.activity_id}`,
                    })
                  }
                >
                  <TableCell width={60}>
                    <MemoizedRenderMember
                      id={props.notification.created_by}
                      renderItem={(byMember) => (
                        <div className="flex flex-row items-center gap-2">
                          <UserAvatar
                            size={'md'}
                            fallback={byMember?.username}
                          />
                          <div className="grow">
                            <MemoizedRenderStatus
                              id={issue?.workflow_id ?? undefined}
                              renderItem={(status) => (
                                <div className="relative w-full overflow-hidden">
                                  <div className="flex grow flex-col pr-[50px]">
                                    <Label
                                      className={classNames(
                                        'line-clamp-1 overflow-hidden',
                                        {
                                          'opacity-50':
                                            props.notification.mark_read,
                                        },
                                      )}
                                    >
                                      {`${team?.identifier}-${issue?.code}  ${issue?.title}`}
                                    </Label>
                                    <div
                                      className={classNames(
                                        'line-clamp-1 overflow-hidden',
                                        {
                                          ' opacity-50':
                                            props.notification.mark_read,
                                        },
                                      )}
                                    >
                                      {props.notification.event &&
                                        mayBeMatch([props.notification.event])({
                                          [PushEventType.NotificationNewComment]:
                                            () => (
                                              <>
                                                <Label className="text-xs">
                                                  {`New comment from ${byMember?.username}`}
                                                </Label>
                                              </>
                                            ),
                                          [PushEventType.NotificationReplyComment]:
                                            () => (
                                              <>
                                                <Label className="text-xs">
                                                  {`New reply from ${byMember?.username}`}
                                                </Label>
                                              </>
                                            ),
                                          [PushEventType.NotificationUpdateWorkflow]:
                                            () => (
                                              <>
                                                <Label className="text-xs">
                                                  {`Marked ${status?.name} by ${byMember?.username}`}
                                                </Label>
                                              </>
                                            ),
                                          [PushEventType.NotificationNewAssignee]:
                                            () => (
                                              <>
                                                <MemoizedRenderMember
                                                  id={
                                                    props.notification.member_id
                                                  }
                                                  renderItem={(toMember) => (
                                                    <Label className="text-xs">
                                                      {`Assignee to ${toMember?.username} by ${byMember?.username}`}
                                                    </Label>
                                                  )}
                                                />
                                              </>
                                            ),
                                          orElse: () => <></>,
                                        })}
                                    </div>
                                  </div>
                                  {issue?.workflow_id && (
                                    <div className="absolute right-0 top-0 flex w-[50px] flex-none flex-col items-center">
                                      <MemoizedRenderStatusIcon
                                        type={status?.type}
                                        color={status?.color}
                                        processing={status?.processing}
                                      />
                                      <Label className="line-clamp-1">
                                        <TimeAgo
                                          live={false}
                                          date={issue.created_at}
                                          formatter={(value, unit) => {
                                            let getUnit = '';
                                            if (unit === 'hour') {
                                              getUnit = `h`;
                                            }
                                            if (unit === 'minute') {
                                              getUnit = `min`;
                                            }
                                            if (unit === 'second') {
                                              getUnit = `sec`;
                                            }
                                            if (unit === 'day') {
                                              getUnit = `d`;
                                            }
                                            if (unit === 'week') {
                                              getUnit = `w`;
                                            }
                                            if (unit === 'month') {
                                              getUnit = `m`;
                                            }
                                            if (unit === 'year') {
                                              getUnit = `y`;
                                            }

                                            return `${value}${getUnit}`;
                                          }}
                                        ></TimeAgo>
                                      </Label>
                                    </div>
                                  )}
                                </div>
                              )}
                            />
                          </div>
                        </div>
                      )}
                    />
                  </TableCell>
                </TableRow>
              )
            }
          />
        )
      }
    />
  );
};

const ItemDocumentInbox = (props: {
  notification: NotificationFragment;
  onSelected: (props: {
    notification: NotificationFragment;
    path: string;
  }) => void;
}) => {
  return (
    <MemoizedRenderDocument
      id={props.notification.map_id}
      renderItem={(document) =>
        document && (
          <TableRow
            onClick={() =>
              props.onSelected({
                notification: props.notification,
                path: `${props.notification.id}`,
              })
            }
          >
            <TableCell width={60}>
              <MemoizedRenderMember
                id={props.notification.created_by}
                renderItem={(byMember) => (
                  <div className="flex flex-row items-center gap-2">
                    <UserAvatar size={'md'} fallback={byMember?.username} />
                    <div className="grow">
                      <div className="relative w-full overflow-hidden">
                        <div className="flex grow flex-col pr-[50px]">
                          <Label
                            className={classNames(
                              'line-clamp-1 overflow-hidden',
                              {
                                'opacity-50': props.notification.mark_read,
                              },
                            )}
                          >
                            {`${document?.title}`}
                          </Label>
                          <div
                            className={classNames(
                              'line-clamp-1 overflow-hidden',
                              {
                                'opacity-50': props.notification.mark_read,
                              },
                            )}
                          >
                            {props.notification.event &&
                              mayBeMatch([props.notification.event])({
                                [PushEventType.NotificationDocumentUpdated]:
                                  () => (
                                    <>
                                      <Label className="text-xs">
                                        {`New update from ${byMember?.username}`}
                                      </Label>
                                    </>
                                  ),
                                orElse: () => <></>,
                              })}
                          </div>
                        </div>
                        <div className="absolute right-0 top-0 flex w-[50px] flex-none flex-col items-center">
                          <Label className="line-clamp-1">
                            <TimeAgo
                              live={false}
                              date={document.created_at}
                              formatter={(value, unit) => {
                                let getUnit = '';
                                if (unit === 'hour') {
                                  getUnit = `h`;
                                }
                                if (unit === 'minute') {
                                  getUnit = `min`;
                                }
                                if (unit === 'second') {
                                  getUnit = `sec`;
                                }
                                if (unit === 'day') {
                                  getUnit = `d`;
                                }
                                if (unit === 'month') {
                                  getUnit = `m`;
                                }
                                if (unit === 'year') {
                                  getUnit = `y`;
                                }
                                return `${value}${getUnit}`;
                              }}
                            ></TimeAgo>
                          </Label>
                        </div>
                      </div>
                    </div>
                  </div>
                )}
              />
            </TableCell>
          </TableRow>
        )
      }
    />
  );
};

const InboxMessage = (props: {
  notifications: NotificationFragment[];
  onSelected: (props: {
    notification: NotificationFragment;
    path: string;
  }) => void;
}) => {
  return (
    <div className="flex w-full flex-col">
      <Table>
        <TableBody>
          {props.notifications.map((item) =>
            mayBeMatch([item.type])({
              [NotificationType.Issue]: () => (
                <ItemIssueInbox
                  key={item.id}
                  notification={item}
                  onSelected={props.onSelected}
                />
              ),
              [NotificationType.Document]: () => (
                <ItemDocumentInbox
                  key={item.id}
                  notification={item}
                  onSelected={props.onSelected}
                />
              ),
              orElse: () => <></>,
            }),
          )}
        </TableBody>
      </Table>
    </div>
  );
};

const InboxPage = () => {
  const navigate = useNavigate();
  const params = useParams();
  const { showErrorToast } = useErrorHandler();
  const { notifications, selected } = useContext(WorkspaceContext);
  const [selectedNotification, setSelectedNotification] =
    useState<NotificationFragment>();
  const widthPercent = CalcPercentByWidth({ width: 300 });

  const markRead = useMarkReadNotificationMutation({
    onMutate: () => {},
    onError: (res) => {
      showErrorToast({ error: res });
    },
    onSuccess: () => {},
    onSettled: () => {},
  });

  useEffect(() => {
    const getNotification = notifications.find((item) => item.id === params.id);
    if (!getNotification) return;
    setSelectedNotification(getNotification);
  }, [params, notifications]);

  return (
    <>
      <ResizablePanelGroup direction="horizontal">
        <ResizablePanel defaultSize={widthPercent}>
          <div className="flex h-full w-full flex-none flex-col">
            <Header />
            <ScrollArea type="auto" style={{ maxHeight: '100%' }}>
              <div className="h-full w-full">
                <InboxMessage
                  notifications={notifications}
                  onSelected={(value) => {
                    navigate(`/${selected?.url}/inbox/${value.path}`);
                    markRead.mutate({
                      id: value.notification.id,
                    });
                    setSelectedNotification(value.notification);
                  }}
                />
              </div>
              <ScrollBar orientation="vertical" />
            </ScrollArea>
          </div>
        </ResizablePanel>
        <ResizableHandle withHandle className="hidden md:flex" />
        <ResizablePanel
          defaultSize={100 - widthPercent}
          className="hidden md:block"
        >
          <div className="flex h-full grow">
            {selectedNotification?.type &&
              mayBeMatch([selectedNotification?.type])({
                [NotificationType.Issue]: () => (
                  <IssueDetailPage id={selectedNotification.map_id} />
                ),
                [NotificationType.Document]: () => (
                  <DocumentDetailPage id={selectedNotification.map_id} />
                ),
                orElse: () => <></>,
              })}

            {!selectedNotification && <EmptyContent />}
          </div>
        </ResizablePanel>
      </ResizablePanelGroup>
    </>
  );
};

export default InboxPage;
