import { toast } from 'sonner';

import { toErrorType } from '@/services/core/error-handler';
import CustomDateTime from '@/utils/format-datetime';

export const useSuccessHandler = () => {
  function showSuccessToast(props: {
    title: string;
    description?: any;
    actions?: any;
  }) {
    const dateString = new Date().toLocaleDateString();
    toast.success(props.title, {
      description:
        props.description ??
        `${CustomDateTime.convertStringToCommon(dateString)}`,
      action: props.actions,
    });
  }

  return { showSuccessToast };
};

export const useErrorHandler = () => {
  function showErrorToast(props: {
    error: Error;
    option?: {
      onConfirm?: () => void;
      onClose?: () => void;
    };
  }) {
    const getError = toErrorType(props.error);
    toast.error(getError.kind, {
      description: getError.message,
    });
  }

  return { showErrorToast };
};

export const useWarningHandler = () => {
  function showWarningToast(props: {
    title: string;
    description: string;
    option?: {
      onConfirm?: () => void;
      onClose?: () => void;
    };
  }) {
    toast.warning(props.title, {
      description: props.description,
    });
  }

  return { showWarningToast };
};
