import { parse, ASTNode, Kind, OperationDefinitionNode } from 'graphql';
import { request } from 'graphql-request';

import AppEnv from '@/services/core/app-env';
import LocalStorage from '@/services/core/local-storage';

const isOperationDefinition = (def: ASTNode): def is OperationDefinitionNode =>
  def.kind === Kind.OPERATION_DEFINITION;

export const useFetchData = <TData, TVariables>(
  query: string,
): ((variables?: TVariables) => Promise<TData>) => {
  return async (variables?: TVariables) => {
    const token = LocalStorage.getToken();

    const document = parse(query);
    const queryName = document.definitions.find(isOperationDefinition)?.name
      ?.value;

    const headers: any = {};

    if (token) {
      headers.Authorization = `Bearer ${token}`;
    }

    return request({
      url: `${AppEnv.get().api}?${queryName}`,
      document: document,
      variables: variables as any,
      requestHeaders: headers,
    });
  };
};
