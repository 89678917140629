import React from 'react';

import { Outlet, RouteObject } from 'react-router-dom';

import DocumentServicesWrapper from '@/providers/document';
import DocumentContentHistoryProvider from '@/providers/document/DocumentContentHistory/DocumentContentHistoryProvider';
import DocumentDeleteProvider from '@/providers/document/DocumentDelete/DocumentDeleteProvider';
import IssueServicesWrapper from '@/providers/issue';
import IssueDeleteProvider from '@/providers/issue/IssueDelete/IssueDeleteProvider';
import MainWrapper from '@/providers/main';
import ProjectServicesWrapper from '@/providers/project';
import ProjectContentHistoryProvider from '@/providers/project/ProjectContentHistory/ProjectContentHistoryProvider';
import ProjectDeleteProvider from '@/providers/project/ProjectDelete/ProjectDeleteProvider';
import SettingsWrapper from '@/providers/settings';
import SubscriptionWrapper from '@/providers/subscriptions';
import WorkspaceWrapper from '@/providers/workspace';

import AuthIssueRoute from './paths/issue';
import AuthSettingsRoute from './paths/settings';
import AuthSettingsProfileRoute from './paths/settings-profile';
import AuthSettingsTeamRoute from './paths/settings-team';
import StartupRoute from './paths/startup';
import IssueContentHistoryProvider from '../providers/issue/IssueContentHistory/IssueContentHistoryProvider';
import { compose } from '../utils/compose';

const AuthRoutes: RouteObject[] = [
  {
    path: '/',
    element: compose(
      <SubscriptionWrapper />,
      <WorkspaceWrapper />,
      <IssueServicesWrapper />,
      <IssueContentHistoryProvider />,
      <IssueDeleteProvider />,
      <DocumentServicesWrapper />,
      <DocumentDeleteProvider />,
      <DocumentContentHistoryProvider />,
      <ProjectServicesWrapper />,
      <ProjectDeleteProvider />,
      <ProjectContentHistoryProvider />,
      <MainWrapper />,
      <Outlet />,
    ),
    children: [...AuthIssueRoute],
  },
  {
    element: compose(
      <SubscriptionWrapper />,
      <WorkspaceWrapper />,
      <SettingsWrapper />,
      <Outlet />,
    ),
    children: [
      ...AuthSettingsRoute,
      ...AuthSettingsTeamRoute,
      ...AuthSettingsProfileRoute,
    ],
  },
  {
    element: compose(<Outlet />),
    children: [...StartupRoute],
  },
];

export default AuthRoutes;
