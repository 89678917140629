import { useNavigate } from 'react-router-dom';

import { Drawer, DrawerContent } from '@/components/Drawer';
import {
  ResizablePanelGroup,
  ResizablePanel,
  ResizableHandle,
} from '@/components/Resizeable';
import { ScrollArea, ScrollBar } from '@/components/ScrollArea';
import { CalcPercentByWidth } from '@/utils/calc-percent-screen';

import SettingSidebar from './components/SettingSidebar';
import { ToggleMenuSettingSidebar } from './components/ToggleMenuSidebar';
import SettingContext from './SettingContext';
import useSetting from './useSetting';

const SettingsWrapper = (props: { children?: React.ReactNode }) => {
  const navigate = useNavigate();
  const widthPercent = CalcPercentByWidth({ width: 250 });
  const { isOpenSidebar, event } = useSetting();

  const onNavigate = (path: string) => {
    navigate(path);
    event.onToggleSidebar(false);
  };

  return (
    <SettingContext.Provider
      value={{ toggleMenuSidebar: (value) => event.onToggleSidebar(value) }}
    >
      <div className="flex h-full max-h-full min-h-full w-full flex-row">
        <ResizablePanelGroup direction="horizontal">
          <ResizablePanel
            defaultSize={widthPercent}
            className="hidden h-full lg:block"
          >
            <SettingSidebar onNavigate={onNavigate} />
          </ResizablePanel>
          <ResizableHandle withHandle className="hidden lg:flex" />
          <ResizablePanel defaultSize={100 - widthPercent}>
            <div className="flex h-full w-full grow flex-col overflow-hidden">
              <div>
                <ToggleMenuSettingSidebar />
              </div>
              <ScrollArea style={{ height: '100%', width: '100%' }}>
                <div className="w-full p-2">
                  <div className="rounded border-[1px] border-solid border-secondary py-[10px] lg:py-[50px]">
                    <div className="m-auto w-full max-w-[640px] p-[10px]">
                      {props.children}
                    </div>
                  </div>
                </div>
                <ScrollBar orientation="vertical" />
              </ScrollArea>
            </div>
          </ResizablePanel>
        </ResizablePanelGroup>
        <Drawer
          direction="left"
          open={isOpenSidebar}
          onOpenChange={(value) => event.onToggleSidebar(value)}
        >
          <DrawerContent>
            <SettingSidebar onNavigate={onNavigate} />
          </DrawerContent>
        </Drawer>
      </div>
    </SettingContext.Provider>
  );
};

export default SettingsWrapper;
