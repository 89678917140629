import React, { useContext } from 'react';

import WorkspaceContext from '@/providers/workspace/WorkspaceContext';
import { ProjectFragment } from '@/types/gql/generated';

const RenderProject = (props: {
  id?: string;
  code?: string;
  renderItem: (value: ProjectFragment | undefined) => React.ReactNode;
}) => {
  const { projects } = useContext(WorkspaceContext);

  if (props.id) {
    return (
      <>{props.renderItem(projects.find((item) => item.id === props.id))}</>
    );
  }
  if (props.code) {
    return (
      <>{props.renderItem(projects.find((item) => item.code === props.code))}</>
    );
  }

  return <>{props.renderItem(undefined)}</>;
};

const MemoizedRenderProject = React.memo(RenderProject);
export { MemoizedRenderProject };
