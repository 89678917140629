import React, { useContext } from 'react';

import AuthenticatedUserContext from '@/providers/auth/AuthenticatedUserContext';
import WorkspaceContext from '@/providers/workspace/WorkspaceContext';
import { SubscriberFragment } from '@/types/gql/generated';

const RenderSubscriber = (props: {
  map_id: string;
  type: string;
  renderItem: (value: SubscriberFragment | undefined) => React.ReactNode;
}) => {
  const { user } = useContext(AuthenticatedUserContext);
  const { subscribers } = useContext(WorkspaceContext);

  if (props.map_id) {
    return (
      <>
        {props.renderItem(
          subscribers.find(
            (item) =>
              item.member_id === user?.id &&
              item.map_id === props.map_id &&
              item.type === props.type,
          ),
        )}
      </>
    );
  }

  return <>{props.renderItem(undefined)}</>;
};

const MemoizedRenderSubscriber = React.memo(RenderSubscriber);
export { MemoizedRenderSubscriber };
