import React, { ReactNode } from 'react';

// eslint-disable-next-line import/no-extraneous-dependencies
import { QueryClient, QueryClientProvider } from '@tanstack/react-query';

import { toErrorType } from '@/services/core/error-handler';
import { UnauthorizedError } from '@/services/core/error-type';

const ReactQueryClientProvider = ({ children }: { children?: ReactNode }) => {
  return (
    <QueryClientProvider
      client={
        new QueryClient({
          defaultOptions: {
            queries: {
              throwOnError: (error) => {
                const getError = toErrorType(error);

                if (getError instanceof UnauthorizedError) {
                  window.location.href = `${window.location.origin}/login`;
                }
                return false;
              },
              gcTime: 1000 * 60 * 30,
            },
            mutations: {
              throwOnError: (error) => {
                const getError = toErrorType(error);
                if (getError instanceof UnauthorizedError) {
                  // window.location.href = `${window.location.origin}/logout?redirect=${window.location.href}`;
                }
                return false;
              },
            },
          },
        })
      }
    >
      {children}
    </QueryClientProvider>
  );
};

export default ReactQueryClientProvider;
