/* eslint-disable import/no-extraneous-dependencies */

import React, { useContext, useEffect, useRef, useState } from 'react';

import { Label } from '@radix-ui/react-context-menu';
import { PlateEditor } from '@udecode/plate-common';
import { insertImage } from '@udecode/plate-media';
import { ReplyIcon } from 'lucide-react';

import { AnimationFadeIn } from '@/components/Animations';
import { Button } from '@/components/Button';
import { Card } from '@/components/Card';
import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuItem,
  DropdownMenuSeparator,
  DropdownMenuTrigger,
} from '@/components/DropdownMenu';
import EditorPlateComponent from '@/components/Editor/EditorPlateComponent';
import { Separator } from '@/components/Separator';
import { UserAvatar } from '@/components/UserAvatar';
import { useErrorHandler, useSuccessHandler } from '@/hooks/useToasterHandler';
import useUploadAttachment from '@/hooks/useUploadAttachment';
import { useWarningHandler } from '@/hooks/useWarningHandler';
import { SpinnerDotIcon, AttachmentIcon } from '@/icons/core';
import { MoreHorizontalIcon } from '@/icons/index';
import { initialValueEditor } from '@/pages/cores';
import AuthenticatedUserContext from '@/providers/auth/AuthenticatedUserContext';
import { MemoizedRenderMember } from '@/renders/RenderMember';
import {
  CommentFragment,
  useCreateCommentMutation,
  useRemoveCommentMutation,
  useUpdateCommentMutation,
} from '@/types/gql/generated';
import PlateUtils from '@/utils/plate-utils';

import IssueDetailContext from '../../../providers/IssueDetailContext';
import { FormatTimeAgo } from '../utils';

type ICommentMoreButton = 'edit' | 'resolve' | 'delete';
const MoreButton = (props: {
  comment: CommentFragment;
  children: React.ReactNode;
  onSelected: (action: ICommentMoreButton) => void;
}) => {
  const { user } = useContext(AuthenticatedUserContext);
  const isOfUser = props.comment.created_by === user?.id;
  const [open, setOpen] = useState<boolean>(false);
  return (
    <DropdownMenu open={open} onOpenChange={setOpen}>
      <DropdownMenuTrigger>{props.children}</DropdownMenuTrigger>
      <DropdownMenuContent color="gray">
        {isOfUser && (
          <DropdownMenuItem onClick={() => props.onSelected('edit')}>
            Edit
          </DropdownMenuItem>
        )}

        {/* <DropdownMenuItem onClick={() => props.onSelected('resolve')}>
          Resolve thread
        </DropdownMenuItem>
        <DropdownMenuItem>Copy link to comment</DropdownMenuItem>
        <DropdownMenuSeparator />
        <DropdownMenuItem>New issue from comment</DropdownMenuItem>
        <DropdownMenuItem>New sub-issue issue from comment</DropdownMenuItem> */}

        {isOfUser && (
          <>
            <DropdownMenuSeparator />
            <DropdownMenuItem onClick={() => props.onSelected('delete')}>
              Delete
            </DropdownMenuItem>
          </>
        )}
      </DropdownMenuContent>
    </DropdownMenu>
  );
};

const ReplyCommentComponent = React.memo(
  (props: { comment: CommentFragment; onClose?: () => void }) => {
    const { showErrorToast } = useErrorHandler();
    const { user } = useContext(AuthenticatedUserContext);
    const editorContentRef = useRef<PlateEditor | null>(null);
    const { showWarningToast } = useWarningHandler();
    const replyComment = useCreateCommentMutation({
      onMutate: () => {},
      onError: (res) => {
        showErrorToast({ error: res });
      },
      onSuccess: () => {
        props.onClose?.();
        editorContentRef.current?.reset();
      },
      onSettled: () => {},
    });

    const { onUpload, loading } = useUploadAttachment({
      type: 'comment',
      workspace_id: props.comment.workspace_id,
      onUploadSuccess: (path) => insertImage(editorContentRef.current, path),
    });

    const onSubmit = () => {
      const getContent = PlateUtils.getContentFromTElement(
        editorContentRef.current?.children,
      );

      if (!getContent || getContent?.length === 0) {
        showWarningToast({
          title: 'Reply is required',
          description: 'Please add a comment before submitting.',
        });
        return;
      }
      replyComment.mutate({
        createInput: {
          content: getContent,
          issue_id: props.comment.issue_id,
          comment_id: props.comment.id,
          workspace_id: props.comment.workspace_id,
        },
      });
    };

    return (
      <>
        <Separator />
        <div className="relative flex flex-row items-start gap-4 p-2">
          <div className="flex-none">
            <MemoizedRenderMember
              id={user?.id}
              renderItem={(member) => (
                <UserAvatar size={'sm'} fallback={member?.username} />
              )}
            />
          </div>
          <div className="grow">
            <EditorPlateComponent
              editorRef={editorContentRef}
              loading={false}
              readOnly={false}
              placeholder="Leave a reply..."
              className="min-h-0 w-full"
            />
          </div>
          <div className="absolute bottom-[4px] right-0 flex flex-row items-center gap-2">
            <Button
              type="button"
              size={'sm'}
              variant={'ghost'}
              disabled={loading}
              onClick={() => onUpload()}
            >
              <div className="flex flex-row items-center gap-2">
                {loading && <SpinnerDotIcon width={18} />}
                <AttachmentIcon width={16} height={16} />
              </div>
            </Button>
            <Button
              type="button"
              variant={'ghost'}
              onClick={() => onSubmit()}
              size={'sm'}
            >
              Reply
            </Button>
          </div>
        </div>
      </>
    );
  },
);

const CommentDetailComponent = (props: {
  comment: CommentFragment;
  created_by: string;
  parent: boolean;
  onReply?: () => void;
}) => {
  const { showWarningToast } = useWarningHandler();
  const { showSuccessToast } = useSuccessHandler();
  const { showErrorToast } = useErrorHandler();
  const editorContentRef = useRef<PlateEditor | null>(null);

  const [readOnly, setReadOnly] = useState<boolean>(true);
  const { onUpload, loading } = useUploadAttachment({
    type: 'comment',
    workspace_id: props.comment.workspace_id,
    onUploadSuccess: (path) => insertImage(editorContentRef.current, path),
  });

  const updateComment = useUpdateCommentMutation({
    onMutate: () => {},
    onError: (res) => {
      showErrorToast({ error: res });
    },
    onSuccess: () => {
      setReadOnly(true);
    },
    onSettled: () => {},
  });

  const removeComment = useRemoveCommentMutation({
    onMutate: () => {},
    onError: (res) => {
      showErrorToast({ error: res });
    },
    onSuccess: () => {
      showSuccessToast({ title: 'Comment has been deleted' });
    },
    onSettled: () => {},
  });

  const onSubmit = () => {
    const getContent = PlateUtils.getContentFromTElement(
      editorContentRef.current?.children,
    );

    if (!getContent || getContent?.length === 0) {
      showWarningToast({
        title: 'Comment is required',
        description: 'Please add a comment before submitting.',
      });
      return;
    }

    updateComment.mutate({
      updateInput: {
        id: props.comment.id,
        content: getContent,
        issue_id: props.comment.issue_id,
      },
    });
  };

  const onSelectAction = (data: ICommentMoreButton) => {
    switch (data) {
      case 'edit':
        setReadOnly(false);
        break;
      case 'delete':
        removeComment.mutate({
          id: props.comment.id,
        });
        break;
      default:
        break;
    }
  };

  useEffect(() => {
    // Editor Content
    if (!editorContentRef.current) return;
    if (props.comment?.content) {
      editorContentRef.current.children = PlateUtils.getTElementFromContent(
        props.comment.content,
      );
      editorContentRef.current.onChange();
    } else {
      editorContentRef.current.children = initialValueEditor;
    }
  }, [props.comment]);

  return (
    <div className="relative px-1 py-2">
      <div className="flex flex-wrap gap-4">
        <MemoizedRenderMember
          id={props.created_by}
          renderItem={(createdBy) =>
            createdBy && (
              <div className="flex flex-row gap-5">
                <UserAvatar size={'sm'} fallback={createdBy.username} />
                <div className="items-cetner flex flex-wrap gap-2">
                  <Label className="text-xs">{createdBy.username}</Label>
                  <Label className="text-xs opacity-50">
                    <FormatTimeAgo date={props.comment.created_at} />
                  </Label>
                  {props.comment.created_at !== props.comment.updated_at && (
                    <Label className="text-xs opacity-50">(edited)</Label>
                  )}
                </div>
              </div>
            )
          }
        />
      </div>
      <div className="absolute right-0 top-0">
        <div className="flex">
          {props.parent && (
            <Button
              type={'button'}
              size={'icon'}
              variant={'ghost'}
              style={{ padding: 5 }}
              onClick={() => props.onReply?.()}
            >
              <ReplyIcon width={16} />
            </Button>
          )}

          <MoreButton comment={props.comment} onSelected={onSelectAction}>
            <Button
              type={'button'}
              size={'icon'}
              variant={'ghost'}
              style={{ padding: 5 }}
            >
              <MoreHorizontalIcon width={16} />
            </Button>
          </MoreButton>
        </div>
      </div>
      <div className="px-2 py-4">
        <EditorPlateComponent
          id={props.comment.updated_at}
          editorRef={editorContentRef}
          loading={false}
          readOnly={readOnly}
          placeholder="Leave comment ..."
          className="min-h-0"
        />
      </div>
      {!readOnly && (
        <AnimationFadeIn>
          <div className="flex w-full flex-row items-center justify-end gap-2 text-right">
            <Button
              type="button"
              size={'sm'}
              variant={'ghost'}
              disabled={loading}
              onClick={() => onUpload()}
            >
              <div className="flex flex-row items-center gap-2">
                {loading && <SpinnerDotIcon width={18} />}
                <AttachmentIcon width={16} height={16} />
              </div>
            </Button>

            <Button
              type="button"
              className=""
              variant={'ghost'}
              onClick={() => setReadOnly(true)}
            >
              Cancel
            </Button>

            <Button
              type="button"
              className=""
              variant={'secondary'}
              onClick={() => onSubmit()}
            >
              Save
            </Button>
          </div>
        </AnimationFadeIn>
      )}
    </div>
  );
};

const UpdateCommentComponent = (props: { comment: CommentFragment }) => {
  const [isReply, setIsReply] = useState<boolean>(false);
  const { comments } = useContext(IssueDetailContext);
  const getChildren = comments.filter(
    (item) => item.comment_id === props.comment.id,
  );

  return (
    <Card className="w-full p-0">
      <CommentDetailComponent
        comment={props.comment}
        created_by={props.comment.created_by}
        onReply={() => setIsReply(true)}
        parent={true}
      />
      {getChildren.map((item) => (
        <div key={item.id}>
          <Separator />
          <CommentDetailComponent
            comment={item}
            created_by={item.created_by}
            parent={false}
          />
        </div>
      ))}
      {isReply && (
        <AnimationFadeIn>
          <ReplyCommentComponent
            comment={props.comment}
            onClose={() => setIsReply(false)}
          />
        </AnimationFadeIn>
      )}
    </Card>
  );
};

export default UpdateCommentComponent;
