/* eslint-disable import/no-extraneous-dependencies */
import { useContext, useEffect, useRef, useState } from 'react';

import { PlateEditor, TElement } from '@udecode/plate-common';
import { InfoIcon } from 'lucide-react';
import { useNavigate, useParams } from 'react-router-dom';

import { AnimationFadeIn } from '@/components/Animations';
import { Button } from '@/components/Button';
import { Card } from '@/components/Card';
import EditorPlateComponent from '@/components/Editor/EditorPlateComponent';
import SingleLineEditorComponent from '@/components/Editor/TitleEditorComponent';
import { Label } from '@/components/Label';
import { ScrollArea, ScrollBar } from '@/components/ScrollArea';
import { Separator } from '@/components/Separator';
import { useErrorHandler } from '@/hooks/useToasterHandler';
import { PlusIcon } from '@/icons/core';
import WorkspaceContext from '@/providers/workspace/WorkspaceContext';
import {
  MemoizedRenderDocument,
  MemoizedRenderDocumentsByParentId,
} from '@/renders/RenderDocument';
import {
  IDocumentFragment,
  TeamFragment,
  useUpdateDocumentMutation,
} from '@/types/gql/generated';
import PlateUtils from '@/utils/plate-utils';

import DocumentAttachmentsComponent from './Attachments/DocumentAttachmentsComponent';
import { CreateSubDocumentDialog } from './CreateDocument/CreateSubDocumentDialog';
import HeaderDocumentComponent from './HeaderDocumentComponent';
import SubDocumentsComponent from './SubDocuments/SubDocumentsComponent';
import { initialValueEditor } from '../cores';

const DocumentNotFound = () => {
  const params = useParams();
  return (
    <div className="absolute left-1/2 top-1/2 -translate-x-1/2 -translate-y-1/2 text-center">
      <div className="flex flex-col gap-2">
        <h2 className="flex items-center justify-center gap-2 font-semibold">
          <InfoIcon />
          Document Not Found
        </h2>
        <p>There is no issue with the identifier {params.id}</p>
      </div>
    </div>
  );
};

const ParentIssue = (props: { document: IDocumentFragment }) => {
  const navigate = useNavigate();
  const { selected } = useContext(WorkspaceContext);
  return (
    <div className="flex w-full flex-row items-center gap-1">
      <Label className="flex-none text-xs opacity-50">Sub-document of</Label>
      <div
        className="flex cursor-pointer flex-row items-center gap-2  rounded-md p-1 hover:bg-secondary"
        onClick={() =>
          navigate(`/${selected?.url}/document/${props.document.id}`)
        }
      >
        <div className="flex flex-row items-center gap-2">
          <Label className="text-xs">{props.document.title}</Label>
        </div>
      </div>
    </div>
  );
};

const DocumentDetailComponent = (props: { document: IDocumentFragment }) => {
  const { teams } = useContext(WorkspaceContext);

  const { showErrorToast } = useErrorHandler();
  const editorTitleRef = useRef<PlateEditor | null>(null);
  const editorContentRef = useRef<PlateEditor | null>(null);
  const updateDocument = useUpdateDocumentMutation({
    onMutate: () => {},
    onError: (res) => {
      showErrorToast({ error: res });
    },
    onSuccess: () => {},
    onSettled: () => {},
  });
  const [isOpenCreateSubDocument, setIsOpenCreateSubDocument] =
    useState<Boolean>(false);
  const [team, setTeam] = useState<TeamFragment>();

  useEffect(() => {
    if (props.document) {
      if (!editorContentRef.current) return;
      if (props.document.description) {
        try {
          editorContentRef.current.children = JSON.parse(
            props.document.description,
          );
        } catch (error) {
          editorContentRef.current.children = [
            {
              id: 1,
              type: 'p',
              children: [{ text: props.document.description }],
            },
          ] as TElement[];
        }
        editorContentRef.current.onChange();
      } else {
        editorContentRef.current.children = initialValueEditor;
      }
      // Editor Title
      if (!editorTitleRef.current) return;
      if (props.document.title) {
        editorTitleRef.current.children = [
          { id: 1, type: 'p', children: [{ text: props.document.title }] },
        ] as TElement[];
        editorTitleRef.current.onChange();
      } else {
        editorTitleRef.current.children = initialValueEditor;
      }
    }
  }, [props.document]);

  useEffect(() => {
    setTeam(teams.find((item) => item.id === props.document.team_id));
  }, [teams]);

  return (
    <>
      {team && <HeaderDocumentComponent data={props.document} team={team} />}
      <Separator />
      <div className="relative z-10 flex h-full grow md:pt-0">
        <ScrollArea className="w-full" type="auto">
          <div className="m-auto w-full max-w-[900px] items-start px-[24px] pb-[70px]">
            <div className="flex w-full flex-col">
              <div className="mb-[10px] mt-10 flex ">
                <SingleLineEditorComponent
                  className="min-h-[10px] text-2xl"
                  id={props.document.id}
                  loading={false}
                  editorRef={editorTitleRef}
                  disabled={props.document.is_deleted}
                  placeholder={'Document title'}
                  onSubmit={() => {
                    const getData = PlateUtils.getTitleFromTElement(
                      editorTitleRef.current?.children,
                    );
                    updateDocument.mutate({
                      updateInput: {
                        id: props.document.id,
                        title: getData ?? undefined,
                      },
                    });
                  }}
                />
              </div>

              {props.document.document_id && (
                <MemoizedRenderDocument
                  id={props.document.document_id}
                  renderItem={(parent) =>
                    parent && <ParentIssue document={parent} />
                  }
                />
              )}

              <div className="my-[20px] flex w-full">
                <EditorPlateComponent
                  id={props.document.id}
                  loading={false}
                  disabled={props.document.is_deleted}
                  editorRef={editorContentRef}
                  placeholder="Add description ..."
                  onSubmit={() => {
                    const getContent = PlateUtils.getContentFromTElement(
                      editorContentRef.current?.children,
                    );
                    if (getContent === props.document.description) return;
                    updateDocument.mutate({
                      updateInput: {
                        id: props.document.id,
                        description: getContent,
                      },
                    });
                  }}
                />
              </div>
              <div className="lbl-md my-[20px] flex w-full text-primary">
                <DocumentAttachmentsComponent
                  disabled={props.document.is_deleted}
                  document_id={props.document.id}
                  workspace_id={props.document.workspace_id}
                />
              </div>
              <div className="flex w-full">
                <Button
                  type="button"
                  size={'sm'}
                  disabled={props.document.is_deleted}
                  variant={'ghost'}
                  onClick={() => setIsOpenCreateSubDocument(true)}
                >
                  <div className="flex flex-row items-center gap-2">
                    <PlusIcon width={14} height={14} />
                    <Label className="text-xs">Add sub-documents</Label>
                  </div>
                </Button>
              </div>
              <MemoizedRenderDocumentsByParentId
                id={props.document.id}
                renderItem={(children) =>
                  children.length > 0 && (
                    <SubDocumentsComponent data={children} />
                  )
                }
              />
              {isOpenCreateSubDocument && (
                <AnimationFadeIn>
                  <Card className="mt-[10px] p-0">
                    <CreateSubDocumentDialog
                      initDocumentId={props.document.id}
                      initTeamId={props.document.team_id}
                      onSuccess={() => setIsOpenCreateSubDocument(false)}
                      onCancel={() => setIsOpenCreateSubDocument(false)}
                    />
                  </Card>
                </AnimationFadeIn>
              )}
            </div>
          </div>
          <ScrollBar orientation="vertical" />
        </ScrollArea>
      </div>
    </>
  );
};

const DocumentDetailPage = (props?: { id?: string }) => {
  const params = useParams();

  return (
    <div className="relative flex w-full flex-col md:h-full md:overflow-hidden">
      <MemoizedRenderDocument
        id={props?.id ?? params.id}
        renderItem={(document) => {
          return (
            <>
              {document ? (
                <DocumentDetailComponent document={document} />
              ) : (
                <DocumentNotFound />
              )}
            </>
          );
        }}
      />
    </div>
  );
};

export default DocumentDetailPage;
