import React from 'react';

import { RouteObject } from 'react-router-dom';

import SettingLabelsPage from '../../pages/settings/labels';
import SettingMembersPage from '../../pages/settings/members';
import SettingOverviewPage from '../../pages/settings/overview/index';
import SettingProjectStatusPage from '../../pages/settings/projects';
import SettingNewTeamPage from '../../pages/settings/team/new-team';
import SettingWorkspacePage from '../../pages/settings/workspace';

const AuthSettingsRoute: RouteObject[] = [
  {
    path: ':workspace/settings',
    element: <SettingOverviewPage />,
  },
  {
    path: ':workspace/settings/overview',
    element: <SettingOverviewPage />,
  },
  {
    path: ':workspace/settings/workspace',
    element: <SettingWorkspacePage />,
  },
  {
    path: ':workspace/settings/labels',
    element: <SettingLabelsPage />,
  },
  {
    path: ':workspace/settings/projects',
    element: <SettingProjectStatusPage />,
  },
  {
    path: ':workspace/settings/members',
    element: <SettingMembersPage />,
  },
  {
    path: ':workspace/settings/new-team',
    element: <SettingNewTeamPage />,
  },
];

export default AuthSettingsRoute;
