import React, { useContext, useState } from 'react';

import { Button } from '@/components/Button';
import { Checkbox } from '@/components/Checkbox';
import { Input } from '@/components/Input';
import { Label } from '@/components/Label';
import { Popover, PopoverContent, PopoverTrigger } from '@/components/Popover';
import { ScrollArea } from '@/components/ScrollArea';
import { Separator } from '@/components/Separator';
import { UserAvatar } from '@/components/UserAvatar';
import WorkspaceContext from '@/providers/workspace/WorkspaceContext';
import { UserFragment } from '@/types/gql/generated';

const OrderButton = (props: {
  data: UserFragment[];
  selected: string[];
  onSelected: (value: string[]) => void;
}) => {
  return (
    <div className="flex w-full flex-col">
      {props.data.map((item) => (
        <Button
          key={item.id}
          type="button"
          variant={props.selected.includes(item.id) ? 'secondary' : 'ghost'}
          className="flex h-10 w-full flex-row items-center gap-2 px-[5px] text-left"
        >
          <Checkbox
            checked={props.selected.includes(item.id)}
            onCheckedChange={(checked) => {
              return checked
                ? props.onSelected([...props.selected, item.id])
                : props.onSelected(
                    props.selected.filter((value) => value !== item.id),
                  );
            }}
          />
          <UserAvatar size="sm" url={item.image} fallback={item.username} />
          <div className="grow">
            <Label>{item.username}</Label>
          </div>
        </Button>
      ))}
    </div>
  );
};

const ItemList = (props: {
  list: UserFragment[];
  selected: string[];
  onSelected: (value: string[]) => void;
}) => {
  return props.list.length > 0 ? (
    <div>
      <Separator className="my-[5px] w-full" orientation="horizontal" />
      <ScrollArea className="h-full max-h-[300px] w-full">
        <OrderButton
          data={props.list}
          onSelected={props.onSelected}
          selected={props.selected}
        />
      </ScrollArea>
    </div>
  ) : (
    <></>
  );
};

const MembersToButton = (props: {
  title?: string;
  teamIds: string[];
  selected: string[];
  placeholder?: string;
  onSelected: (value: string[]) => void;
  renderItem: (value: UserFragment[]) => React.ReactNode;
}) => {
  const { getDataByTeam } = useContext(WorkspaceContext);

  const members = props.teamIds.flatMap((item) => {
    const data = getDataByTeam && getDataByTeam({ team_id: item });
    return data?.members ?? [];
  });
  const [filter, setFilter] = useState<string>('');
  const [isOpen, setIsOpen] = useState<boolean>(false);
  return (
    <Popover modal={true} open={isOpen} onOpenChange={setIsOpen}>
      <PopoverTrigger asChild className="md:w-full">
        {props.renderItem(
          members.filter((item) => props.selected.includes(item.id)),
        )}
      </PopoverTrigger>

      <PopoverContent className="p-[5px]">
        {props.title && (
          <div className="w-full px-[8px] pt-[4px]">
            <Label className="line-clamp-1 max-w-[250px]">{props.title}</Label>
          </div>
        )}
        <div className="flex w-full items-center p-[8px]">
          <Input
            className="h-[30px] w-full border-none p-0 text-lg shadow-none"
            placeholder={props.placeholder ?? 'Change member...'}
            value={filter}
            autoFocus
            onChange={(e) => setFilter(e.currentTarget.value)}
          ></Input>
        </div>
        <ItemList
          list={members.filter((item) => item.email.includes(filter)) ?? []}
          onSelected={(data) => {
            props.onSelected(data);
          }}
          selected={props.selected}
        />
      </PopoverContent>
    </Popover>
  );
};
const MemoizedMembersToButton = React.memo(MembersToButton);
export default MemoizedMembersToButton;
