import { useContext } from 'react';

import { yupResolver } from '@hookform/resolvers/yup';
import { useForm } from 'react-hook-form';
import { useParams } from 'react-router-dom';
import * as yup from 'yup';

import { Button } from '@/components/Button';
import { Form, FormField } from '@/components/Form';
import { Input } from '@/components/Input';
import { Label } from '@/components/Label';
import { LabelError } from '@/components/LabelError';
import { useErrorHandler } from '@/hooks/useToasterHandler';
import EmojiIcon, { EmojiIconList, IEmojiIcon } from '@/icons/emoji';
import WorkspaceContext from '@/providers/workspace/WorkspaceContext';
import { useUpdateTeamMutation } from '@/types/gql/generated';

import HeaderSetting from '../../components/HeaderSetting';
import IconPicker from '../../components/IconPicker';

export type IUpdateTeam = {
  name: string;
  icon: IEmojiIcon;
  color: string;
  identifier: string;
};

export const schemaUpdateTeam = yup.object().shape({
  name: yup.string().required(),
  icon: yup
    .mixed<IEmojiIcon>()
    .oneOf([...EmojiIconList])
    .required(),
  color: yup.string().required(),
  identifier: yup
    .string()
    .min(3, 'Must be exactly 3 characters')
    .max(3, 'Must be exactly 3 characters')
    .required(),
});

const SettingTeamGeneralPage = () => {
  const { teams } = useContext(WorkspaceContext);
  const { showErrorToast } = useErrorHandler();
  const params = useParams();

  const selectedTeam = teams.find((item) => item.identifier === params.team);
  const updateTeam = useUpdateTeamMutation({
    onMutate: () => {},
    onError: (res) => {
      showErrorToast({ error: res });
    },
    onSuccess: () => {},
    onSettled: () => {},
  });

  const form = useForm<IUpdateTeam>({
    resolver: yupResolver<IUpdateTeam>(schemaUpdateTeam),
    defaultValues: {
      icon: (selectedTeam?.icon as IEmojiIcon) ?? 'EmojiBabyChick',
      name: selectedTeam?.name ?? '',
      color: selectedTeam?.color ?? '',
      identifier: selectedTeam?.identifier ?? '',
    },
  });

  const onSubmitUpdate = (data: IUpdateTeam) => {
    if (selectedTeam) {
      updateTeam.mutate({
        updateInput: {
          icon: data.icon,
          name: data.name,
          color: data.color,
          identifier: data.identifier,
          id: selectedTeam?.id,
        },
      });
    }
  };

  return (
    <div>
      <HeaderSetting
        title={selectedTeam?.name ?? ''}
        subtitle={'Manage team settings'}
      ></HeaderSetting>
      {selectedTeam && (
        <Form {...form}>
          <form onSubmit={form.handleSubmit(onSubmitUpdate)}>
            <div className="flex w-full flex-col gap-8">
              <div className="flex w-full flex-col gap-2">
                <Label className="font-semibold">Team icon & name</Label>
                <div className="flex w-full flex-row items-center gap-4">
                  <IconPicker
                    icon={form.watch('icon')}
                    color={form.watch('color')}
                    onSelectedIcon={(data) => {
                      form.setValue('icon', data);
                    }}
                    onSelectedColor={(data) => {
                      form.setValue('color', data);
                    }}
                  >
                    <Button
                      type="button"
                      variant="outline"
                      size="icon"
                      className={`opacity-100`}
                    >
                      <EmojiIcon
                        tag={form.watch('icon')}
                        attrs={{
                          width: 28,
                          height: 28,
                          color: form.watch('color'),
                        }}
                      />
                    </Button>
                  </IconPicker>

                  <FormField
                    control={form.control}
                    name="name"
                    render={({ field }) => (
                      <Input
                        placeholder="e.g. Engineering"
                        className="w-full"
                        {...field}
                      ></Input>
                    )}
                  />
                </div>

                <LabelError>{form.formState.errors.name?.message}</LabelError>
              </div>
              <FormField
                control={form.control}
                name="identifier"
                render={({ field }) => (
                  <div className="flex w-full flex-col gap-2">
                    <Label className="font-semibold">Team identifier</Label>
                    <div className="flex w-full flex-row items-center gap-4">
                      <Input
                        placeholder="e.g. ENG"
                        className="w-[120px]"
                        {...field}
                      ></Input>
                      <div className="w-full grow">
                        <Label className="opacity-50">
                          This is used as the identifier (e.g. ENG-123) for all
                          issues of the team. Keep it short and simple.
                        </Label>
                      </div>
                    </div>

                    <LabelError>
                      {form.formState.errors.identifier?.message}
                    </LabelError>
                  </div>
                )}
              />
              <div className="mt-[30px]">
                <Button
                  disabled={updateTeam.isPending || !form.formState.isDirty}
                  size={'sm'}
                  type="submit"
                >
                  Save
                </Button>
              </div>
            </div>
          </form>
        </Form>
      )}
    </div>
  );
};

export default SettingTeamGeneralPage;
