type IEnvironmentName = 'dev' | 'prod';

type IEnv = {
  [k in IEnvironmentName]: {
    environment: IEnvironmentName;
    base: string;
    api: string;
    ws: string;
  };
};

const env: IEnv = {
  dev: {
    environment: 'dev',
    base: 'https://wspaces.app',
    api: 'http://localhost:4000/graphql',
    ws: 'ws://localhost:4000/graphql',
  },
  prod: {
    environment: 'prod',
    base: 'https://wspaces.app',
    api: 'https://api.wspaces.app/graphql',
    ws: 'wss://api.wspaces.app/graphql',
  },
};

class AppEnv {
  static get = () =>
    env[import.meta.env.VITE_ENV as IEnvironmentName] ?? env.dev;
}

export default AppEnv;
