import { SlidersHorizontalIcon } from 'lucide-react';

import { Button } from '@/components/Button';
import { Label } from '@/components/Label';
import { Separator } from '@/components/Separator';
import { CloseFillIcon, FilterIcon } from '@/icons/core';
import { IGroupingIssue, ILayoutIssue } from '@/types/core/core';
import {
  WorkflowFragment,
  LabelFragment,
  UserFragment,
} from '@/types/gql/generated';

import FilterAssignees from './FilterAssignees';
import { FilterButton } from './FilterButton';
import FilterLabels from './FilterLabels';
import FilterOptions from './FilterOptions';
import FilterPriorities from './FilterPriorities';
import FilterStatus from './FilterStatus';
import { IConditionFilter } from '../../constant/constant';

const FilterIssue = (props: {
  workflows: WorkflowFragment[];
  labels: LabelFragment[];
  members: UserFragment[];

  selectedStatuses: string[];
  selectedConditionStatus: IConditionFilter;
  onSelectedStatuses: (value: string[]) => void;
  onChangeConditionStatus: (value: IConditionFilter) => void;
  onClearStatuses: () => void;

  selectedPriorities: string[];
  selectedConditionPriority: IConditionFilter;
  onSelectedPriorities: (value: string[]) => void;
  onChangeConditionPriority: (value: IConditionFilter) => void;
  onClearPriorities: () => void;

  selectedLabels: string[];
  selectedConditionLabel: IConditionFilter;
  onSelectedLabels: (value: string[]) => void;
  onChangeConditionLabel: (value: IConditionFilter) => void;
  onClearLabels: () => void;

  selectedAssignees: string[];
  selectedConditionAssignee: IConditionFilter;
  onSelectedAssignees: (value: string[]) => void;
  onChangeConditionAssignee: (value: IConditionFilter) => void;
  onClearAssignees: () => void;

  selectedLayout?: ILayoutIssue;
  onSelectedLayout?: (value: ILayoutIssue) => void;
  selectedGrouping: IGroupingIssue;
  onSelectedGrouping: (value: IGroupingIssue) => void;
}) => {
  return (
    <>
      <div className="flex flex-row items-center gap-4 py-[5px]">
        <div className="flex grow flex-wrap items-center gap-2">
          {props.selectedStatuses.length > 0 && (
            <div className="flex flex-row items-center gap-1">
              <FilterStatus
                defaultValue={props.selectedStatuses}
                selectedCondition={props.selectedConditionStatus}
                onChange={props.onChangeConditionStatus}
                onSelectedStatuses={props.onSelectedStatuses}
              />
              <Button
                size={'sm'}
                variant={'ghost'}
                className="h-[32px] w-[32px] rounded-none"
              >
                <CloseFillIcon
                  className={'cursor-pointer'}
                  onClick={() => props.onClearStatuses()}
                  width={14}
                />
              </Button>
            </div>
          )}
          {props.selectedPriorities.length > 0 && (
            <div className="flex flex-row items-center gap-1">
              <FilterPriorities
                defaultValue={props.selectedPriorities}
                selectedCondition={props.selectedConditionPriority}
                onChange={props.onChangeConditionPriority}
                onSelectedPriorities={props.onSelectedPriorities}
              />
              <Button
                size={'sm'}
                variant={'ghost'}
                className="h-[32px] w-[32px] rounded-none"
              >
                <CloseFillIcon
                  className={'cursor-pointer'}
                  onClick={() => props.onClearPriorities()}
                  width={14}
                />
              </Button>
            </div>
          )}
          {props.selectedLabels.length > 0 && (
            <div className="flex flex-row items-center gap-1">
              <FilterLabels
                defaultValue={props.selectedLabels}
                selectedCondition={props.selectedConditionLabel}
                onChange={props.onChangeConditionLabel}
                onSelectedLabels={props.onSelectedLabels}
              />
              <Button
                size={'sm'}
                variant={'ghost'}
                className="h-[32px] w-[32px] rounded-none"
              >
                <CloseFillIcon
                  className={'cursor-pointer'}
                  onClick={() => props.onClearLabels()}
                  width={14}
                />
              </Button>
            </div>
          )}
          {props.selectedAssignees.length > 0 && (
            <div className="flex flex-row items-center gap-1">
              <FilterAssignees
                defaultValue={props.selectedAssignees}
                selectedCondition={props.selectedConditionAssignee}
                onChange={props.onChangeConditionAssignee}
                onSelectedAssignees={props.onSelectedAssignees}
              />
              <Button
                size={'sm'}
                variant={'ghost'}
                className="h-[32px] w-[32px] rounded-none"
              >
                <CloseFillIcon
                  className={'cursor-pointer'}
                  onClick={() => props.onClearAssignees()}
                  width={14}
                />
              </Button>
            </div>
          )}
          <div className="flex w-[30px] flex-none items-center">
            <FilterButton
              defaultValue={undefined}
              onSelectedStatus={props.onSelectedStatuses}
              selectedStatuses={[]}
              isDisableStatuses={props.selectedStatuses.length > 0}
              onSelectedPriorities={props.onSelectedPriorities}
              selectedPriorities={[]}
              isDisablePriorities={props.selectedPriorities.length > 0}
              onSelectedLabels={props.onSelectedLabels}
              selectedLabels={[]}
              isDisableLabels={props.selectedLabels.length > 0}
              onSelectedAssignees={props.onSelectedAssignees}
              selectedAssignees={[]}
              isDisableAssignees={props.selectedAssignees.length > 0}
              workflows={props.workflows}
              labels={props.labels}
              members={props.members}
            >
              <Button
                variant={'ghost'}
                className="flex w-full flex-none items-center px-[5px]"
              >
                <FilterIcon width={20} height={20} />
              </Button>
            </FilterButton>
          </div>
          <div className="flex flex-wrap gap-1">
            <Label>Filter</Label>
          </div>
        </div>
        <div className="pr-[5px]">
          <FilterOptions
            layout={props.selectedLayout}
            onSelectedLayout={props.onSelectedLayout}
            grouping={props.selectedGrouping}
            onSelectedGrouping={props.onSelectedGrouping}
          >
            <Button variant={'ghost'}>
              <SlidersHorizontalIcon width={20} />
            </Button>
          </FilterOptions>
        </div>
      </div>
      <Separator className="w-full" orientation="horizontal" />
    </>
  );
};
export default FilterIssue;
