import { Label } from '@radix-ui/react-dropdown-menu';

import { SpinnersPulseAnimationIcon } from '@/icons/core';

const LoadingFullPage = (props?: { description?: string }) => (
  <div
    className={
      'fixed flex h-full w-full flex-col items-center justify-center gap-2'
    }
  >
    <SpinnersPulseAnimationIcon width={80} height={80} />
    {props?.description && (
      <Label className="text-xs opacity-50">{props?.description}</Label>
    )}
  </div>
);

export default LoadingFullPage;
