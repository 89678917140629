import { useContext } from 'react';

import { Button } from '@/components/Button';
import { Label } from '@/components/Label';
import { ScrollArea, ScrollBar } from '@/components/ScrollArea';
import BackIcon from '@/icons/back';
import { Building, UserCircle, UserCircleGear } from '@/icons/core';
import { RoleType } from '@/types/gql/generated';

import TeamList from './TeamList';
import WorkspaceContext from '../../workspace/WorkspaceContext';

const HeaderSidebar = (props: { onNavigate: (path: string) => void }) => {
  const { selected } = useContext(WorkspaceContext);

  return (
    <div className="flex flex-col gap-2 p-[20px]">
      <Button
        size={'sm'}
        type="button"
        variant={'secondary'}
        onClick={() => props.onNavigate(`/${selected?.url}/my-issues/assigned`)}
      >
        <div className="flex w-full items-center gap-4">
          <div className="flex">
            <BackIcon />
          </div>
          <Label>Settings</Label>
        </div>
      </Button>
    </div>
  );
};

const TitleSection = (props: { title: string; icon: React.ReactNode }) => {
  return (
    <div className="flex w-full items-center gap-2">
      <div className="flex items-center">{props.icon}</div>
      <Label className="font-semibold">{props.title}</Label>
    </div>
  );
};

const ItemMenu = (props: {
  title: string;
  path: string;
  onNavigate: (path: string) => void;
}) => {
  const { selected } = useContext(WorkspaceContext);
  return (
    <Button
      className="h-[30px] w-full cursor-pointer"
      color="gray"
      variant="ghost"
      size={'sm'}
      type="button"
      onClick={() =>
        props.onNavigate(`/${selected?.url}/settings/${props.path}`)
      }
    >
      <div className="flex w-full items-center pl-[10px]">
        <Label>{props.title}</Label>
      </div>
    </Button>
  );
};
const BodySidebar = (props: { onNavigate: (path: string) => void }) => {
  const { role } = useContext(WorkspaceContext);
  const MenuGeneral = [
    { title: 'Overview', path: 'overview' },
    { title: 'General', path: 'workspace' },
    { title: 'Members', path: 'members' },
    { title: 'Labels', path: 'labels' },
    { title: 'Projects', path: 'projects' },
  ];

  const MenuAccount = [
    { title: 'Profile', path: 'account/profile' },
    { title: 'Preferences', path: 'account/preferences' },
  ];

  const onNavigation = (path: string) => {
    props.onNavigate(path);
  };

  return (
    <div className="px-[20px]">
      <div className="flex flex-col gap-2">
        {role === RoleType.Admin && (
          <div className="w-full">
            <TitleSection
              icon={<Building width={16} height={16} />}
              title="Workspace"
            />
            <div className="pt-[10px]">
              {MenuGeneral.map((item, index) => {
                return (
                  <ItemMenu
                    key={index}
                    title={item.title}
                    path={item.path}
                    onNavigate={onNavigation}
                  />
                );
              })}
            </div>
          </div>
        )}

        <div className="w-full">
          <TitleSection
            icon={<UserCircle width={20} height={20} />}
            title="My Account"
          />
          <div className="pt-[10px]">
            {MenuAccount.map((item, index) => {
              return (
                <ItemMenu
                  key={index}
                  title={item.title}
                  path={item.path}
                  onNavigate={onNavigation}
                />
              );
            })}
          </div>
        </div>
        {role === RoleType.Admin && (
          <div className="w-full pb-[30px]">
            <TitleSection
              icon={<UserCircleGear width={20} height={20} />}
              title="Teams"
            />
            <div className="pt-[10px]">
              <TeamList onNavigate={onNavigation} />
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

const SettingSidebar = (props: { onNavigate: (path: string) => void }) => {
  return (
    <div className="h-full w-full flex-none">
      <div className="flex h-full w-full flex-col">
        <HeaderSidebar onNavigate={props.onNavigate} />
        <div className="flex w-full flex-1 overflow-hidden">
          <div className="h-full w-full">
            <ScrollArea style={{ height: '100%' }}>
              <BodySidebar onNavigate={props.onNavigate} />
              <ScrollBar orientation="vertical" />
            </ScrollArea>
          </div>
        </div>
      </div>
    </div>
  );
};
export default SettingSidebar;
