import { useContext, useEffect, useState } from 'react';

import SubscriptionContext from '@/providers/subscriptions/SubscriptionContext';
import { PushEventType } from '@/types/gql/generated';
import { IssueAttachmentFragment } from '@/types/gql/generated';

const useAttachments = () => {
  const { pubsub } = useContext(SubscriptionContext);
  const [attachments, setAttachments] = useState<IssueAttachmentFragment[]>([]);

  const storeAttachments = async (value: IssueAttachmentFragment[]) => {
    setAttachments(value);
  };

  const pushItem = async (props: { attachment: IssueAttachmentFragment }) => {
    const clone = Object.assign([], attachments);
    clone.push(props.attachment);
    setAttachments(clone);
  };

  const popItem = async (props: { attachment_id: string }) => {
    const clone: IssueAttachmentFragment[] = Object.assign(
      [],
      attachments.filter((item) => item.id !== props.attachment_id),
    );
    setAttachments(clone);
  };

  useEffect(() => {
    if (pubsub && pubsub.event && pubsub.id) {
      switch (pubsub.event) {
        case PushEventType.IssueAttachmentAdded:
          if (!pubsub.data) return;
          pushItem({ attachment: pubsub.data as IssueAttachmentFragment });
          break;
        case PushEventType.IssueAttachmentDeleted:
          popItem({ attachment_id: pubsub.id });
          break;
        default:
          break;
      }
    }
  }, [pubsub]);

  return {
    attachments,
    event: {
      storeAttachments,
    },
  };
};

export default useAttachments;
