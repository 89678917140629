import React, { useContext } from 'react';

import WorkspaceContext from '@/providers/workspace/WorkspaceContext';
import { IDocumentFragment } from '@/types/gql/generated';

const RenderDocument = (props: {
  id: string | undefined;
  renderItem: (value: IDocumentFragment | undefined) => React.ReactNode;
}) => {
  if (!props.id) {
    return <>{props.renderItem(undefined)}</>;
  }
  const { documents } = useContext(WorkspaceContext);
  return (
    <>{props.renderItem(documents.find((item) => item.id === props.id))}</>
  );
};
const RenderDocumentsByParentId = (props: {
  id: string | undefined;
  renderItem: (value: IDocumentFragment[]) => React.ReactNode;
}) => {
  if (!props.id) {
    return <>{props.renderItem([])}</>;
  }
  const { documents } = useContext(WorkspaceContext);
  return (
    <>
      {props.renderItem(
        documents.filter((item) => item.document_id === props.id),
      )}
    </>
  );
};
const MemoizedRenderDocumentsByParentId = React.memo(RenderDocumentsByParentId);
const MemoizedRenderDocument = React.memo(RenderDocument);
export { MemoizedRenderDocument, MemoizedRenderDocumentsByParentId };
