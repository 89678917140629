import { Button } from '@/components/Button';
import { Label } from '@/components/Label';
import { UserAvatar } from '@/components/UserAvatar';
import { BacklogIcon } from '@/icons/core/issue';
import { MemoizedRenderMembers } from '@/renders/RenderMember';

import { FilterButton } from './FilterButton';
import { FilterType } from './FilterType';
import { IConditionFilter } from '../../constant/constant';

const FilterAssignees = (props: {
  defaultValue: string[];
  selectedCondition: IConditionFilter;
  onChange: (value: IConditionFilter) => void;
  onSelectedAssignees: (value: string[]) => void;
}) => {
  return (
    <Button variant={'secondary'} size={'sm'} asChild className="rounded-none">
      <div className="flex h-[32px] flex-row items-center gap-0.5">
        <div className="flex flex-row gap-1">
          <BacklogIcon width={18} height={18} />
          <Label>Assignee</Label>
        </div>

        <FilterType
          onSelected={props.onChange}
          selected={props.selectedCondition}
        >
          <Button
            variant={'secondary'}
            size={'sm'}
            asChild
            className="cursor-pointer rounded-none "
          >
            <div className="flex flex-row gap-1">
              <div>
                <Label>{props.selectedCondition.name}</Label>
              </div>
            </div>
          </Button>
        </FilterType>
        <FilterButton
          defaultValue={'assignee'}
          onSelectedAssignees={props.onSelectedAssignees}
          selectedAssignees={props.defaultValue}
          workflows={[]}
          labels={[]}
          members={[]}
        >
          <Button
            variant={'secondary'}
            size={'sm'}
            asChild
            className="cursor-pointer rounded-none "
          >
            <div className="flex flex-row items-center gap-1">
              <div className="relative flex items-center">
                <MemoizedRenderMembers
                  ids={props.defaultValue}
                  renderItem={(value) =>
                    value.map((item) => (
                      <UserAvatar
                        key={item.id}
                        size={'sm'}
                        fallback={item?.username}
                      />
                    ))
                  }
                />
              </div>
              <div>
                <Label>{props.defaultValue.length} assignees</Label>
              </div>
            </div>
          </Button>
        </FilterButton>
      </div>
    </Button>
  );
};
export default FilterAssignees;
