import { setContext } from '@apollo/client/link/context';

import LocalStorage from '../local-storage';

const authLink = setContext((_, { headers }) => {
  const token = LocalStorage.getToken();
  // const tokenPayload = jwtDecode(token) as JwtPayload | null;

  // let hasuraRole: string | null = null;
  // if (role) {
  //   hasuraRole = role;
  // } else if (tokenPayload?.roles.includes(userRoles.ROLE_USER)) {
  //   hasuraRole = userRoles.ROLE_USER;
  // } else if (tokenPayload?.roles.includes(userRoles.ROLE_STAFF)) {
  //   hasuraRole = userRoles.ROLE_STAFF;
  // }

  return {
    headers: {
      ...headers,
      'Apollo-Require-Preflight': true,
      authorization: token ? `Bearer ${token}` : '',
    },
  };
});

export default authLink;
