import {
  Accordion,
  AccordionContent,
  AccordionItem,
  AccordionTrigger,
} from '@/components/Accordion';
import { Button } from '@/components/Button';
import { Label } from '@/components/Label';
import { DocumentsIcon, PeopleTeamIcon } from '@/icons/core';
import { WorkspaceFragment } from '@/types/gql/generated';

const WorkspaceSection = (props: {
  workspace: WorkspaceFragment;
  onNavigate: (path: string, reload?: boolean) => void;
}) => {
  const items = [
    {
      title: 'Teams',
      path: `/${props.workspace.url}/teams`,
      icon: <PeopleTeamIcon width={20} height={20} />,
    },
    {
      title: 'Projects',
      path: `/${props.workspace.url}/teams`,
      icon: <DocumentsIcon width={20} height={20} />,
    },
  ];
  return (
    <Accordion type="single" collapsible={true} defaultValue={'team'}>
      <AccordionItem value={'team'}>
        <Button
          type="button"
          variant={'ghost'}
          className="flex  h-[30px] w-full flex-row justify-start px-[10px] text-left"
        >
          <AccordionTrigger className="flex-none">
            <Label className="text-xs opacity-50">Workspace</Label>
          </AccordionTrigger>
        </Button>
        <AccordionContent>
          <div className="flex flex-col">
            {items.map((item, index) => (
              <Button
                type="button"
                key={index}
                variant="ghost"
                className={'h-[30px] cursor-pointer px-[10px]'}
                onClick={() => props.onNavigate(item.path)}
              >
                <div className="flex w-full flex-row items-center gap-2">
                  <div className="w-[20px]">{item.icon}</div>
                  <Label>{item.title}</Label>
                </div>
              </Button>
            ))}
          </div>
        </AccordionContent>
      </AccordionItem>
    </Accordion>
  );
};

export default WorkspaceSection;
