import { useState } from 'react';

import IssueServicesContext from './IssueServicesContext';
import useProvider from './useProvider';
import { CreateIssueDialog } from '../../pages/issue/components/CreateIssue/CreateIssueDialog';

const IssueServicesWrapper = ({ children }: { children?: React.ReactNode }) => {
  const { isShowCreateIssue, event } = useProvider();

  const [initCreate, setInitCreate] = useState<{
    initTeamId?: string;
    initWorkflowId?: string;
    initMemberId?: string;
    initTitle?: string;
    initContent?: string;
  }>();
  return (
    <IssueServicesContext.Provider
      value={{
        isShowCreateIssue,
        showCreatePopup: (value) => {
          event.showCreatePopup(value.isOpen);
          setInitCreate({ ...value });
        },
      }}
    >
      {children}
      <CreateIssueDialog
        {...initCreate}
        onSuccess={() => {}}
        onCancel={() => {}}
      />
    </IssueServicesContext.Provider>
  );
};

export default IssueServicesWrapper;
