import { useContext } from 'react';

import { FileText } from 'lucide-react';
import { useNavigate } from 'react-router-dom';

import { Label } from '@/components/Label';
import WorkspaceContext from '@/providers/workspace/WorkspaceContext';
import { ProjectFragment } from '@/types/gql/generated';

import { ToolsMenuProject } from '../../ContextMenuProject';

const RowItem = (props: { project: ProjectFragment }) => {
  const { selected } = useContext(WorkspaceContext);
  const navigate = useNavigate();
  return (
    <div
      className="border-bottom flex h-[40px] w-full cursor-pointer items-center gap-2 overflow-hidden border-[1px] border-secondary px-[10px] hover:bg-secondary"
      onClick={() =>
        navigate(`/${selected?.url}/project/${props.project.code}`)
      }
    >
      <div className="flex w-full flex-row gap-2">
        <div className="flex flex-row items-center gap-2">
          <span>
            <FileText width={20} />
          </span>
          <Label asChild className="line-clamp-1 flex-auto">
            <span> {props.project.title}</span>
          </Label>
        </div>
      </div>
    </div>
  );
};

const ProjectTableList = (props: { projects: ProjectFragment[] }) => {
  return (
    <div className="w-full">
      {props.projects.map((item) => (
        <ToolsMenuProject
          contextMenu={true}
          key={item.id}
          project={item}
          workspace_id={item.workspace_id}
        >
          <RowItem key={item.id} project={item} />
        </ToolsMenuProject>
      ))}
    </div>
  );
};

export default ProjectTableList;
